function ValidateRUC(numero) {

    let suma = 0;
    let residuo = 0;
    let pri = false;
    let pub = false;
    let nat = false;
    let numeroProvincias = 24;
    let modulo = 11;

    /* Verifico que el campo no contenga letras */
    let ok = 1;
    for (let i = 0; i < numero.length && ok === 1; i++) {
        let n = parseInt(numero.charAt(i));
        if (isNaN(n)) ok = 0;
    }
    if (ok === 0) {
        return {isValid: false, error: 'No puede ingresar caracteres en el número'};
    }

    if (numero.length < 10) {
        return {isValid: false, error: 'El número de RUC ingresado no es válido'};
    }

    /* Los primeros dos digitos corresponden al codigo de la provincia */
    let provincia = numero.substr(0, 2);
    if (provincia < 1 || provincia > numeroProvincias) {
        return {isValid: false, error: 'El código de la provincia (dos primeros dígitos) es inválido'};
    }

    //Aqui almacenamos los digitos de la cedula en letiables.
    let d1 = numero.substr(0, 1);
    let d2 = numero.substr(1, 1);
    let d3 = numero.substr(2, 1);
    let d4 = numero.substr(3, 1);
    let d5 = numero.substr(4, 1);
    let d6 = numero.substr(5, 1);
    let d7 = numero.substr(6, 1);
    let d8 = numero.substr(7, 1);
    let d9 = numero.substr(8, 1);
    let d10 = numero.substr(9, 1);


    /* El tercer digito es: */
    /* 9 para sociedades privadas y extranjeros   */
    /* 6 para sociedades publicas */
    /* menor que 6 (0,1,2,3,4,5) para personas naturales */


    if (d3 === '7' || d3 === '8') {
        return {isValid: false, error: 'El tercer dígito ingresado es inválido'};
    }


    let p1
    let p2
    let p3
    let p4
    let p5
    let p6
    let p7
    let p8
    let p9

    /* Solo para personas naturales (modulo 10) */
    if (d3 < 6) {
        nat = true;
        p1 = d1 * 2;
        if (p1 >= 10) p1 -= 9;
        p2 = d2 * 1;
        if (p2 >= 10) p2 -= 9;
        p3 = d3 * 2;
        if (p3 >= 10) p3 -= 9;
        p4 = d4 * 1;
        if (p4 >= 10) p4 -= 9;
        p5 = d5 * 2;
        if (p5 >= 10) p5 -= 9;
        p6 = d6 * 1;
        if (p6 >= 10) p6 -= 9;
        p7 = d7 * 2;
        if (p7 >= 10) p7 -= 9;
        p8 = d8 * 1;
        if (p8 >= 10) p8 -= 9;
        p9 = d9 * 2;
        if (p9 >= 10) p9 -= 9;
        modulo = 10;
    }
    /* Solo para sociedades publicas (modulo 11) */
    /* Aqui el digito verficador esta en la posicion 9, en las otras 2 en la pos. 10 */

    if (d3 === '6') {
        pub = true;
        p1 = parseInt(d1) * 3;
        p2 = parseInt(d2) * 2;
        p3 = parseInt(d3) * 7;
        p4 = parseInt(d4) * 6;
        p5 = parseInt(d5) * 5;
        p6 = parseInt(d6) * 4;
        p7 = parseInt(d7) * 3;
        p8 = parseInt(d8) * 2;
        p9 = 0;
    }
    /* Solo para entidades privadas (modulo 11) */
    if (d3 === '9') {
        pri = true;
        p1 = parseInt(d1) * 4;
        p2 = parseInt(d2) * 3;
        p3 = parseInt(d3) * 2;
        p4 = parseInt(d4) * 7;
        p5 = parseInt(d5) * 6;
        p6 = parseInt(d6) * 5;
        p7 = parseInt(d7) * 4;
        p8 = parseInt(d8) * 3;
        p9 = parseInt(d9) * 2;
    }

    suma = p1 + p2 + p3 + p4 + p5 + p6 + p7 + p8 + p9;
    residuo = suma % modulo;

    let digitoVerificador = residuo === 0 ? 0 : modulo - residuo;

    if (pub) {
        if (digitoVerificador.toString() !== d9) {
            return {isValid: false, error: 'El ruc de la empresa del sector público es incorrecto.'};
        }
        /* El ruc de las empresas del sector publico terminan con 0001*/
        if (numero.substr(9, 4) !== '0001') {
            return {isValid: false, error: 'El ruc de la empresa del sector público debe terminar con 0001.'};
        }
        return {isValid: true, error: null};
    }

    if (pri) {
        if (digitoVerificador.toString() !== d10) {
            return {isValid: false, error: 'El ruc de la empresa del sector privado es incorrecto.'};
        }
        if (numero.substr(10, 3) !== '001') {
            return {isValid: false, error: 'El ruc de la empresa del sector privado debe terminar con 001.'};
        }
        return {isValid: true, error: null};
    }

    if (nat) {
        if (digitoVerificador.toString() !== d10) {
            return {isValid: false, error: 'El número de cédula de la persona natural es incorrecto.'};
        }

        if (numero.substr(10, 3) !== '001') {
            return {isValid: false, error: 'El RUC de la persona natural debe terminar con 001.'};
        }
        return {isValid: true, error: null};
    }
}

function ValidarCedula(numero) {
    let suma = 0;
    let residuo = 0;
    let nat = false;
    let numeroProvincias = 24;
    let modulo = 11;

    /* Verifico que el campo no contenga letras */
    let ok = 1;
    for (let i = 0; i < numero.length && ok === 1; i++) {
        let n = parseInt(numero.charAt(i));
        if (isNaN(n)) ok = 0;
    }
    if (ok === 0) {
        return {isValid: false, error: 'No puede ingresar caracteres en el número'};
    }

    if (numero.length < 10 || numero.length > 10) {
        return {isValid: false, error: 'El número de cedula ingresado no es válido'};
    }

    /* Los primeros dos digitos corresponden al codigo de la provincia */
    let provincia = numero.substr(0, 2);
    if (provincia < 1 || provincia > numeroProvincias) {
        return {isValid: false, error: 'El código de la provincia (dos primeros dígitos) es inválido'};
    }

    //Aqui almacenamos los digitos de la cedula en letiables.
    let d1 = numero.substr(0, 1);
    let d2 = numero.substr(1, 1);
    let d3 = numero.substr(2, 1);
    let d4 = numero.substr(3, 1);
    let d5 = numero.substr(4, 1);
    let d6 = numero.substr(5, 1);
    let d7 = numero.substr(6, 1);
    let d8 = numero.substr(7, 1);
    let d9 = numero.substr(8, 1);
    let d10 = numero.substr(9, 1);


    /* El tercer digito es: */
    /* 9 para sociedades privadas y extranjeros   */
    /* 6 para sociedades publicas */
    /* menor que 6 (0,1,2,3,4,5) para personas naturales */


    if (d3 === '7' || d3 === '8') {
        return {isValid: false, error: 'El tercer dígito ingresado es inválido'};
    }


    let p1
    let p2
    let p3
    let p4
    let p5
    let p6
    let p7
    let p8
    let p9

    /* Solo para personas naturales (modulo 10) */
    if (d3 < 6) {
        nat = true;
        p1 = d1 * 2;
        if (p1 >= 10) p1 -= 9;
        p2 = d2 * 1;
        if (p2 >= 10) p2 -= 9;
        p3 = d3 * 2;
        if (p3 >= 10) p3 -= 9;
        p4 = d4 * 1;
        if (p4 >= 10) p4 -= 9;
        p5 = d5 * 2;
        if (p5 >= 10) p5 -= 9;
        p6 = d6 * 1;
        if (p6 >= 10) p6 -= 9;
        p7 = d7 * 2;
        if (p7 >= 10) p7 -= 9;
        p8 = d8 * 1;
        if (p8 >= 10) p8 -= 9;
        p9 = d9 * 2;
        if (p9 >= 10) p9 -= 9;
        modulo = 10;
    }

    suma = p1 + p2 + p3 + p4 + p5 + p6 + p7 + p8 + p9;
    residuo = suma % modulo;

    let digitoVerificador = residuo === 0 ? 0 : modulo - residuo;

    if (nat) {
        if (digitoVerificador.toString() !== d10) {
            return {isValid: false, error: 'El número de cédula es incorrecto.'};
        }

        return {isValid: true, error: null};
    }
}

function sendRequestPhone(value, num) {
    const initNumber = value.substr(0, 2)
    const numberCel = /^\d{10}$/;
    const numberLocal = /^\d{9}$/;
    const numberPattern = num === 10 ? numberCel : numberLocal;
    return new Promise((resolve) => {
        setTimeout(function () {
            if (numberPattern.test(value) && (initNumber === '09' || initNumber === '02' || initNumber === '03'
                || initNumber === '04' || initNumber === '05' || initNumber === '06' || initNumber === '07')) {
                resolve(true);
            } else {
                resolve(false);
            }
        }, 300);
    });
}


export {ValidateRUC, ValidarCedula, sendRequestPhone}
