import React, {useContext, useEffect,} from 'react';
import {useTimer} from 'react-timer-hook';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {collection, deleteDoc, doc, getDocs, query, setDoc, where} from "firebase/firestore";
import {db} from "../../firebase/Firebase";
import {Filters, UpdateArray} from "../../utils/HelpFunctions";
import {observer} from "mobx-react-lite";
import {StoreContext} from "../../store/Context";
import {useNavigate} from "react-router-dom";

function Timer({expiryTimestamp}) {

    const {dataStore} = useContext(StoreContext)

    const {user, shoppingCart, uidCart, dataOrder} = dataStore

    const {
        seconds,
        minutes,
    } = useTimer({expiryTimestamp, onExpire: () => console.warn('onExpire called')});

    const navigate = useNavigate();

    useEffect(() => {

        const handleDelete = async () => {
            const productsArr = []
            let updateProducts;
            const uid = user.uid

            try {
                for (const items of shoppingCart) {
                    const cod = items.cod
                    const storage = items.storage

                    const q = query(collection(db, "products"), where("cod", "==", cod))

                    const querySnapshot = await getDocs(q);

                    querySnapshot.forEach((doc) => {
                        productsArr.push({...doc.data(), docId: doc.id,})
                    })

                    const filteredArr = productsArr.reduce((acc, current) => {
                        const x = acc.find(item => item.cod === current.cod);
                        if (!x) {
                            return acc.concat([current]);
                        } else {
                            return acc;
                        }
                    }, []);


                    const amount = items.amount

                    filteredArr.forEach(items => {
                        if (items.cod === cod) {
                            const stores = items.stores
                            const filterStore = Filters(stores, 'name', storage)
                            const newStock = filterStore[0].stock + amount
                            const updateArray = UpdateArray(stores, 'name', storage, 'stock', newStock)
                            items.stores = updateArray
                        }
                    })
                    updateProducts = filteredArr
                }


                for (const items of updateProducts) {
                    let stock = items?.stores.reduce(function (prev, cur) {
                        return prev + cur.stock;
                    }, 0);
                    const uidProduct = items.uidProduct
                    items.stock = stock
                    await setDoc(doc(db, "products", uidProduct), items);
                }

                const uidOrder = uidCart

                const newDataOrder = {...dataOrder, status: 'Caducada', uidOrder, uid: user.uid}
                await setDoc(doc(db, "overTime", uidOrder), newDataOrder);
                await deleteDoc(doc(db, "clients", uid, 'orders', uidOrder));
                await deleteDoc(doc(db, 'orders', uidOrder));

                navigate('/')


            } catch (e) {
                navigate('/')
            }
        }

        if (minutes === 0 && seconds === 0) {
            handleDelete().catch(() => null)
        }
    }, [seconds, minutes])

    return (
        <Stack sx={{width: '100%'}} spacing={2} style={{marginTop: 10}}>
            <div style={{fontSize: '18px'}}>
                <Alert
                    severity="info"> {` Usted dispone de  10 minutos para finalizar su compra ${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}</Alert>
            </div>
        </Stack>
    );

}


export default observer(Timer)


