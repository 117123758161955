import './data-session.css'
import Register from "./Register";
import Login from "./Login";

function DataSession() {
    return (
        <div className="session-main-container">
            <div className={'data-session-container'}>
                <Register/>
                <Login/>
            </div>
        </div>
    )
}

export default DataSession
