import Button from "@mui/material/Button";
import {useContext, useEffect, useState} from "react";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../../firebase/Firebase";
import {observer} from "mobx-react-lite";
import {StoreContext} from "../../../store/Context";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import {Dropdown} from 'primereact/dropdown';
import {Filters} from "../../../utils/HelpFunctions";


function removeDuplicates_(arr, field) {
    let uniqueCodes = new Set();

    return arr.filter(element => {
        if (uniqueCodes.has(element[field])) {
            return false; // Duplicate element, skip
        } else {
            uniqueCodes.add(element[field]); // Add the code to the set
            return true;
        }
    });
}

function getYearsFromModels(brandModels, uidProduct) {
    const result = [];

    brandModels.forEach(({initYear, endYear}) => {
        if (initYear && endYear) {
            const start = parseInt(initYear);
            const end = parseInt(endYear);
            for (let year = start; year <= end; year++) {
                result.push({id: uidProduct, year: year.toString()});
            }
        } else if (initYear) {
            result.push({id: uidProduct, year: initYear});
        }
    });

    return result;
}

const getYears = (data) => {
    const results_ = [];

    data.forEach(object => {
        const uidProduct = object?.productId;
        const brandModels = object.brandModels || [];
        const yearsFromModels = getYearsFromModels(brandModels, uidProduct);
        results_.push(...yearsFromModels);
    });

    const uniquePairs = new Set();

    const filteredArray = results_.filter(({id, year}) => {
        const pair = id + year;
        if (id !== undefined && !uniquePairs.has(pair)) {
            uniquePairs.add(pair);
            return true;
        }
        return false;
    });
    return filteredArray.sort((a, b) => {
        return parseInt(a.year) - parseInt(b.year);
    })
}

function FilterMMA(props) {

    const {dataStore, filtersStore} = useContext(StoreContext)
    const {
        vehicles,
        mainProducts,
        copyMainProducts,
    } = dataStore

    const {
        products,
        filterSearch,
        selectSchema,
        selectedVehicles,
        selectedModel,
        selectedYear,
        yearsList,
        copyYearsList,
        selectManufacturers,
        selectGroup,
        selectSubGroup,
        copSubGroupList,
        subGroupList,
        schemasList_,
        modelsList,
        copyModelsList,
        copySchemaList,
        deleteGlobalFilter,
        keyWords,
    } = filtersStore


    const {manufacturers, groups,} = props

    const [models_, setModels] = useState(modelsList);
    const [years, setYears] = useState(null);
    const [results, setResults] = useState(null);

    useEffect(() => {

        const Products = async () => {
            const q = query(collection(db, "products"), where("status", "==", 'Activo'), where("stock", ">", 0));
            const querySnapshot = await getDocs(q);
            const products = []
            querySnapshot.forEach((doc) => products.push({...doc.data(), productId: doc.id}));

            const brandsModels_ = []

            products?.forEach(items => items.brandModels?.forEach(bm => brandsModels_.push({
                ...bm,
                productId: items.productId
            })))
            filtersStore?.setBrandsModels(brandsModels_)
            filtersStore?.setProducts(products)
            filtersStore.AllProducts(products)
            const years = getYears(products)
            const years_ = removeDuplicates_(years, 'year')
            setYears(years)
            !yearsList && filtersStore?.setYearsList(years_)
            filtersStore?.setCopYearsList(years_)
        }
        !products && Products().catch(() => null)
    }, []);

    useEffect(() => {
        if (selectSchema && results) {
            setTimeout(() => {
                const products_ = Filters(results, 'name', selectSchema?.name)
                dataStore?.MainProducts(products_)
            }, 700);
        }

        if (selectSchema && !results) {
            setTimeout(() => {
                const products_ = Filters(products, 'schema', selectSchema?.name)
                dataStore?.MainProducts(products_)
            }, 650);
        }
    }, [selectSchema]);

    useEffect(() => {

        const data = keyWords !== '' ? mainProducts : products

        const results = data?.filter(product => {
            let match = true;

            if (selectedVehicles?.vehicles && !product.brandModels.some(brandModel => brandModel.vehicles === selectedVehicles?.vehicles)) {
                match = false;
            }

            if (selectedModel?.models && !product.brandModels.some(brandModel => brandModel.models === selectedModel?.models)) {
                match = false;
            }

            if (selectedYear?.year.toString() && !product.brandModels.some(brandModel => brandModel.initYear === selectedYear?.year.toString())) {
                match = false;
            }

            if (selectManufacturers?.manufacturers && product.manufacturers !== selectManufacturers?.manufacturers) {
                match = false;
            }

            if (selectGroup?.group && product.group !== selectGroup?.group) {
                match = false;
            }

            if (selectSubGroup?.subGroup && product.subGroup !== selectSubGroup?.subGroup) {
                match = false;
            }

            // if (selectSchema?.name && product.schema !== selectSchema?.name) {
            //     match = false;
            // }

            return match;
        });


        if (results) {
            setResults(results)
            dataStore?.MainProducts(results)
            filtersStore?.Result(results)
            if (!selectedYear) {
                const years = getYears(results)
                setYears(years)
                const years_ = removeDuplicates_(years, 'year')
                filtersStore?.setYearsList(years_)
            } else {
                onSelectYear(selectedYear)
            }

        } else {
            dataStore?.MainProducts(copyMainProducts)
        }

    }, [selectedVehicles, selectedModel, selectedYear, selectManufacturers, selectGroup, selectSubGroup]);

    const onResetFilters = () => {
        filtersStore?.FilterSearch({...filterSearch, inLateralFilters: false, inDetail: false})
        setResults(null)
        filtersStore?.setSelectedVehicles(null)
        filtersStore?.setSelectedModel(null)
        filtersStore?.ModelsList(copyModelsList)
        filtersStore?.SubGroupList(copSubGroupList)
        filtersStore?.SchemaList(copySchemaList)
        filtersStore?.setYearsList(copyYearsList)
        filtersStore?.setSelectedYear(null)
        filtersStore?.setSelectManufacturers(null)
        filtersStore?.Result(null)
        filtersStore?.BackHome(false)
        filtersStore?.setSelectGroup(null)
        filtersStore?.setSelectSubGroup(null)
        filtersStore.setSelectSchema(null)
        filtersStore.setKeyWords('')
        filtersStore.DeleteGlobalFilter(!deleteGlobalFilter)
        dataStore.MainProducts(copyMainProducts)

    }

    const onSelectVehicle = (value) => {

        if (value) {
            const {vehicles} = value

            filtersStore?.setSelectedModel(null)
            filtersStore?.setSelectedVehicles(value)
            const vehicles_ = products.filter(item => item.brandModels.some(model => model.vehicles === vehicles));

            const filteredModels = vehicles_?.reduce((acc, product) => {
                const brandModelsForVehicle = product.brandModels.filter(brandModel => brandModel.vehicles === vehicles);
                acc.push(...brandModelsForVehicle);
                return acc;
            }, []);
            const uniqueModels = removeDuplicates_(filteredModels, 'models')
            setModels(uniqueModels);
            filtersStore?.ModelsList(uniqueModels);
        } else {
            filtersStore?.setSelectedVehicles(null)
        }


    }

    const onSelectYear = (value) => {
        if (value) {
            filtersStore?.setSelectedYear(value)
            const {year} = value
            const productId = years?.filter(items => items.year === year)
            const ids = productId?.map(item => item.id);
            const products_ = products?.filter(item => ids?.includes(item.productId));

            if (selectedVehicles || selectedModel) {
                const prod = []
                products_.forEach(items => {
                    const brandModels = items.brandModels || [];
                    brandModels.forEach(bm => {
                        if (bm.vehicles === selectedVehicles?.vehicles || bm.models.includes(selectedModel?.models)) {
                            if (parseInt(bm.initYear) === year || parseInt(bm.endYear) === year || (parseInt(bm.initYear) <= year && parseInt(bm.endYear) >= year)) {
                                prod.push(items)
                            }
                        }
                    })
                })
                const uniqueProd = removeDuplicates_(prod, 'cod')
                dataStore?.MainProducts(uniqueProd)
                filtersStore?.Result(uniqueProd)
            } else {
                dataStore?.MainProducts(products_)
                filtersStore?.Result(products_)
            }

        } else {
            filtersStore?.setSelectedYear(null)
        }
    }

    const onSelectedGroup = (value) => {
        if (value) {
            filtersStore?.setSelectGroup(value)
            const {group} = value
            const filterSubGroup = products?.filter(items => items.group === group)
            const uniqueSubGroup = removeDuplicates_(filterSubGroup, 'subGroup')
            filtersStore?.SubGroupList(uniqueSubGroup)
        } else {
            filtersStore?.setSelectGroup(null)
        }
    }

    const onSelectSubGroup = (value) => {

        if (value) {
            const {subGroup} = value
            filtersStore?.setSelectSchema(null)
            filtersStore?.setSelectSubGroup(value)
            const schemas = products?.filter(items => items.subGroup === subGroup)
            const uniqueSchema = removeDuplicates_(schemas, 'name')
            filtersStore?.SchemaList(uniqueSchema)
        } else {
            filtersStore?.setSelectSubGroup(copySchemaList)
            filtersStore?.setSelectSubGroup(null)
        }
    }

    const onSelectSchema = (value) => {

        if (value) {
            filtersStore?.setSelectSchema(null)
            const {schema} = value
            if (schema) {
                filtersStore.setSelectSchema(value)
                const res = Filters(copySchemaList, 'name', schema)[0]?.imageUrl
                const imageUrl = res ? res : Filters(copySchemaList, 'schema', schema)[0]?.imageUrl
                filtersStore?.ImageSchema(imageUrl)
            } else {
                const {imageUrl} = value
                setResults(null)
                filtersStore.setSelectSchema(value)
                filtersStore?.ImageSchema(imageUrl)
            }
        } else {
            filtersStore?.setSelectSchema(null)
            dataStore?.MainProducts(products)
        }
    }


    return (
        <div className="filter-container">


            <Button size="small" style={{width: 150, background: '#282A36', color: 'white', borderRadius: 10}}
                    onClick={onResetFilters} startIcon={<FilterAltOutlinedIcon/>}>
                Quitar filtros
            </Button>

            <span style={{marginTop: 15, color: '#282A36'}}>Por marca/modelo/año</span>

            <Dropdown value={selectedVehicles} onChange={(e) => onSelectVehicle(e.value)}
                      options={vehicles}
                      optionLabel="vehicles" filter showClear showFilterClear emptyFilterMessage={'Sin resultados'}
                      onClick={() => filtersStore?.BackHome(false)}
                      placeholder="Marca" style={{marginTop: 10, width: '100%'}}/>

            <Dropdown value={selectedModel} onChange={(e) => filtersStore?.setSelectedModel(e.value)} options={models_}
                      optionLabel="models" filter showClear showFilterClear emptyFilterMessage={'Sin resultados'}
                      onClick={() => filtersStore?.BackHome(false)}
                      placeholder="Modelo" style={{marginTop: 10, width: '100%'}}/>


            <Dropdown value={selectedYear} onChange={(e) => onSelectYear(e.value)} options={yearsList}
                      optionLabel="year" filter showClear showFilterClear emptyFilterMessage={'Sin resultados'}
                      placeholder="Año" style={{marginTop: 10, width: '100%'}}/>

            <span style={{marginTop: 15, color: '#282A36'}}>Fabricantes</span>

            <Dropdown value={selectManufacturers} onChange={(e) => filtersStore?.setSelectManufacturers(e.value)}
                      options={manufacturers}
                      optionLabel="manufacturers" filter showClear showFilterClear emptyFilterMessage={'Sin resultados'}
                      onClick={() => filtersStore?.BackHome(false)}
                      placeholder="Fabricantes" style={{marginTop: 10, width: '100%'}}/>

            <span style={{marginTop: 10, color: '#282A36'}}>Grupo/Sub grupo/Nombre producto</span>


            <Dropdown value={selectGroup} onChange={(e) => onSelectedGroup(e.value)}
                      options={groups}
                      optionLabel="group" filter showClear showFilterClear emptyFilterMessage={'Sin resultados'}
                      placeholder="Grupo" style={{marginTop: 10, width: '100%'}}/>

            <Dropdown value={selectSubGroup} onChange={(e) => onSelectSubGroup(e.value)}
                      options={subGroupList}
                      optionLabel="subGroup" filter showClear showFilterClear emptyFilterMessage={'Sin resultados'}
                      placeholder="Sub grupo" style={{marginTop: 10, width: '100%'}}/>

            <Dropdown value={selectSchema} onChange={e => onSelectSchema(e.value)} options={schemasList_}
                      optionLabel="name" filter showClear showFilterClear emptyFilterMessage={'Sin resultados'}
                      placeholder="Nombre producto" style={{marginTop: 10, width: '100%'}}/>

        </div>
    )
}


export default observer(FilterMMA)
