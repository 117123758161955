import Header from "../home/header/Header";
import Footer from "../home/footer/Footer";
import Recover from "./Recover";


function RecoverPassword() {

    return (
        <div className="wrapper-detail">
            <Header/>
            <div className="main-detail-container">
                <Recover/>
            </div>
            <footer>
                <Footer/>
            </footer>
        </div>
    )
}

export default RecoverPassword
