import {Formik} from 'formik';
import React, {useContext, useEffect, useState} from "react";
import {useStyles} from "../../utils/Styles";
import {clientDeliverySchema} from "../../utils/YupSchemas";
import InputForm from "../../palette/InputForm";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {Autocomplete} from "@mui/lab";
import {cities, province} from "../../utils/ProvincieCities";
import {Filters} from "../../utils/HelpFunctions";
import {doc, setDoc,} from "firebase/firestore";
import {db} from "../../firebase/Firebase";
import {StoreContext} from "../../store/Context";
import {observer} from "mobx-react-lite";
import CustomizedSnackbars from "../../palette/SnacksBar";
import CircularProgress from "@mui/material/CircularProgress";
import {useNavigate} from "react-router-dom";
import Alert from '@mui/material/Alert';
import {useLocalStorage} from "../../utils/useLocalStorage";

function ClientDeliveryData(props) {

    const {dataStore} = useContext(StoreContext)

    const {user, clientData, deliveryData} = dataStore


    const {setActiveStep, setOpenModalRegister,} = props


    const classes = useStyles();

    const [valueProvinces, setValueProvince] = useState(null);
    const [inputValueProvinces, setInputValueProvinces] = useState('');
    const [provinceSelect, setProvinceSelect] = useState('')
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [citiesArr, setCitiesArr] = useState([])
    const [open, setOpen] = useState(false);
    const [snacks, setSnacks] = useState({message: '', type: 'success'})
    const [textButton, setTextButton] = useState('Finalizar')
    const [loading, setLoading] = useState(false)
    const [city, setCity] = useLocalStorage("city", "");
    const [province_1, setProvince_1] = useState(deliveryData?.deliveryProvince);

    const navigate = useNavigate();

    useEffect(() => {
        const city = {city: deliveryData.deliveryCity}
        setValue(city)

        setValueProvince({province: deliveryData.deliveryProvince})
    }, [])


    useEffect(() => {
        let isMounted = true;

        setCitiesArr([])

        if (provinceSelect !== '' && province_1) {
            //provinceSelect !== deliveryData.deliveryProvince && setInputValue('')
            provinceSelect !== province_1 && setValue(null)
        }

        const filterProvince = Filters(province, 'province', provinceSelect)
        const id = filterProvince[0]?.id
        const filtered = Filters(cities, 'id', parseInt(id))
        isMounted && setCitiesArr(filtered)
        setProvince_1(filterProvince[0]?.province)

        return () => {
            isMounted = false
        }
    }, [provinceSelect])

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleDeliveryData = async (values) => {

        if (inputValueProvinces === '') {
            setOpen(true)
            setSnacks({...snacks, message: 'Verifique el campo provincia!', type: 'warning'})
            return
        }

        if (inputValue === '') {
            setOpen(true)
            setSnacks({...snacks, message: 'Verifique el campo ciudad!', type: 'warning'})
            return
        }

        console.log(clientData)

        const uid = user.uid
        const newData = {...clientData, ...values,}
        const update = {...newData, deliveryCity: inputValue}
        setCity(inputValue)

      setTextButton('Finalizando')
        setLoading(true)

        try {
            await setDoc(doc(db, "clients", uid), update);
            setActiveStep(0)
            setOpenModalRegister(false)
            navigate('/order')
        } catch (e) {
            setOpen(true)
            setSnacks({...snacks, message: 'Error inesperado intentolo otra vez!', type: 'error'})
            setTextButton('Finalizar')
            setLoading(false)
        }
    }


    return (
        <>
            <Alert severity="warning">
                El costo de envio y el valor final puede variar de acuerdo a la ciudad de entrega.
            </Alert>
            <div style={{height: 5}}/>
            <Formik
                    initialValues={deliveryData}
                    validationSchema={clientDeliverySchema}
                    onSubmit={handleDeliveryData}
                >
                    {({
                          values,
                          errors,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                      }) => {


                    if (inputValueProvinces !== '') {
                        values.deliveryProvince = inputValueProvinces
                    }

                    if (inputValue !== '') {
                        values.deliveryCity = inputValue
                    }

                    return (

                        <>
                            <Autocomplete
                                id="combo-box"
                                name="deliveryProvince"
                                options={province}
                                getOptionLabel={(option) => option.province}
                                style={{marginTop: 7, width: '100%'}}
                                size={'small'}
                                value={valueProvinces}
                                onChange={(event, newValue) => {
                                    setValueProvince(newValue);
                                }}
                                onInputChange={(event, newInputValue) => {
                                    setInputValueProvinces(newInputValue);
                                }}
                                inputValue={inputValueProvinces}
                                renderInput={(params) => {

                                    setProvinceSelect(params.inputProps.value)

                                    return (
                                        <TextField {...params}
                                                   label="Provincia"
                                                   variant="outlined"
                                                   name="deliveryProvince"
                                                   className={errors.province ? null : classes.formInput}
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                            //  value={values.deliveryProvince}
                                                   error={!!errors.deliveryProvince}
                                                   helperText={errors.deliveryProvince}
                                                   size={'small'}
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                        />
                                    )
                                }}
                            />

                            <Autocomplete
                                id="combo-box"
                                name="cities"
                                options={citiesArr}
                                getOptionLabel={(option) => option.city}
                                noOptionsText={'Escoja una provincia'}
                                style={{marginTop: 10, width: '100%'}}
                                size={'small'}
                                value={value}
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                inputValue={inputValue}

                                renderInput={(params) => {

                                    //values.city = params.inputProps.value
                                    //setCitySelect(values.city)

                                    return (
                                        <TextField {...params}
                                                   label="Ciudad"
                                                   variant="outlined"
                                                   name="deliveryCity"
                                                   className={errors.city ? null : classes.formInput}
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   value={values.deliveryCity}
                                                   error={!!errors.deliveryCity}
                                                   helperText={errors.deliveryCity}
                                                   size={'small'}
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                        />
                                    )
                                }
                                }
                            />

                            <InputForm label="Calle principal" full field={'deliveryMainStreet'} errors={errors}
                                       handleChange={handleChange} values={values} top={15}
                                       handleBlur={handleBlur}
                                       maxLength={30} width={'100%'} small
                            />
                            <InputForm label="No. de casa/Dpto" full field={'deliveryHouseNumber'}
                                       errors={errors} top={15}
                                       handleChange={handleChange} values={values}
                                       handleBlur={handleBlur}
                                       maxLength={5} width={'100%'} small
                            />

                            <InputForm label="Calle secundaria" full field={'deliverySecondaryStreet'}
                                       errors={errors} top={10}
                                       handleChange={handleChange} values={values}
                                       handleBlur={handleBlur}
                                       maxLength={30} width={'100%'} small
                            />
                            <InputForm label="Referencia" full field={'deliveryReference'} errors={errors}
                                       handleChange={handleChange} values={values}
                                       handleBlur={handleBlur} top={10}
                                       maxLength={50} width={'100%'} small
                            />
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                sx={{mt: 1, mr: 1}}
                            >
                                {loading && <CircularProgress size={15} style={{marginRight: 7, color: 'white'}}/>}
                                {textButton}
                            </Button>
                            <Button
                                onClick={handleBack}
                                sx={{mt: 1, mr: 1}}
                            >
                                Atras
                            </Button>
                        </>
                    )
                }
                }
            </Formik>

            <CustomizedSnackbars open={open} setOpen={setOpen} message={snacks.message} type={snacks.type}/>
        </>
    )

}

export default observer(ClientDeliveryData)
