import {collection, getDocs, limit, query, where, orderBy} from "firebase/firestore";
import {auth, db} from "../../firebase/Firebase";
import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {logo} from "../../utils/Const";
import Stack from "@mui/material/Stack";
import Text from "../../palette/Text";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {signOut} from "firebase/auth";
import Button from "@mui/material/Button";
import {StoreContext} from "../../store/Context";
import {observer} from "mobx-react-lite";
import Divider from "@mui/material/Divider";
import TextField from '@mui/material/TextField';
import CustomizedSnackbars from "../../palette/SnacksBar";
import OrderList from "./OrderList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useMediaQuery from "@mui/material/useMediaQuery";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import InputAdornment from '@mui/material/InputAdornment';
import CancelIcon from '@mui/icons-material/Cancel';
import {OrderDes} from "../../utils/HelpFunctions";

function OrderHistory() {

    const {dataStore} = useContext(StoreContext)

    const {user, } = dataStore


    const [anchorEl, setAnchorEl] = useState(null);
    const [snacks, setSnacks] = useState({message: '', type: 'success'})
    const [open, setOpen] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [valueInit, setValueInit] = useState('');
    const [valueEnd, setValueEnd] = useState('');
    const [value, setValue] = useState('');
    const [dataOrder, setDataOrder] = useState(null);
    const [copyData, setCopyData] = useState(null);
    const [trigger, setTrigger] = useState(false);

    const openMenu = Boolean(anchorEl);
    const matches = useMediaQuery('(min-width:768px)');
    const navigate = useNavigate();

    const time = new Date();
    time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
    window.location.hash = "no-back-button";

    useEffect(() => {

        let isMounted = true
        const GetOrders = async () => {
            const uid = user.uid
            try {
                const q = query(collection(db, "clients", uid, 'orders'), orderBy("actualDate", "desc"), limit(10));
                const querySnapshot = await getDocs(q);
                const arr = []
                querySnapshot.forEach((doc) => {
                    const total = doc.data().total
                    let value;
                    if (typeof total === 'number') {
                        value = total.toFixed(2)
                    } else {
                        value = total
                    }

                    arr.push({...doc.data(), docId: doc.id, total: value})
                })

                const shortData = OrderDes(arr, 'actualDate')


                setDataOrder(shortData)
                setCopyData(shortData)

            } catch (e) {
                // console.log("Error getting cached document:", e);
            }
        }

        (isMounted && user) && GetOrders()

        return (() => {
            isMounted = false
        })


    }, [user, trigger])


    useEffect(() => {

        let isMounted = true

        const GetData = async () => {

            const uid = user.uid

            if (valueInit === '' || valueEnd === '') {
                setTrigger(!trigger)
            }

            if (valueInit !== '' && valueEnd !== '') {


                if (valueEnd < valueInit) {
                    setOpenSnack(true)
                    setSnacks({
                        ...snacks,
                        message: 'La fecha de inicio debe ser mayor a la fecha final!',
                        type: 'warning'
                    })
                    return
                }

                const q = query(collection(db, "clients", uid, 'orders'),
                    where('actualDate', ">=", valueInit),
                    where('actualDate', "<=", valueEnd),
                    limit(10));
                const querySnapshot = await getDocs(q);
                const arr = []
                querySnapshot.forEach((doc) => {
                    arr.push({...doc.data(), docId: doc.id})
                })

                setDataOrder(arr)
            }
        }

        (isMounted && user) && GetData()

        return (() => {
            isMounted = false
        })


    }, [valueInit, valueEnd])


    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleBack = async () => {

    }

    const onHandleChange = (text) => {

        let search = new RegExp(text, 'i')
        if (text !== '') {

            const filteredOrderNumber = copyData.filter(e => e.orderNumber.match(search))
            const filteredOrderDate = copyData.filter(e => e.orderDate.match(search))
            const filteredTotal = copyData.filter(e => e.total.match(search))
            const filteredPayment = copyData.filter(e => e.payment.match(search))
            const filteredStatus = copyData.filter(e => e.status.match(search))

            const newArray = [...filteredOrderNumber, ...filteredOrderDate, ...filteredTotal, ...filteredPayment,
                ...filteredStatus]

            const ids = newArray.map(o => o.docId)
            const filtered = newArray.filter(({docId}, index) => !ids.includes(docId, index + 1))

            setDataOrder(filtered)

            setValue(text)
        } else {
            setValue('')
            setDataOrder(copyData)
        }

    }


    return (
        <div className="wrapper-detail">
            <header>
                <div className="header-container">
                    <div className="contacts-container">
                        <div style={{width: '100%', cursor: 'pointer'}} onClick={() => {
                            navigate('/')
                            handleBack().catch(() => null)
                        }}>
                            <img alt="" src={logo} width={matches ? '100%' : '80%'} height={matches ? 120 : 100}/>
                        </div>
                    </div>
                    <div className="session-container" style={{position: 'relative', zIndex: 2000}}>
                        {user ?
                            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">

                                <Text text={`${user.displayName} ( ${user.typeClient} )`} size={12} weeigh={'500'}/>

                                <IconButton style={{color: 'red'}}
                                            id="basic-button"
                                            aria-controls="basic-menu"
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClickMenu}
                                >
                                    <FilterListOutlinedIcon style={{color: '#282A36'}}/>
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={handleCloseMenu}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => {
                                        signOut(auth).then(() => {
                                            dataStore.ShoppingCart([])
                                            setSnacks({...snacks, message: 'Saliendo..!', type: 'info'})
                                            navigate('/')
                                        }).catch(() => {
                                            setOpen(true)
                                            setSnacks({
                                                ...snacks,
                                                message: 'Error inesperado intentelo otra vez!',
                                                type: 'error'
                                            })
                                        })
                                    }
                                    }>Cerrar sesión</MenuItem>
                                </Menu>

                            </Stack> :
                            <Stack spacing={2} direction="row">
                                <Button style={{textTransform: 'none'}} variant="text"
                                        onClick={() => {
                                            navigate('/session')
                                            /// dataStore.Path(path)
                                        }}
                                >Iniciar</Button>
                                <Button
                                    onClick={() => {
                                        navigate('/session')
                                        //dataStore.Path(path)
                                    }}
                                    style={{textTransform: 'none', backgroundColor: 'red'}}
                                    variant="contained" disableElevation>
                                    Registarse
                                </Button>
                            </Stack>
                        }
                    </div>
                </div>
                <div style={{width: matches ? '80%' : '90%',}}>
                    <Button variant="text" startIcon={<ArrowBackIcon/>}
                            onClick={async () => {
                                //dataStore.BackDetailHome(true)
                                // dataStore.UpdateFilter({...filterData})
                                navigate("/")
                            }}>Volver</Button>
                </div>
            </header>
            <div className="main-detail-container">
                <div className={'shopping-container'}>
                    <Divider/>
                    <br/>
                    <div style={{
                        width: '100%', display: 'flex', flexDirection: matches ? 'row' : 'column', alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <div style={{
                            display: 'flex', flexDirection: matches ? 'row' : 'column',
                            width: matches ? 'auto' : '100%'
                        }}>
                            <TextField
                                id="date"
                                label="Inicio"
                                type="date"
                                value={valueInit}
                                onChange={(e) => setValueInit(e.target.value)}
                                size={'small'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton aria-label="delete" onClick={() => setValueInit('')}>
                                                <CancelIcon/>
                                            </IconButton>
                                        </InputAdornment>,
                                }}
                            />

                            <TextField
                                style={{marginLeft: matches ? 10 : 0, marginTop: matches ? 0 : 10}}
                                id="date"
                                label="Fin"
                                type="date"
                                value={valueEnd}
                                onChange={(e) => setValueEnd(e.target.value)}
                                size={'small'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton aria-label="delete" onClick={() => setValueEnd('')}>
                                                <CancelIcon/>
                                            </IconButton>
                                        </InputAdornment>,
                                }}
                                fullWidth={!matches}
                            />
                        </div>
                        <TextField
                            style={{marginTop: matches ? 0 : 10}}
                            label={'Buscar'}
                            id="outlined-margin-dense"
                            size={'small'}
                            variant="outlined"
                            onChange={e => onHandleChange(e.target.value)}
                            value={value}
                            fullWidth={!matches}
                        />
                    </div>

                    <br/>

                    <OrderList dataOrder={dataOrder} user={user}/>


                </div>
            </div>
            <CustomizedSnackbars open={openSnack} setOpen={setOpenSnack} message={snacks.message} type={snacks.type}/>
        </div>
    )
}

export default observer(OrderHistory)

