import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {useContext, useEffect, useState} from "react";
import {useStyles} from "../../../utils/Styles";
import {StoreContext} from "../../../store/Context";
import {observer} from "mobx-react-lite";
import {format} from "date-fns";

function FilterManufacturers(props) {

    const {dataStore, filtersStore} = useContext(StoreContext)
    const {filterData, showNoStock, globalFilterCombination, globalFilter} = dataStore
    const {valueManufacturers, inputValueManufacturers} = filtersStore


    const {manufacturers, setTriggerProd, triggerProd} = props

    const classes = useStyles();

    useEffect(() => {
        let isMounted = true

        const GetManufacturers = async () => {
            if (inputValueManufacturers !== '') {
                const data = {...filterData, manufacturers: inputValueManufacturers}
                const dataGlobal = {...globalFilterCombination,  manufacturers: inputValueManufacturers}
                dataStore.GlobalFilterCombination(dataGlobal)

                if (globalFilter) {
                    return
                }

                await dataStore.FilterData(data, showNoStock)
            } else {
                const data = {...filterData, manufacturers: ''}
                const dataGlobal = {...globalFilterCombination,  manufacturers: ''}
                dataStore.GlobalFilterCombination(dataGlobal)

                if (globalFilter) {
                    return
                }
                dataStore.UpdateFilter(data)
                await dataStore.FilterData(data, showNoStock)


                const {vehicles, models, qYear, manufacturers, schema, groups, subGroup, schemaArr} = data
                if (vehicles === '' && models === '' && qYear === '' && manufacturers === '' && schema === ''
                    && groups === false && subGroup === false && schemaArr.length === 0) {
                    dataStore.BackDetailHome(false)
                    setTriggerProd(!triggerProd)
                }

            }
        }

        isMounted && GetManufacturers()

        return (() => {
            isMounted = false
        })

    }, [valueManufacturers, inputValueManufacturers])

    return (
        <div className="filter-container">
            <span style={{marginTop: 10,  color: '#282A36'}}>Fabricantes</span>
            {manufacturers &&
            <Autocomplete
                id="manufactures"
                style={{marginTop: 10}}
                freeSolo
                fullWidth
                options={manufacturers?.map((option) => option.manufacturers)}
                value={valueManufacturers}
                noOptionsText={'Fabricantes'}
                onChange={(event, newValue) => {
                    //setManufacturers(newValue);
                    filtersStore.ValuesManufacturers(newValue)
                }}
                inputValue={inputValueManufacturers}
                onInputChange={(event, newInputValue) => {
                    // setInputValueManufacturers(newInputValue);
                    filtersStore.InputValuesManufacturers(newInputValue)
                }}
                renderInput={(params) =>
                    <TextField {...params} label="Fabricantes" fullWidth size="small" className={classes.formInput}/>
                }
            />}
        </div>
    )
}

export default observer(FilterManufacturers)
