import TextField from '@mui/material/TextField';
import Text from "../../../palette/Text";
import {useStyles} from "../../../utils/Styles";
import {useContext, useEffect, useState} from "react";
import {StoreContext} from "../../../store/Context";
import {observer} from "mobx-react-lite";
import axios from "axios";
import {activeServiceUrl, headers} from "../../../utils/Const";
import {Filters} from "../../../utils/HelpFunctions";


function SchemaResult(props) {

    const {dataStore} = useContext(StoreContext)

    const {imageUrl, mainProducts, schema} = props

    const classes = useStyles();

    const [values, setValues] = useState({
        measureA: '',
        measureB: '',
        measureC: '',
        measureD: '',
        measureE: '',
    });

    const [products, setProducts] = useState(mainProducts)
    const [copyProducts, setCopyProducts] = useState([])
    const [dataMeasure, setDataMeasure] = useState(null)
    const [control, setControl] = useState(true)
    const [trigger, setTrigger] = useState(false)

    const [filterValues, setFilterValues] = useState({
        measuresA: '',
        measuresB: '',
        measuresC: '',
        measuresD: '',
        measuresE: '',
    });

    useEffect(() => {
        const f = Filters(mainProducts, 'schema', schema?.name)
        setProducts(f)
        setCopyProducts(f)
    }, [schema])


    useEffect(() => {

        const ClearInput = async () => {
            const data = {products: products, ...values}
            const res = await axios.post(activeServiceUrl, data, headers)
            dataStore.MainProducts(res.data.result)
        }

        products.length > 0 && ClearInput().catch(() => null)

    }, [trigger])

    useEffect(() => {

        control && setDataMeasure({
            products: copyProducts,
            measureA: '',
            measureB: '',
            measureC: '',
            measureD: '',
            measureE: '',
        })


    }, [imageUrl, copyProducts])

    useEffect(() => {

        const ActiveService = async () => {
            if (dataMeasure) {
                await axios.post(activeServiceUrl, dataMeasure, headers)
            }
        }

        ActiveService().catch(() => null)


    }, [dataMeasure])

    useEffect(() => {
        if (imageUrl) {
            setValues({
                measureA: '',
                measureB: '',
                measureC: '',
                measureD: '',
                measureE: '',
            })
        }
    }, [imageUrl])

    const ClearInput = (event) => {


        if (event.code === 'Delete') {
            setTimeout(() => setTrigger(!trigger), 400)
            // setTrigger(!trigger)
        }
        if (event.code === 'Backspace') {
            //  setTimeout(() => setTrigger(!trigger), 400)

        }
    }

    useEffect(() => {
        const {measuresA, measuresB, measuresC, measuresD, measuresE} = filterValues
        if (measuresA === '' && measuresB === '' && measuresC === '' && measuresD === '' && measuresE === '') {
            dataStore.MainProducts(copyProducts);
        } else {
            filterArray();
        }
    }, [filterValues]);


    const handleInputChange = (measure, value) => {
        setFilterValues({...filterValues, [measure]: value,});
    };
    const filterArray = () => {

        const filtered = products.filter(item => {
            return (
                (filterValues.measuresA === '' ||
                    isValueInRange(item.measuresA, filterValues.measuresA)) &&
                (filterValues.measuresB === '' ||
                    isValueInRange(item.measuresB, filterValues.measuresB)) &&
                (filterValues.measuresC === '' ||
                    isValueInRange(item.measuresC, filterValues.measuresC)) &&
                (filterValues.measuresD === '' ||
                    isValueInRange(item.measuresD, filterValues.measuresD)) &&
                (filterValues.measuresE === '' ||
                    isValueInRange(item.measuresE, filterValues.measuresE))
            );
        });

        dataStore.MainProducts(filtered);
    };

    const isValueInRange = (value, filterValue) => {
        const numericValue = parseFloat(value);
        const numericFilterValue = parseFloat(filterValue);

        if (isNaN(numericFilterValue)) {
            return true;
        }

        const lowerBound = numericFilterValue - 5;
        const upperBound = numericFilterValue + 5;

        return numericValue >= lowerBound && numericValue <= upperBound;
    };


    return (
        <div className="filter-container">
            <img src={imageUrl} alt="" width={'auto'} height={200}
                 style={{maxWidth: 450}}/>


            <div style={{display: 'flex', flexDirection: 'row',}}>
                <Text text={`MEDIDAS ${schema?.units === undefined ? '' : schema?.units}`} color="#333"
                      weight={'bold'}/>
            </div>

            <TextField
                className={classes.formInput}
                value={filterValues.measuresA}
                type="number"
                onKeyDown={(e) => ClearInput(e)}
                onChange={e => handleInputChange('measuresA', e.target.value)}
                inputProps={{min: 1}}
                label={`A`}
                size={'small'}
            />

            <TextField
                style={{marginTop: 10}}
                className={classes.formInput}
                type="number"
                value={filterValues.measuresB}
                onKeyDown={(e) => ClearInput(e)}
                onChange={e => handleInputChange('measuresB', e.target.value)}
                inputProps={{min: 1}}
                label={'B'}
                size={'small'}
            />

            <TextField
                style={{marginTop: 10}}
                className={classes.formInput}
                value={filterValues.measuresC}
                type="number"
                onChange={e => handleInputChange('measuresC', e.target.value)}
                inputProps={{min: 1}}
                label={'C'}
                size={'small'}
            />

            <TextField
                style={{marginTop: 10}}
                className={classes.formInput}
                value={filterValues.measuresD}
                type="number"
                onChange={e => handleInputChange('measuresD', e.target.value)}
                onKeyDown={(e) => ClearInput(e)}
                inputProps={{min: 1}}
                size={'small'}
                label={'D'}
            />

            <TextField
                style={{marginTop: 10}}
                className={classes.formInput}
                value={filterValues.measuresE}
                type="number"
                onKeyDown={(e) => ClearInput(e)}
                onChange={e => handleInputChange('measuresE', e.target.value)}
                inputProps={{min: 1}}
                size={'small'}
                label={'E'}
            />
        </div>
    )
}

export default observer(SchemaResult)

