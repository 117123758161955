import {logo} from "../../utils/Const";
import React from "react";
import {observer} from "mobx-react-lite";

function TermsConditions() {


    return (
        <div className="wrapper-detail" >
            <header>
                <div className="header-container">
                    <div className="contacts-container">
                        <div style={{width: '100%', cursor: 'pointer'}} >
                            <img alt="" src={logo} width={'100%'} height={120}/>
                        </div>
                    </div>
                    <div />
                </div>
            </header>

            <div className="main-detail-container">
                <div style={styles.termsConditions}>

                    <span style={{fontSize: 18}}><strong>Términos y Condiciones</strong></span>

                    <p style={{fontWeight: '500'}}>

                        A través del sitio web www.mazpartes.com, MIRANDA NUÑEZ HERNAN VLADIMIR en adelante “MAZPARTES”
                        ofrece un servicio al usuario bajo la condición de “EL USUARIO”, el cual al acceder y/o usar
                        dicho sitio acepta sin restricción los Términos y Condiciones aquí establecidos.
                        MAZPARTES tendrá el derecho de modificar el presente acuerdo, así también realizar cambios a
                        cualquier producto o servicio sin ningún tipo de notificación previa y sin asumir ninguna
                        responsabilidad por cualquier cambio o modificación.
                        Mediante el presente sitio web EL USUARIO podrá hacer uso de las funcionalidades que permite el
                        propio sitio exclusivamente para realizar compras legitimas y no fraudulentas.
                        EL USUARIO reconoce a MAZPARTES como propietario absoluto y exclusivo del contenido del sitio
                        web, así como sus derechos de autor.
                        Todo el tiempo EL USUARIO es el único responsable del uso de la contraseña otorgada, MAZPARTES
                        no asume ninguna responsabilidad derivada del uso de la contraseña por parte de terceros.
                    </p>
                    <br/>

                    <span style={{fontSize: 18}}><strong>Privacidad</strong></span>

                    <p style={{fontWeight: '500'}}>

                        MAZPARTES se compromete a proteger la información de sus usuarios y manejarlos con la mayor
                        cautela y cuidado posible. Nuestros procesos utilizan grandes estándares de protección.
                        Para que EL USUARIO acceda a todos beneficios del sitio web es necesario que se registre
                        ingresando todos los datos solicitados y cree una contraseña la cual podrá cambiar cuando
                        considere necesario, además en caso de olvido podrá recuperar su contraseña mediante su correo
                        electrónico registrado.
                    </p>

                    <p style={{fontWeight: '500'}}>
                        Los datos entregados por EL USUARIO serán usados por MAZPARTES para enviar información según
                        interés.
                    </p>

                    <br/>
                    <span style={{fontSize: 18}}><strong>Devoluciones</strong></span>

                    <p style={{fontWeight: '500'}}>
                        Si desea realizar la devolución de algún producto que fue adquirido en esta página web o en uno
                        de nuestros puntos de venta físicos lo puede realizar a través del mismo punto de facturación
                        del producto.
                    </p>

                    <p style={{fontWeight: '500'}}>Las condiciones para receptar una devolución son las siguientes:
                    </p>

                    <p style={{fontWeight: '500'}}>
                        <p style={{marginLeft: 10}}>
                            1. Solo se aceptan devoluciones hasta 2 días de realizada la compra.
                        </p>
                    </p>


                    <p style={{fontWeight: '500'}}>
                        <p style={{marginLeft: 10}}>
                            2. El producto debe encontrarse en perfectas condiciones y en sus respectivos empaques con
                            etiquetas.
                        </p>
                    </p>
                    <p style={{fontWeight: '500'}}>
                        <p style={{marginLeft: 10}}>
                            3. No se aceptan productos que hayan sido utilizados o muestren signos de aquello.
                        </p>
                    </p>
                    <p style={{fontWeight: '500'}}>
                        <p style={{marginLeft: 10}}>
                            4. Productos que contengan partes eléctricas o sean eléctricos no se acepta devoluciones.
                        </p>
                    </p>
                    <p style={{fontWeight: '500'}}>
                        Una vez receptada la devolución será evaluada por el personal autorizado y puede ser aceptada o
                        negada.
                    </p>

                    <br/>
                    <span style={{fontSize: 18}}><strong>Despacho y envíos</strong></span>

                    <p style={{fontWeight: '500'}}>
                        Una vez realizado el proceso de compra EL USUARIO visualizará el costo de envío, el cual podrá
                        deshabilitar si desea retirar el producto del punto de facturación en cuyo caso lo podrá hacer
                        en los 30 minutos posteriores de haber culminado el proceso de compra.
                    </p>
                    <p style={{fontWeight: '500'}}>
                        La atención a pedidos y despachos son de lunes a viernes en horarios de 8H30 a 12H30 y 13H30 a
                        17H30
                    </p>
                    <p style={{fontWeight: '500'}}>
                        En el caso de envíos a nivel nacional se los realizara mediante empresas de Courier
                        especializadas o de transportes interprovinciales tratando siempre que los pedidos sean
                        entregados antes de las 24 horas esto siempre y cuando se culmine el proceso de compra antes de
                        las 14H00.
                    </p>
                </div>
            </div>
        </div>
    )

}

export default observer(TermsConditions)


const styles = {
    termsConditions: {
        width: '80%', margin: 'auto',
        background: 'white',
        padding: 10,
        borderRadius: 5

    }
}
