import Footer from "../home/footer/Footer";
import ShoppingCartDetails from "./ShoppingCartDetails";
import {logo} from "../../utils/Const";
import Stack from "@mui/material/Stack";
import Text from "../../palette/Text";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {signOut} from "firebase/auth";
import {auth} from "../../firebase/Firebase";
import Button from "@mui/material/Button";
import React, {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {StoreContext} from "../../store/Context";
import {observer} from "mobx-react-lite";
import CustomizedSnackbars from "../../palette/SnacksBar";
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import useMediaQuery from "@mui/material/useMediaQuery";

function ShoppingCart() {

    const {dataStore} = useContext(StoreContext)

    const {user} = dataStore

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [snacks, setSnacks] = useState({message: '', type: 'success'})

    const openMenu = Boolean(anchorEl);

    const navigate = useNavigate();
    const matches = useMediaQuery('(min-width:768px)');

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };


    return(
        <div className="wrapper-detail">
            {/*<Header path={'/product-details'}/>*/}
            <header>
                <div className="header-container">
                    <div className="contacts-container">
                        <div style={{width: '100%', cursor: 'pointer'}} onClick={() => {
                            navigate('/')
                           // handleBack().catch(() => null)
                        }}>
                            <img alt="" src={logo} width={ matches ?'100%' : '80%'} height={ matches ? 120 : 100}/>
                        </div>
                    </div>
                    <div className="session-container" style={{position: 'relative', zIndex: 2000}}>
                        {user ?
                            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">

                                <Text text={`${user.displayName} ( ${user.typeClient} )`} size={12} weeigh={'700'}
                                color={'#333'}/>

                                <IconButton style={{color: 'red'}}
                                            id="basic-button"
                                            aria-controls="basic-menu"
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClickMenu}
                                >
                                    <FilterListOutlinedIcon style={{color: '#282A36'}}/>
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={handleCloseMenu}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => {
                                        signOut(auth).then(() => {
                                            dataStore.ShoppingCart([])
                                            //setOpenSnak(true)
                                            setSnacks({...snacks, message: 'Saliendo..!', type: 'info'})
                                            navigate('/')
                                        }).catch(() => {
                                            setOpen(true)
                                            setSnacks({
                                                ...snacks,
                                                message: 'Error inesperado intentelo otra vez!',
                                                type: 'error'
                                            })
                                        })
                                    }
                                    }>Cerrar sesión</MenuItem>
                                    <MenuItem onClick={() => {
                                        navigate('/order-history')
                                    }}>Historial de pedidos</MenuItem>
                                    <MenuItem onClick={() => {
                                        navigate('/update-password')
                                    }
                                    }>Actualizar contraseña</MenuItem>

                                </Menu>

                            </Stack> :
                            <Stack spacing={2} direction="row">
                                <Button style={{textTransform: 'none'}} variant="text"
                                        onClick={() => {
                                            navigate('/session')
                                            /// dataStore.Path(path)
                                        }}
                                >Iniciar</Button>
                                <Button
                                    onClick={() => {
                                        navigate('/session')
                                        //dataStore.Path(path)
                                    }}
                                    style={{textTransform: 'none', backgroundColor: 'red'}}
                                    variant="contained" disableElevation>
                                    Registarse
                                </Button>
                            </Stack>
                        }
                    </div>
                </div>
            </header>
            <div className="main-detail-container">
                <ShoppingCartDetails/>
            </div>
            <footer>
                <Footer/>
            </footer>
            <CustomizedSnackbars open={open} setOpen={setOpen} message={snacks.message} type={snacks.type}/>
        </div>
    )
}

export default observer(ShoppingCart)

