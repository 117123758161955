import {db} from "../firebase/Firebase";
import {collection, getDocs, query, where} from "firebase/firestore";
import {configure, makeAutoObservable, runInAction} from 'mobx';
import {FilterDelete, Filters, Order} from "../utils/HelpFunctions";
import axios from "axios";
import {filtersUrl, headers} from "../utils/Const";

configure({
    enforceActions: 'never',
})

class DataStore {

    mainProducts = null
    product = null
    schema = null
    group = null
    subGroup = null
    stock = null
    realPrice = null
    price = null
    newPrice = null
    discount = null
    shoppingCart = []
    showNoStock = false;
    backDetailHome = false
    vehicles = []
    filterData = {
        vehicles: '', models: '', qYear: '', manufacturers: '', schema: '', groups: false,
        subGroup: false, schemaArr: [],
    }

    loadingProducts = false

    path = '/'

    user = null

    clientDiscount = 0

    uidCart = null

    observation = ''

    personalData = null
    directionData = null
    deliveryData = null
    clientData = null

    timer = false

    dataOrder = null

    schemaFilter = null

    controlStores = null

    globalFilter = false
    globalFilterCombination = {
        vehicles: '', models: '', qYear: '', manufacturers: '', schema: '',
    }

    valueSearch = ''
    updateName = ''

    triggerProd_ = false

    globalFilterProducts = false

    sideFilters = null
    copyMainProducts = null
    ivaValue = 15


    constructor() {
        makeAutoObservable(this);
    }

    MainProducts = (products) => this.mainProducts = products;

    CopyMainProducts = (products) => this.copyMainProducts = products;

    GetProductReload = async (cod, dis, realPrice, newPrice, price) => {
        const q = query(collection(db, "products"), where("cod", "==", cod));

        const querySnapshot = await getDocs(q);
        let newObject;
        querySnapshot.forEach((doc) => {
            newObject = {...doc.data(), uidProduct: doc.id}
        });

        let stock = newObject?.stores.reduce(function (prev, cur) {
            return prev + cur.stock;
        }, 0);


        runInAction(() => {
            this.product = newObject
            this.realPrice = realPrice
            this.newPrice = newPrice
            this.stock = stock
            this.price = price
            this.discount = dis
        })
    }

    GetProduct = async (product, cod, dis, realPrice, newPrice, price, clientDiscount) => {
        const q = query(collection(db, "products"), where("cod", "==", cod));

        const querySnapshot = await getDocs(q);
        let newObject;
        querySnapshot.forEach((doc) => {
            newObject = {...doc.data(), uidProduct: doc.id}
        });


        let stock = newObject?.stores.reduce(function (prev, cur) {
            return prev + cur.stock;
        }, 0);


        runInAction(() => {
            this.product = product
            this.realPrice = realPrice
            this.newPrice = newPrice
            this.stock = stock
            this.price = price
            this.discount = dis
            this.clientDiscount = clientDiscount
        })
    }

    GetSchema = async (name) => {
        const q = query(collection(db, "schemas"), where("name", "==", name));

        const querySnapshot = await getDocs(q);
        let newObject;
        querySnapshot.forEach((doc) => {
            newObject = {...doc.data(), uidSchema: doc.id}
        });


        runInAction(() => {
            this.schema = newObject
            this.group = newObject.group
            this.subGroup = newObject.subGroup
        })

    }

    TriggerProd = (triggerProd) => this.triggerProd_ = triggerProd

    FilterData = async (filterData, stock, globalFilter) => {


        if (globalFilter) {
            return
        }

        if (filterData.groups || filterData.subGroup || filterData.schema !== '') {

            try {

                this.loadingProducts = true

                const res = await axios.post(filtersUrl, filterData, headers)
                const {result} = res.data

                this.loadingProducts = false

                if (result !== 'empty') {

                    if (result === 'ok') {
                        this.loadingProducts = false
                        return
                    }
                    let res
                    if (stock) {
                        res = Filters(result, 'status', 'Activo')
                    } else {
                        const filterStatus = Filters(result, 'status', 'Activo')
                        res = FilterDelete(filterStatus, 'stock', 0)
                    }


                    // const filtered = FilterDelete(result, 'stock', 0)
                    this.filterData = {...filterData}
                    this.mainProducts = res
                    this.loadingProducts = false

                } else {
                    this.mainProducts = []
                }

            } catch (e) {
            }


        } else {

            try {

                this.loadingProducts = true

                const resp = await axios.post(filtersUrl, filterData, headers)
                const {result} = resp.data

                this.loadingProducts = false

                if (result !== 'empty') {

                    if (result === 'ok') {

                        this.loadingProducts = false
                        return
                    }

                    let res
                    if (stock) {
                        res = Filters(result, 'status', 'Activo')
                    } else {
                        const filterStatus = Filters(result, 'status', 'Activo')
                        res = FilterDelete(filterStatus, 'stock', 0)
                    }

                    this.filterData = {...filterData}


                    if (filterData.vehicles !== '') {
                        const arrResult = [...res]
                        arrResult.forEach(items => {
                            items.brandModels = Filters(items.brandModels, 'vehicles', filterData.vehicles)
                        })
                        this.mainProducts = arrResult
                        this.filterData = {...filterData}
                        this.loadingProducts = false
                    } else {
                        this.mainProducts = res
                        this.filterData = {...filterData}
                        this.loadingProducts = false
                    }

                    if (filterData.models !== '') {
                        const arrResult = [...res]
                        arrResult.forEach(items => {
                            items.brandModels = Filters(items.brandModels, 'models', filterData.models)
                        })
                        this.mainProducts = arrResult
                        this.filterData = {...filterData}
                        this.loadingProducts = false
                    } else {
                        this.mainProducts = res
                        this.filterData = {...filterData}
                        this.loadingProducts = false
                    }

                    if (filterData.qYear !== '') {
                        const arrResult = [...res]
                        arrResult.forEach(items => {
                            const arr = []
                            items.brandModels.forEach(item => {
                                if (item.endYear !== '') {
                                    if (filterData.qYear >= item.initYear && filterData.qYear <= item.endYear) {
                                        arr.push(item)
                                    }
                                } else {
                                    arr.push(item)
                                }
                            })
                            items.brandModels = arr//FilterDelete(arr, 'initYear', '')
                        })


                        this.mainProducts = arrResult
                        this.filterData = {...filterData}
                        this.loadingProducts = false
                    } else {
                        this.mainProducts = res
                        this.filterData = {...filterData}
                        this.loadingProducts = false
                    }


                    if (filterData.manufacturers !== '') {
                        const arrResult = [...res]
                        arrResult.forEach(items => {
                            items.brandModels = FilterDelete(items.brandModels, 'manufacturers', filterData.manufacturers)
                            //items.brandModels = FilterDelete(items.brandModels, 'endYear', '')
                        })
                        this.mainProducts = arrResult
                        this.filterData = {...filterData}
                        this.loadingProducts = false
                    } else {
                        this.mainProducts = res
                        this.filterData = {...filterData}
                        this.loadingProducts = false
                    }

                } else {
                    this.filterData = {...filterData}
                    this.mainProducts = []
                    this.loadingProducts = false
                }

            } catch (e) {
            }

        }
    }

    UpdateFilter = (filterData) => {
        this.filterData = {...filterData}
    }

    ShoppingCart = (shoppingCartList) => {
        this.shoppingCart = shoppingCartList
    }

    ShowNoStock = (value) => {
        this.showNoStock = value
    }

    BackDetailHome = (value) => {
        this.backDetailHome = value
    }

    Vehicles = (vehicles) => {
        this.vehicles = Order(vehicles, 'vehicles')
    }

    Path = (path) => {
        this.path = path
    }

    User = (user) => {
        this.user = user
    }

    Stock = (stock) => {
        this.stock = stock
    }

    Product = (product) => {
        this.product = product
    }

    UIDCart = (uidCart) => {
        this.uidCart = uidCart
    }

    Observation = (observation) => {
        this.observation = observation
    }

    RegisterClientModal = (personalData, clientDirection, clientDeliveryData, clientData) => {
        this.personalData = personalData
        this.directionData = clientDirection
        this.deliveryData = clientDeliveryData
        this.clientData = clientData
    }

    DataOrder = (dataOrder) => {
        this.dataOrder = dataOrder
    }

    SchemaFilter = (schemaFilter) => {
        this.schemaFilter = schemaFilter
    }

    ControlStores = (stores) => {
        this.controlStores = Order(stores, 'order')
    }

    GlobalFilter = (global) => {
        this.globalFilter = global
    }

    GlobalFilterCombination = (data) => {
        this.globalFilterCombination = data
    }

    ValueSearch = (value) => {
        this.valueSearch = value
    }

    GlobalFilterProducts = (products) => {
        this.globalFilterProducts = products
    }

    UpdateName = (name) => {
        this.updateName = name
    }

    ///News
    SideFilter = (values) => this.sideFilters = values
    SideCopyFilter = (values) => this.sideCopyFilters= values
}

export {DataStore}
