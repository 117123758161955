import {StoresProvider} from "./store/Provider";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import IndexRoutes from "./routes/IndexRoutes"; // requires a loader
import {SnackbarProvider} from 'notistack';
import 'tailwindcss/tailwind.css'
import {PrimeReactProvider} from "primereact/api";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

function App() {


    return (
        <StoresProvider>
            <SnackbarProvider maxSnack={5}>
                <PrimeReactProvider >
                    <IndexRoutes/>
                </PrimeReactProvider>
            </SnackbarProvider>
        </StoresProvider>
    );
}

export default App;

// style={{display: modal ? 'flex' : 'none'}}
