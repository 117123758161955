import {IconButton} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {withStyles, makeStyles} from '@mui/styles';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {useNavigate} from "react-router-dom";
import {doc, getDoc, setDoc} from "firebase/firestore";
import {db} from "../../firebase/Firebase";
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import {useTheme} from "@emotion/react";
import {useContext, useMemo, useState} from "react";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Box from '@mui/material/Box';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import {StoreContext} from "../../store/Context";

const useStyles = makeStyles({
    table: {
        minWidth: 1000,
    },
    containerButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    }
});
function TablePaginationActions(props) {
    const theme = useTheme();
    const {count, page, rowsPerPage, onPageChange} = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{flexShrink: 0, ml: 2.5}}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
            </IconButton>
        </Box>
    );
}

function OrderList(props) {

    const {dataStore} = useContext(StoreContext)

    const {dataOrder, user} = props

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [locale] = useState('esES');


    const classes = useStyles();
    const navigate = useNavigate();
    const theme = useTheme();

    // Avoid a layout jump when reaching the last page with empty rows.
    const themeWithLocale = useMemo(
        () => createTheme(theme, locales[locale]),
        [locale, theme],
    );


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleDetail = async (row) => {
        const status = row.status
        const docRef = doc(db, 'clients', user.uid, 'uidOrder', 'uidOrder001');
        await setDoc(docRef, {uidOrder: status === 'PENDIENTE' ? row.docId: row.uidCart});
        navigate('/orders-pdf')
        const uid = user.uid;
        const docRefUser = doc(db, "clients", uid);
        const docSnap = await getDoc(docRefUser);
        if (docSnap.exists()) {
            const city = docSnap.data()?.city ? docSnap.data()?.city : null
            const type = docSnap.data()?.type
            const purchaseQuota = docSnap.data()?.purchaseQuota
            const paymentDeadline = docSnap.data()?.paymentDeadline
            const idClient = docSnap.data()?.idClient
            const phoneNumber = docSnap.data()?.phoneNumber
            const email = docSnap.data()?.email
            const deliveryCity = docSnap.data()?.deliveryCity
            let displayName = type === 'Judirico' ? docSnap.data().tradeName : `${docSnap.data().name}  ${docSnap.data().lastName}`;
            const discount = docSnap.data()?.discount
            const typeClient = docSnap.data()?.typeClient
            dataStore.User({
                uid, displayName, typeClient, discount, city, paymentDeadline, purchaseQuota,
                deliveryCity, type, idClient, phoneNumber, email
            })
        }
    }

    return (
        <>
            <ThemeProvider theme={themeWithLocale}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Num. orden</StyledTableCell>
                                <StyledTableCell align="center">Fecha</StyledTableCell>
                                <StyledTableCell align="center">Valor</StyledTableCell>
                                <StyledTableCell align="center">Forma de pago</StyledTableCell>
                                <StyledTableCell align="center">Estado</StyledTableCell>
                                <StyledTableCell align="center">Detalles </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? dataOrder?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : dataOrder)?.map((row, i) => {
                                return (
                                    <StyledTableRow key={i}>
                                        <StyledTableCell align="center">{row?.orderNumber}</StyledTableCell>
                                        <StyledTableCell align="center">{row?.orderDate}</StyledTableCell>
                                        <StyledTableCell
                                            align="center">{`$ ${parseFloat(row?.total).toFixed(2)}`}</StyledTableCell>
                                        <StyledTableCell align="center">{row?.payment}</StyledTableCell>
                                        <StyledTableCell align="center">{row?.status}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <div className={classes.containerButton}>
                                                <IconButton onClick={() => handleDetail(row)}>
                                                    <PictureAsPdfIcon style={{color: '#e91e63'}}/>
                                                </IconButton
                                                >
                                            </div>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, {label: 'Todo', value: -1}]}
                                    colSpan={5}
                                    count={dataOrder?.length}
                                    labelRowsPerPage={'Filas por página'}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'Filas por página',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </ThemeProvider>
        </>
    )

}

export default OrderList

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#333',
        color: 'white',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#FAFAFA',
        },
    },
}))(TableRow);
