import {observer} from "mobx-react-lite";
import {useContext, useEffect, useState} from "react";
import {StoreContext} from "../../../store/Context";
import {Carousel} from 'react-responsive-carousel';
import './main-detail.css'
import {logo} from "../../../utils/Const";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {FilterDelete, Filters, UpdateArray} from "../../../utils/HelpFunctions";
import {onAuthStateChanged} from "firebase/auth";
import {auth, db} from "../../../firebase/Firebase";
import {useNavigate} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {doc, getDoc,} from "firebase/firestore";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";

const vertical = 'button'
const horizontal = 'right'

function MainDetail() {

    const {dataStore, filtersStore} = useContext(StoreContext)

    const {filterSearch} = filtersStore

    const {inLateralFilters} = filterSearch


    const {
        product, schema, group, subGroup, stock, discount, newPrice, price, filterData, user, shoppingCart,
        mainProducts,
    } = dataStore


    const [measuresArr, setMeasuresArray] = useState([])
    const [applications, setApplication] = useState([])
    const [value, setValue] = useState(1)
    const [clientDiscountReload, setClientDiscountReload] = useState(0)
    const [cant, setCant] = useState(0)
    const [amountData, setAmountData] = useState(1)
    const [alert, setAlert] = useState(false)
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    window.onbeforeunload = function () {
        return "Dude, are you sure you want to leave? Think of the kittens!";
    }

    useEffect(() => {

        const GetApplications = async () => {
            const {brandModels} = product
            filtersStore?.FilterSearch({...filterSearch, inDetail: !!inLateralFilters})
            /*console.log(product)
            console.log(product?.brandModels)
            const uid = product?.uidProduct
            const docRef = doc(db, "products", uid);
            const docSnap = await getDoc(docRef);*/
            setApplication(brandModels)
        }

        GetApplications().catch(() => null)

    }, [filterData, product])

    useEffect(() => {


        let isMounted = true


        const UpdateData = async () => {
            if (product) {

                const cod = product?.cod
                let newStock

                const filterProd = Filters(shoppingCart, 'cod', cod)

                let amount = filterProd.reduce(function (prev, cur) {
                    return prev + cur.amount;
                }, 0);

                //const uid = product.docId === undefined ? product.uidProduct : product.docId

                if (filterProd.length > 0) {
                    newStock = product.stock - amount
                    if (newStock === 0) {
                        setValue(0)
                        const update = UpdateArray(mainProducts, 'cod', cod, 'mainCart', true)
                        dataStore.MainProducts(update)
                        /* const updateProduct = doc(db, "products", uid)
                         await updateDoc(updateProduct, {
                             mainCart: true
                         });*/
                    }
                    dataStore.Stock(newStock)
                }

                if (shoppingCart.length > 0) {
                    product.stores.forEach(data => {
                        filterProd.forEach(items => {
                            if (data.name === items.storage && data.codProd === items.cod) {
                                data.stock = data.stock - items.amount
                            }
                        })
                    })
                    dataStore.Product(product)
                }

            }
        }


        isMounted && UpdateData()

        return (() => {
            isMounted = false
        })

    }, [shoppingCart, product])

    useEffect(() => {

        let isMounted = true

        if (user && isMounted) {
            // GetCart().catch(() => null)
        } else {
            dataStore.ShoppingCart([])
        }

        return () => {
            isMounted = false
        }


    }, [user])

    useEffect(() => {

        if (!user?.discount) {
            try {
                onAuthStateChanged(auth, async (user) => {
                    if (user) {
                        const uid = user.uid;
                        const docRef = doc(db, "clients", uid);
                        const docSnap = await getDoc(docRef);
                        const city = docSnap.data()?.city ? docSnap.data()?.city : null
                        const type = docSnap.data()?.type
                        const purchaseQuota = docSnap.data()?.purchaseQuota
                        const paymentDeadline = docSnap.data()?.paymentDeadline
                        const idClient = docSnap.data()?.idClient
                        const deliveryCity = docSnap.data()?.deliveryCity
                        let displayName = type === 'Judirico' ? docSnap.data().tradeName : `${docSnap.data().name}  ${docSnap.data().lastName}`;
                        const discount = docSnap.data()?.discount
                        const typeClient = docSnap.data()?.typeClient
                        setClientDiscountReload(discount)
                        dataStore.User({
                            uid, displayName, typeClient, discount, city, paymentDeadline, purchaseQuota,
                            deliveryCity, type, idClient
                        })
                    } else {
                        dataStore.User(null)
                    }
                });

            } catch (e) {

            }
        } else {
            setClientDiscountReload(user?.discount)
            // GetCart().catch(() => null)
        }


    }, [product])

    useEffect(() => {
        return () => {
            if (navigate.action === 'POP') {
                navigate.go(1);
            }
        };

    }, [navigate])

    useEffect(() => {

        let isMounted = true

        if (product == null) {

            const GetProduct = async () => {
                if (typeof window !== "undefined") {
                    const saved = localStorage.getItem('cod');
                    const cod = saved !== null ? JSON.parse(saved) : null;

                    const savedSchema = localStorage.getItem('schema');
                    const schema = savedSchema !== null ? JSON.parse(savedSchema) : null;

                    const savedDis = localStorage.getItem('dis');
                    const dis = savedDis !== null ? JSON.parse(savedDis) : null;

                    const savedRealPrice = localStorage.getItem('realPrice');
                    const realPrice = savedRealPrice !== null ? JSON.parse(savedRealPrice) : null;

                    const savedPrice = localStorage.getItem('price');
                    const price = savedPrice !== null ? JSON.parse(savedPrice) : null;

                    const savedNewPrice = localStorage.getItem('newPrice');
                    const newPrice = savedNewPrice !== null ? JSON.parse(savedNewPrice) : null;

                    await dataStore.GetSchema(schema)
                    await dataStore.GetProductReload(cod, dis, realPrice, newPrice, price)

                }
            }

            isMounted && GetProduct()

        }

        return () => {
            isMounted = false
        }

    }, [])

    useEffect(() => {

        if (product) {
            const measuresArr = [
                {label: 'Medida A', measures: product.measuresA, unit: product.unitA},
                {label: 'Medida B', measures: product.measuresB, unit: product.unitB},
                {label: 'Medida C', measures: product.measuresC, unit: product.unitC},
                {label: 'Medida D', measures: product.measuresD, unit: product.unitD},
                {label: 'Medida E', measures: product.measuresE, unit: product.unitE}
            ]
            setMeasuresArray(measuresArr)

            dataStore.ControlStores(product.stores)
        }

    }, [product])

    const handleClose = () => {
        setOpen(false);
    };

    const AddCart = async () => {

        if (value > stock) {
            setAlert(true)
            return
        }

        setAlert(false)


        onAuthStateChanged(auth, (user) => {
            if (user) {

                // const stores = Order(product.stores, 'order')
                const stores = dataStore.controlStores

                let s = 0
                let cartNum = 0
                let q = value
                let q_2 = value
                let control = true

                const filterClientDiscount = Filters(clientDiscountReload, 'manufacturers', product?.manufacturers)
                const clientDis = filterClientDiscount.length > 0 ? filterClientDiscount[0].discounts : 0
                const disProd = discount - parseFloat(clientDis)

                const {cod, name, manufacturers, imagesArray, iva} = product

                const objCart = {
                    cod, name, manufacturers, price, newPrice, disProd, clientDis, iva,
                    image: imagesArray[0]
                }
                const cartArr = [...shoppingCart]

                if (q === '---') {

                    const filterStore = FilterDelete(stores, 'stock', 0)

                    const storeName = filterStore[0].name

                    filterStore.forEach(items => {

                        const stock = items.stock

                        if ((stock > 0) && (storeName === items.name)) {
                            s = stock - 1
                            const update = UpdateArray(stores, 'name', items.name, 'stock', s)
                            dataStore.ControlStores(update)
                            items.stock = s
                            cartNum = s + cartNum
                            setAmountData(amountData + 1)
                            const total = s * newPrice.toFixed(2)
                            cartArr.push({
                                ...objCart, total, storage: items.name, amount: amountData,
                                direction: items.direction, realPrice: items.price
                            })
                            if (s === 0) {
                                setAmountData(1)
                            }
                        }
                    })


                    let result = Object.values(cartArr.reduce(function (r, e) {
                        let key = e.cod + '|' + e.storage;
                        if (!r[key]) r[key] = e;
                        else {
                            r[key].amount = e.amount;
                        }
                        return r;
                    }, {}))

                    setOpen(false);
                    dataStore.ShoppingCart(result)

                } else {


                    let filterStore = FilterDelete(stores, 'stock', 0)
                    const storeName = filterStore[0].name
                    let value = 0


                    if (cartArr.length > 0) {

                        const stock = filterStore[0].stock


                        if (stock === q_2) {
                            q_2 = 0
                            const update = UpdateArray(stores, 'name', storeName, 'stock', 0)
                            dataStore.ControlStores(update)
                        }

                        if (stock > q) {
                            q_2 = stock - q_2
                            const update = UpdateArray(stores, 'name', storeName, 'stock', q_2)
                            dataStore.ControlStores(update)
                        }

                        if (stock < q_2) {
                            q_2 = stock - q_2
                            let val = q_2 < 0 ? 0 : q_2
                            const update = UpdateArray(stores, 'name', storeName, 'stock', val)
                            dataStore.ControlStores(update)
                        }


                    } else {
                        const stock = filterStore[0].stock
                        setCant(q_2 + cant)
                        if (stock === q_2) {
                            const update = UpdateArray(stores, 'name', storeName, 'stock', 0)
                            dataStore.ControlStores(update)
                        }

                        if (stock > q_2) {
                            q_2 = stock - q_2
                            const update = UpdateArray(stores, 'name', storeName, 'stock', q_2)
                            dataStore.ControlStores(update)
                        }

                        if (stock < q_2) {
                            q_2 = stock - q_2
                            let val = q_2 < 0 ? 0 : q_2
                            const update = UpdateArray(stores, 'name', storeName, 'stock', val)
                            dataStore.ControlStores(update)
                        }
                    }


                    filterStore.forEach(items => {

                        const stock = items.stock

                        //console.log('s', s, 'q', q, 'bodega', 'stock', stock, items.name)

                        if (control) {
                            if (q >= stock) {
                                if (stock > 0) {
                                    s = stock
                                    q = q - stock
                                    cartNum = s + cartNum
                                    const total = s * newPrice.toFixed(2)
                                    cartArr.push({
                                        ...objCart, total, storage: items.name, amount: s,
                                        direction: items.direction, realPrice: items.price, location: items.location
                                    })
                                }

                            } else {
                                s = stock - q
                                cartNum = s + cartNum
                                // console.log('s==>', s_2, 'stock', stock, 'bodega', items.name, cartNum)
                                if (s > 0) {
                                    control = false
                                }

                                const val = value === 1 ? value : q
                                const total = val * newPrice.toFixed(2)
                                cartArr.push({
                                    ...objCart,
                                    total,
                                    storage: items.name,
                                    amount: value === 1 ? value : q,
                                    direction: items.direction,
                                    realPrice: items.price,
                                    location: items.location
                                })
                            }
                        }
                    })


                    let result = Object.values(cartArr.reduce(function (r, e) {
                        let key = e.cod + '|' + e.storage + '|' + e.direction;
                        if (!r[key]) r[key] = e;
                        else {
                            r[key].amount += e.amount;
                        }
                        return r;
                    }, {}))

                    let filterStock = FilterDelete(result, 'amount', 0)
                    setOpen(true);
                    dataStore.ShoppingCart(filterStock)
                }


            } else {
                navigate('/session')
                dataStore.Path('/product-details')
            }
        });

    }

    return (
        <div className="details-container">
            {(product && schema) ?
                <div className={'images-container'}>
                    <div className={'products-container'}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                            <span>{product?.name} </span>
                            <span style={{marginLeft: 10}}>{product?.cod}</span>
                        </div>

                        {discount > 0 &&
                            <div className={'discount-container-detail'}>
                                <span style={{color: 'white', fontSize: 16}}>{`${discount}%`}</span>
                            </div>}
                        <div className={'hack-detail'}/>
                        <div className={'hack-detail_2'}/>
                        <Carousel
                            showArrows
                            dynamicHeight
                            showIndicators
                            showThumbs={false}
                        >
                            {product?.imagesArray.map((image, i) => {
                                return (
                                    <div key={i}>
                                        <img src={image} alt={''} style={{borderRadius: 3, height: 350}}/>
                                    </div>
                                )
                            })}
                        </Carousel>
                        <span style={{
                            color: 'gray', fontWeight: '700', marginTop: 20
                        }}>{`Fabricante: ${product?.manufacturers}`}</span>
                        <div style={styles.spanRow}>
                                   <span style={{
                                       color: '#333', fontWeight: '700'
                                   }}>{`Peso: ${product?.weight}`}</span>
                            <span style={{
                                color: '#333', fontWeight: '700'
                            }}>{`Unidad: ${product?.unitWeight}`}</span>
                            <span style={{
                                color: '#333', fontWeight: '700'
                            }}>{`Color: ${product?.color}`}</span>
                        </div>


                        <div style={styles.tableAlternateManufacturers}>
                            <div style={{width: '50%'}}>
                             <span style={{
                                 color: '#333', fontWeight: '700', marginTop: 20
                             }}>FABRICANTE ALTERNO</span>
                            </div>
                            <div style={{width: '50%'}}>
                                <span style={{color: '#333', fontWeight: '700', marginTop: 20}}>CÓDIGO</span>
                            </div>
                        </div>

                        {product?.alternateManufacturers.map((items, i) => {
                            return (
                                <div style={{
                                    display: 'flex', flexDirection: 'row', width: '100%',
                                    backgroundColor: i % 2 === 0 ? '#FFFF' : '#F6F9FB',
                                    padding: 3
                                }} key={i}>
                                    <div style={{width: '50%'}}>
                                       <span style={{
                                           color: '#333', fontWeight: '700', marginTop: 20
                                       }}>{items.alternateManufacturers}</span>
                                    </div>
                                    <div style={{width: '50%'}}>
                                       <span style={{
                                           color: '#333', fontWeight: '700', marginTop: 20
                                       }}>{items.alternateCod}</span>
                                    </div>

                                </div>
                            )
                        })}

                        <div style={{height: 15}}/>
                        <span style={{
                            color: 'gray', fontWeight: '700', marginTop: 20
                        }}>{`Aplicaciones`}</span>
                        <div className="application-container">
                            {applications?.map((product, i) => {
                                return (
                                    <div style={{display: 'flex', flexDirection: 'column',}} key={i}>
                                        <span style={{fontWeight: '700', color: '#333'}}>
                                        {`Vehículo: ${product?.vehicles}  Modelo: ${product?.models}`}
                                    </span>
                                        <span style={{fontWeight: '600', color: '#333'}}>
                                        {`Motor: ${product?.motor}  Cilindraje: ${product?.cylinder}
                                        Años: ${product?.initYear}-${product?.endYear}
                                        Observacion: ${product?.observation}`}
                                    </span>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                    <div className={'schema-container'}>
                        <img src={schema?.imageUrl} alt={''} style={{borderRadius: 3, height: 400}} width={'100%'}/>

                        <div style={{
                            display: 'flex', flexDirection: 'row', width: '100%',
                        }}>
                             <span style={{
                                 color: '#333', fontWeight: '700',
                             }}>GRUPO : </span>
                            <span style={{
                                color: '#333', fontWeight: '600', marginLeft: 10
                            }}>{group}</span>
                        </div>

                        <div style={{
                            display: 'flex', flexDirection: 'row', width: '100%', height: 5
                        }}>
                             <span style={{
                                 color: '#333', fontWeight: '700',
                             }}>SUB GRUPO : </span>
                            <span style={{
                                color: '#333', fontWeight: '600', marginLeft: 10
                            }}>{subGroup}</span>
                        </div>


                        <div style={{height: 5}}/>
                        <div style={styles.tableAlternateManufacturers}>
                            <div style={{width: '50%'}}>
                             <span style={{
                                 color: '#333', fontWeight: '700', marginTop: 20
                             }}>MEDIDA</span>
                            </div>
                            <div style={{width: '50%'}}>
                                <span style={{color: '#333', fontWeight: '700', marginTop: 20}}>UNIDAD</span>
                            </div>
                        </div>
                        {measuresArr.map((items, i) => {
                            return (
                                <div style={{
                                    display: 'flex', flexDirection: 'row', width: '100%',
                                    backgroundColor: i % 2 === 0 ? '#FFFF' : '#F6F9FB',
                                    padding: 3
                                }} key={i}>
                                    <div style={{width: '50%'}}>
                                       <span style={{
                                           color: '#333', fontWeight: '700', marginTop: 20
                                       }}>{`${items.label}  `}</span>
                                        <span style={{
                                            color: '#333', fontWeight: '700', marginTop: 20, marginLeft: 20
                                        }}>{`${items.measures}`}</span>
                                    </div>
                                    <div style={{width: '50%'}}>
                                       <span style={{
                                           color: '#333', fontWeight: '700', marginTop: 20
                                       }}>{items.unit}</span>
                                    </div>
                                </div>
                            )
                        })}

                    </div>

                    <div className={'add-car-container'}>
                        {discount > 0 ?
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                             <span style={{
                                                 fontWeight: 'bold', color: 'gray', fontSize: 18,
                                                 textDecoration: 'line-through'
                                             }}>
                                                   {`$ ${price.toFixed(2)}`}
                                             </span>
                                <span style={{color: '#B7182B', fontSize: 18, marginLeft: 10}}>
                            {`$ ${discount > 0 ? newPrice.toFixed(2) : price}`}</span>

                            </div> :

                            <span style={{fontWeight: 'bold', color: '#B7182B', fontSize: 16}}>
                                                   {`$ ${price.toFixed(2)}`}
                                             </span>
                        }

                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                            <span style={{color: '#646464', fontSize: 12, fontWeight: '600'}}>{`PRODUCTO`}</span>
                            <span style={{color: '#B7182B', fontSize: 12, fontWeight: '400', marginLeft: 10}}>
                                {product.name}
                            </span>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                            <span style={{color: '#646464', fontSize: 12, fontWeight: '600'}}>{`FABRICANTE`}</span>
                            <span style={{color: '#B7182B', fontSize: 12, fontWeight: '400', marginLeft: 10}}>
                                {product.manufacturers}
                            </span>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                            {stock > 0 ?
                                <span style={{color: '#8BC34A', fontSize: 12, fontWeight: '600'}}>
                                    {`Existen ${stock} ${product.unitWeight} disponibles`}</span> :
                                <span style={{color: 'gray', fontSize: 12, fontWeight: '400', marginLeft: 10}}>
                                SIN STOCK
                            </span>}
                        </div>

                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 15}}>
                            <TextField
                                label="Cantidad"
                                type="number"
                                value={value}
                                style={{width: 183}}
                                onChange={(e) => setValue(parseInt(e.target.value))}
                                inputProps={{min: 1, max: stock}}
                                id="outlined-size-small"
                                disabled={stock <= 0}
                                defaultValue="Small"
                                size="small"
                            />
                        </div>

                        <br/>

                        {alert &&
                            <Stack sx={{width: '100%'}} spacing={2}>
                                <Alert severity="error">{`La cantidad ingresada supera al stock de
                                ${stock} ${product.unitWeight}`}</Alert>
                            </Stack>
                        }

                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 15}}>
                            <Button letiant="contained" disableElevation style={{background: '#B7182B'}}
                                    disabled={stock <= 0} onClick={AddCart}>
                                <span style={{color: 'white'}}>Agregar al carrito</span>
                            </Button>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 15}}>
                            <Button letiant="text" startIcon={<ArrowBackIcon/>}
                                    onClick={async () => {
                                        dataStore.BackDetailHome(true)
                                        //dataStore.UpdateFilter({...filterData})

                                        navigate("/")
                                    }}>Volver</Button>
                        </div>
                    </div>
                </div> :
                <div style={{
                    width: '100%', height: 400, display: 'flex', alignItems: 'center',
                    justifyContent: 'center', flexDirection: 'column'
                }}>
                    <Backdrop
                        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                        open={!product || schema}
                        //onClick={handleClo}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                            }}>
                            <img alt={''} src={logo} style={{width: 250, height: 125}}/>
                            <CircularProgress color="inherit"/>
                        </div>
                    </Backdrop>
                </div>
            }
            <Snackbar
                anchorOrigin={{vertical, horizontal}} open={open} autoHideDuration={2000} key={vertical + horizontal}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="info" sx={{width: '100%'}}>
                    Agregado al carrito!
                </Alert>
            </Snackbar>
        </div>
    )
}


export default observer(MainDetail)

const styles = {
    spanRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',

    },

    tableAlternateManufacturers: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        background: '#F2F2F2',
        border: '1px solid #b0bec5',
        borderRadius: 2
    }
}
