import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import {gfcUrl, headers, logo, searchProductsUrl} from "../../../utils/Const";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import {useContext, useEffect, useRef, useState} from "react";
import {StoreContext} from "../../../store/Context";
import {collection, getDocs, limit, query, where} from "firebase/firestore";
import {db} from "../../../firebase/Firebase";
import {FilterDelete, Filters, generateUUID, Order} from "../../../utils/HelpFunctions";
import {observer} from "mobx-react-lite";
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {useNavigate} from "react-router-dom";
import {doc, setDoc} from "firebase/firestore";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Text from "../../../palette/Text";
import axios from "axios";
import UserOptions from "./UserOptions";
import './header-styles.css'
import {FaSearch} from 'react-icons/fa';
import {InputText} from "primereact/inputtext";


function AppBarHeader(props) {

    const {dataStore, filtersStore} = useContext(StoreContext)
    const {value, setValue} = props

    const {
        shoppingCart, showNoStock, user, filterData, valueSearch, globalFilterCombination, mainProducts,
        globalFilter, triggerProd_
    } = dataStore


    const {

        selectSchema,
        selectedVehicles,
        selectedModel,
        selectedYear,
        selectManufacturers,
        selectGroup,
        selectSubGroup,
    } = filtersStore


    const {allProducts, keyWords} = filtersStore

    const navigate = useNavigate();

    let amount = shoppingCart.reduce(function (prev, cur) {
        return prev + cur.amount;
    }, 0);

    const [results, setResults] = useState(null)
    const [compareResults, setCompareResults] = useState(null)
    const [hits, setHits] = useState(null)
    const [productResults, setProductResults] = useState(null)
    const [copyProductResults, setCopyProductResults] = useState(null)
    const [open, setOpen] = useState(false);
    const [close, setClose] = useState(false);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [controlSearch, setControlSearch] = useState(false);

    const anchorRef = useRef(null);

    useEffect(() => {
        if (keyWords?.length === 1) {
            dataStore.TriggerProd(!triggerProd_)
        }
    }, [keyWords]);

    useEffect(() => {

        const {vehicles, models, qYear, manufacturers, schema} = globalFilterCombination

        if (vehicles === '' && models === '' && qYear === '' && manufacturers === '' && schema === '' && results) {
            productResults?.length > 0 && dataStore.MainProducts(productResults)
            setControlSearch(false)
            setHits(null)
            setCopyProductResults(null)
        }

    }, [globalFilterCombination, value])

    useEffect(() => {

        const GetProducts = async () => {

            const {vehicles, models, qYear, manufacturers, schema} = globalFilterCombination
            if (results) {


                const data = {
                    vehicles, models, qYear, manufacturers, schema,
                    productResults: controlSearch ? copyProductResults : productResults,
                    globalFilter: results,
                }


                let res
                if (controlSearch) {
                    setOpenBackdrop(true)
                    res = await axios.post(gfcUrl, data, headers)


                    const arr = [data]
                    setOpenBackdrop(false)

                    let count = 0
                    arr.forEach(items => {
                        if (items.vehicles !== '') {
                            count = count + 1
                        }

                        if (items.models !== '') {
                            count = count + 1
                        }

                        if (items.qYear !== '') {
                            count = count + 1
                        }

                        if (items.manufacturers !== '') {
                            count = count + 1
                        }

                        if (items.schema !== '') {
                            count = count + 1
                        }
                    })
                    if (count === 1) {
                        setControlSearch(false)
                    }

                } else {
                    setOpenBackdrop(true)
                    res = await axios.post(gfcUrl, data, headers)
                    setOpenBackdrop(false)
                    const arr = [data]
                    let count = 0
                    arr.forEach(items => {
                        if (items.vehicles !== '') {
                            count = count + 1
                        }

                        if (items.models !== '') {
                            count = count + 1
                        }

                        if (items.qYear !== '') {
                            count = count + 1
                        }

                        if (items.manufacturers !== '') {
                            count = count + 1
                        }

                        if (items.schema !== '') {
                            count = count + 1
                        }

                    })
                    if (count > 1) {
                        //  setControlSearch(true)
                    }
                    setCopyProductResults(res.data.result)
                }

                const productResult = Filters(res.data.result, 'show', true)
                if (productResult === 'ok') {
                    return
                }


                if (data.schema !== '') {
                    setTimeout(() => dataStore.MainProducts(productResult, showNoStock), 2000)
                } else {
                    setTimeout(() => dataStore.MainProducts(productResult, showNoStock), 500)
                }


            } else {
                await handleFilter(results)
            }
        }

        GetProducts().catch(() => {
            setOpenBackdrop(false)
        })


    }, [globalFilterCombination])

    useEffect(() => {

        if ((!close || !showNoStock) && mainProducts) {
            const filterProd = FilterDelete(mainProducts, 'stock', 0)
            dataStore.MainProducts(filterProd)
        }

        if (showNoStock) {
            dataStore.MainProducts(compareResults)
        }
    }, [showNoStock, valueSearch, value, globalFilter, close])


    const handleToggle = () => setOpen((prevOpen) => !prevOpen);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };


    const handleFilter = async (results) => {

        const arr = []
        for (const items of results) {
            let q = query(collection(db, "products"), where("status", "==", 'Activo'),
                where("cod", "==", items.cod), limit(10));
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc) => {
                let stock = doc.data().stores.reduce(function (prev, cur) {
                    return prev + cur.stock;
                }, 0);
                arr.push({...doc.data(), docId: doc.id, stock, show: stock > 0, uidProduct: doc.id})
            })
        }

        //  const filter = arr.filter((v, i, a) => a.findIndex(t => (t.cod === v.cod)) === i)
        const order = Order(arr, 'name')

        setCompareResults(order)

        if (results) {
            setTimeout(async () => {
                const global = order.length > 0
                setOpenBackdrop(false)
                dataStore.GlobalFilterProducts(order)
                let filterStock;
                if (showNoStock) {
                    filterStock = order
                } else {
                    filterStock = FilterDelete(order, 'show', showNoStock)
                }
                valueSearch !== '' && setProductResults(filterStock)
                global && await dataStore.FilterData(null, showNoStock, global)
                valueSearch !== '' && dataStore.MainProducts(filterStock, showNoStock)
                dataStore.GlobalFilter(global)
            }, 100)
        }
    }

    const handleViewCart = async () => {
        navigate('/shopping-cart')

        if (user) {
            const uidCart = generateUUID()
            dataStore.UIDCart(uidCart)
            const uid = user.uid
            const purchaseQuota = user?.purchaseQuota
            const data = {data: shoppingCart, uidCart, orderNumber: '', delivery: true, payment: '', purchaseQuota}

            try {
                await setDoc(doc(db, "clients", uid, 'cart', 'cart001'), data);
            } catch (e) {
                // console.log(e)
            }
        } else {
            dataStore.ShoppingCart([])
        }
    }

    const Back = () => navigate('/')

    const handleSearchProducts = async () => {

        const data = {keyWords, data: null}

        if (keyWords === '') {
            return
        }

        setOpenBackdrop(true)


        const typeClient = user?.typeClient

        let key

        if (typeClient?.toLowerCase() === 'final') {
            key = 'final'
        }

        if (typeClient?.toLowerCase() === 'taller') {
            key = 'workshop'
        }

        if (typeClient?.toLowerCase() === 'almacen') {
            key = 'warehouse'
        }

        if (typeClient?.toLowerCase() === 'distribuidor') {
            key = 'distributor'
        }

        try {
            const res = await axios.post(searchProductsUrl, data)

            const products = res.data.result


            const product = allProducts.filter(item1 =>
                products.some(item2 => item2.cod === item1.cod)
            );


            const discountUser = user?.discount



            const lateralFilter = []

            selectedVehicles && lateralFilter.push(selectedVehicles?.vehicles)
            selectedModel && lateralFilter.push(selectedModel?.models)
            selectedYear && lateralFilter.push(selectedYear?.year)
            selectManufacturers && lateralFilter.push(selectManufacturers?.manufacturers)
            selectGroup && lateralFilter.push(selectGroup?.group)
            selectSubGroup && lateralFilter.push(selectSubGroup?.subGroup)
            selectSchema && lateralFilter.push(selectSchema?.name)


            product?.forEach((product) => discountUser?.forEach(items => product.manufacturers === items.manufacturers ? product['discount'] = items[key] : product['discount'] = '0'))

            if (lateralFilter.length > 0) {

                const coincidences = product.filter((obj) =>
                    obj.brandModels.some(
                        (items) => lateralFilter.includes(items.vehicles)
                            || lateralFilter.includes(items.models)
                            || lateralFilter.includes(items.qYear)
                            || lateralFilter.includes(items.manufacturers)
                            || lateralFilter.includes(items.schema)
                    )
                );

                const filterManufactures = selectManufacturers ? Filters(coincidences, 'manufacturers', selectManufacturers?.manufacturers) : coincidences
                setOpenBackdrop(false)
                dataStore.MainProducts(filterManufactures)
            } else {
                dataStore.MainProducts(product)
                setOpenBackdrop(false)

            }


        } catch
            (e) {
            console.log(e)
            setOpenBackdrop(false)
        }

    }

    const handleKeyEnter = (event) => event.key === 'Enter' && handleSearchProducts()

    return (
        <div className={'app-bar-container'}>

            <AppBar position="static" style={{backgroundColor: 'transparent'}} elevation={0}>
                <div className={'toolbar-container'}>

                    <div className={'responsive-image-container'}>
                        <div style={{cursor: 'pointer'}} onClick={Back}>
                            <img alt="" src={logo} width={'100%'} height={80}/>
                        </div>
                    </div>

                    <div className={'responsive-head-container'}>
                        <UserOptions/>
                        <div className={'cart-container'}>
                            <IconButton color="primary" aria-label="add to shopping cart"
                                        ref={anchorRef}
                                        id="composition-button"
                                        aria-controls={open ? 'composition-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={handleToggle}
                                        onMouseLeave={handleClose}
                                        onClick={handleToggle}

                            >
                                <Badge badgeContent={amount} color="error">
                                    <AddShoppingCartIcon/>
                                </Badge>
                            </IconButton>
                            <Popper
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                placement="bottom-start"
                                transition
                                disablePortal
                            >
                                {({TransitionProps, placement}) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                                        }}
                                    >
                                        <Paper>
                                            {shoppingCart.length > 0 &&
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList
                                                        autoFocusItem={open}
                                                        id="composition-menu"
                                                        aria-labelledby="composition-button"
                                                        // onKeyDown={handleListKeyDown}
                                                    >

                                                        {shoppingCart.map((items, i) => {
                                                            return (
                                                                <div key={i}>
                                                                    <MenuItem>
                                                                        {`${items.amount}  ${items.name}`}
                                                                    </MenuItem>
                                                                </div>
                                                            )
                                                        })}
                                                        <MenuItem
                                                            onClick={handleViewCart}>Ver carrito</MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>}
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    </div>

                    <div className={'logo-container'}>
                        <div style={{cursor: 'pointer'}} onClick={Back}>
                            <img alt="" src={logo} width={'80%'} height={70}/>
                        </div>
                    </div>


                    <div className={'search-container'}>
                        <div style={{width: '100%'}}>
                            <div className="search-container_in">
                                <InputText placeholder="Buscar..." onKeyDown={handleKeyEnter}
                                           value={keyWords}
                                           onChange={(e) => filtersStore.setKeyWords(e.target.value)}/>
                                <button
                                    onClick={handleSearchProducts}
                                    className="button icon-search"
                                >
                                    <FaSearch size={22} color={'white'}/>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className={'user-option-container'}>

                        <div>
                            <IconButton color="primary" aria-label="add to shopping cart"
                                        ref={anchorRef}
                                        id="composition-button"
                                        aria-controls={open ? 'composition-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={handleToggle}
                                        onMouseLeave={handleClose}
                                        onClick={handleToggle}

                            >
                                <Badge badgeContent={amount} color="error">
                                    <AddShoppingCartIcon style={{fontSize: 32, marginLeft: 10}}/>
                                </Badge>
                            </IconButton>
                            <Popper
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                placement="bottom-start"
                                transition
                                disablePortal
                            >
                                {({TransitionProps, placement}) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                                        }}
                                    >
                                        <Paper>
                                            {shoppingCart.length > 0 &&
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList
                                                        autoFocusItem={open}
                                                        id="composition-menu"
                                                        aria-labelledby="composition-button"
                                                        // onKeyDown={handleListKeyDown}
                                                    >

                                                        {shoppingCart.map((items, i) => {
                                                            return (
                                                                <div key={i}>
                                                                    <MenuItem>
                                                                        {`${items.amount}  ${items.name}`}
                                                                    </MenuItem>
                                                                </div>
                                                            )
                                                        })}
                                                        <MenuItem
                                                            onClick={handleViewCart}>Ver carrito</MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>}
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                        <div/>

                        <div>
                            <UserOptions/>
                        </div>

                    </div>
                </div>
            </AppBar>

            <Backdrop
                sx={{color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={openBackdrop} color={'black'}
                //onClick={handleClo}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'row',
                    }}>
                    <CircularProgress style={{'color': 'white'}}/>
                    <Text text={'Buscando coincidencias...'} color={'white'} left={10}/>
                </div>
            </Backdrop>
        </div>
    )
}

export default observer(AppBarHeader)

