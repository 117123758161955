import {logo} from "../../utils/Const";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {signOut} from "firebase/auth";
import {auth, db} from "../../firebase/Firebase";
import Button from "@mui/material/Button";
import React, {useContext, useEffect, useState} from "react";
import {StoreContext} from "../../store/Context";
import {useNavigate} from "react-router-dom";
import {doc, getDoc} from "firebase/firestore";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    PDFViewer,
    Image,
    PDFDownloadLink,
} from '@react-pdf/renderer';
import {observer} from "mobx-react-lite";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useMediaQuery from "@mui/material/useMediaQuery";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const MyDoc = ({
                   user, dataUser, matches, dataOrder, shoppingCart, subTotal, deliveryCost,
                   subTotal_2, iva, total,
               }) => {



    return (
        <PDFViewer style={{
            width: matches ? "80%" : "95%", height: '100vh', margin: "auto"
        }}>
            <Document>
                <Page size="A4" style={styles.page}>
                    {/*<Text style={{fontSize: 22, fontWeight: 'bold', color: '#333'}}>MAZPARTES</Text>*/}

                    <Image
                        style={styles.image}
                        src={`${logo}`}
                    />

                    <View style={styles.rowContainer}>
                        <Text style={{
                            fontSize: 14, fontWeight: 'bold', color: '#2976D2',
                        }}>DATOS DEL CLIENTE</Text>

                        <Text style={{
                            fontSize: 12, fontWeight: 'bold', color: '#333',
                        }}>{`Pedido # ${dataOrder?.orderNumber}`}</Text>
                    </View>

                    <View style={styles.rowContainer_2}>

                        <View style={styles.rowViews}>
                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: '#000'
                            }}>{`Cliente :`}
                            </Text>

                            <Text style={{
                                fontSize: 10, fontWeight: '500', color: '#333', marginLeft: 5
                            }}>{`${user?.displayName}`}
                            </Text>

                        </View>

                        <View style={styles.rowViews}>
                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: '#000',
                            }}>{`Identificación :`}</Text>

                            <Text style={{
                                fontSize: 10, fontWeight: '555', color: '#333', marginLeft: 5
                            }}>{`${dataUser?.idClient}`}</Text>
                        </View>

                    </View>

                    <View style={styles.rowContainer_2}>

                        <View style={styles.rowViews}>
                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: '#000',
                            }}>{`Email :`}</Text>

                            <Text style={{
                                fontSize: 10, fontWeight: '555', color: '#333', marginLeft: 5
                            }}>{`${dataUser?.email}`}</Text>
                        </View>

                        <View style={styles.rowViews}>

                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: '#000', marginTop: 5
                            }}>{`Celular :`}</Text>

                            <Text style={{
                                fontSize: 10, fontWeight: '555', color: '#333', marginTop: 5, marginLeft: 5
                            }}>{`${dataUser?.phoneNumber}`}</Text>
                        </View>

                        <View style={styles.rowViews}>

                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: '#000', marginTop: 5
                            }}>{`Num.  convencional :`}</Text>

                            <Text style={{
                                fontSize: 10, fontWeight: '555', color: '#333', marginTop: 5, marginLeft: 5
                            }}>{`${dataUser?.businessNumber}`}</Text>
                        </View>

                    </View>

                    <View style={{
                        width: '100%', padding: 5, display: ' flex', flexDirection: 'row',
                        alignItems: 'center', justifyContent: 'space-between'
                    }}>

                        <View style={styles.rowViews}>
                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: '#000',
                            }}>{`Provincia :`}
                            </Text>

                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                            }}>{`${dataUser?.province}`}
                            </Text>
                        </View>


                        <View style={styles.rowViews}>

                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: '#000', marginLeft: 15
                            }}>{`Ciudad :`}</Text>

                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                            }}>{`${dataUser?.city}`}</Text>

                        </View>

                        <View style={styles.rowViews}>

                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: '#000', marginLeft: 15
                            }}>{`Calle principal :`}</Text>

                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                            }}>{`${dataUser?.mainStreet}`}</Text>
                        </View>
                    </View>

                    <View style={{
                        width: '100%', display: ' flex', flexDirection: 'row',
                        alignItems: 'center', justifyContent: 'space-between', padding: 5
                    }}>

                        <View style={styles.rowViews}>
                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: '#000'
                            }}>{`Num. Casa/Dpto :`}</Text>

                            <Text style={{
                                fontSize: 10, fontWeight: '500', color: '#333', marginLeft: 5
                            }}>{`${dataUser?.houseNumber}`}</Text>

                        </View>

                        <View style={styles.rowViews}>
                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: '#000', marginLeft: 15
                            }}>{`Calle secundaria :`}
                            </Text>

                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                            }}>{`${dataUser?.secondaryStreet}`}
                            </Text>
                        </View>

                    </View>

                    {dataOrder?.delivery &&
                        <View style={{width: '100%', marginTop: 7, display: ' flex', flexDirection: 'row'}}>
                            <View style={styles.rowViews}>
                                <Text style={{
                                    fontSize: 10, fontWeight: 'bold', color: '#000', marginLeft: 5,
                                }}>{`Referencia :`}</Text>

                                <Text style={{
                                    fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                                }}>{`${dataUser?.reference}`}</Text>
                            </View>
                        </View>}


                    <View style={styles.rowContainer}>
                        <Text style={{
                            fontSize: 14, fontWeight: 'bold', color: '#2976D2',
                        }}>DATOS DEL PEDIDO</Text>
                        {dataOrder?.delivery &&
                            <Text style={{
                                fontSize: 12, fontWeight: 'bold', color: 'gray',
                            }}>{`Dirección de entrega`}</Text>}

                    </View>

                    {dataOrder?.delivery &&
                        <View style={{
                            width: '100%', padding: 5, display: ' flex', flexDirection: 'row',
                            alignItems: 'center', justifyContent: 'space-between', marginTop: 5
                        }}>

                            <View style={styles.rowViews}>
                                <Text style={{
                                    fontSize: 10, fontWeight: 'bold', color: '#000',
                                }}>{`Provincia :`}
                                </Text>

                                <Text style={{
                                    fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                                }}>{`${dataUser?.deliveryProvince}`}
                                </Text>
                            </View>


                            <View style={styles.rowViews}>

                                <Text style={{
                                    fontSize: 10, fontWeight: 'bold', color: '#000', marginLeft: 15
                                }}>{`Ciudad :`}</Text>

                                <Text style={{
                                    fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                                }}>{`${dataUser?.deliveryCity}`}</Text>

                            </View>

                            <View style={styles.rowViews}>

                                <Text style={{
                                    fontSize: 10, fontWeight: 'bold', color: '#000', marginLeft: 15
                                }}>{`Calle principal :`}</Text>

                                <Text style={{
                                    fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                                }}>{`${dataUser?.deliveryMainStreet}`}</Text>
                            </View>
                        </View>}

                    {dataOrder?.delivery &&
                        <View style={{
                            width: '100%', display: ' flex', flexDirection: 'row',
                            alignItems: 'center', justifyContent: 'space-between', padding: 5
                        }}>

                            <View style={styles.rowViews}>
                                <Text style={{
                                    fontSize: 10, fontWeight: 'bold', color: '#000'
                                }}>{`Num. Casa/Dpto :`}</Text>

                                <Text style={{
                                    fontSize: 10, fontWeight: '500', color: '#333', marginLeft: 5
                                }}>{`${dataUser?.deliveryHouseNumber}`}</Text>

                            </View>

                            <View style={styles.rowViews}>
                                <Text style={{
                                    fontSize: 10, fontWeight: 'bold', color: '#000', marginLeft: 15
                                }}>{`Calle secundaria :`}
                                </Text>

                                <Text style={{
                                    fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                                }}>{`${dataUser?.deliverySecondaryStreet}`}
                                </Text>
                            </View>


                        </View>}

                    <View style={{
                        width: '100%', display: ' flex', flexDirection: 'row',
                        alignItems: 'center', justifyContent: 'space-between', marginTop: 5
                    }}>
                        {dataOrder?.delivery &&
                            <View style={styles.rowViews}>
                                <Text style={{
                                    fontSize: 10, fontWeight: 'bold', color: '#000', marginLeft: 5
                                }}>{`Referencia :`}</Text>

                                <Text style={{
                                    fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                                }}>{`${dataUser?.deliveryReference}`}</Text>

                            </View>}
                    </View>
                    <View style={{height: 5}}/>

                    <View style={styles.rowContainer_2}>

                        <View style={styles.rowViews}>
                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: '#000',
                            }}>{`Fecha de pedido :`}
                            </Text>

                            <Text style={{
                                fontSize: 10, fontWeight: '500', color: '#333', marginLeft: 5
                            }}>{`${dataOrder?.orderDate}`}
                            </Text>
                        </View>

                        <View style={styles.rowViews}>

                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: '#000',
                            }}>{`Estado :`}</Text>

                            <Text style={{
                                fontSize: 10, fontWeight: '500', color: '#333', marginLeft: 5
                            }}>{dataOrder?.status}</Text>
                        </View>

                        <View style={styles.rowViews}>
                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: '#000',
                            }}>{`Forma de pago :`}</Text>

                            <Text style={{
                                fontSize: 10, fontWeight: '500', color: '#333', marginLeft: 5
                            }}>{`${dataOrder?.payment}`}</Text>

                        </View>
                    </View>


                    {dataOrder.payment === 'Credito' &&
                        <View style={styles.rowContainer_2}>

                            <View style={styles.rowViews}>
                                <Text style={{
                                    fontSize: 10, fontWeight: 'bold', color: '#000',
                                }}>{`Días de plazo :`}
                                </Text>

                                <Text style={{
                                    fontSize: 10, fontWeight: '500', color: '#333',
                                }}>{`${dataUser?.paymentDeadline}`}
                                </Text>
                            </View>

                            <View style={styles.rowViews}>

                                <Text style={{
                                    fontSize: 10, fontWeight: 'bold', color: '#000',
                                }}>{`Número de cuotas :`}</Text>

                                <Text style={{
                                    fontSize: 10, fontWeight: '500', color: '#333',
                                }}>{`${dataUser?.numberInstallments}`}</Text>

                            </View>
                        </View>
                    }

                    {dataOrder?.delivery &&
                        <View style={{marginLeft: 5}}>
                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: 'red',
                            }}>{`ENTREGA A DOMICILIO`}</Text>
                        </View>}

                    {/*GRID*/}

                    <View style={styles.rowContainer}>
                        <Text style={{
                            fontSize: 14, fontWeight: 'bold', color: '#2976D2',
                        }}>DETALLE</Text>
                    </View>

                    <View style={{
                        width: '100%', background: '#FAFAFA', padding: 5, marginTop: 10,
                        display: 'flex', flexDirection: 'row', alignItems: 'center'
                    }}>
                        <View style={styles.titleRows}>
                            <Text style={{
                                fontSize: 8, fontWeight: 'bold', color: '#000',
                            }}>{`PRODUCTOS`}</Text>
                        </View>

                        <View style={styles.titleRows_2}>
                            <Text style={{
                                fontSize: 8, fontWeight: 'bold', color: '#000',
                            }}>{`PRECIO SIN IMP.`}</Text>
                        </View>

                        <View style={styles.titleRows_2}>
                            <Text style={{
                                fontSize: 8, fontWeight: 'bold', color: '#000',
                            }}>{`CANTIDAD`}</Text>
                        </View>

                        <View style={styles.titleRows_2}>
                            <Text style={{
                                fontSize: 8, fontWeight: 'bold', color: '#000',
                            }}>{`%DCTO. PROD `}</Text>
                        </View>

                        <View style={styles.titleRows_2}>
                            <Text style={{
                                fontSize: 8, fontWeight: 'bold', color: '#000',
                            }}>{`%DCTO. CTE`}</Text>
                        </View>

                        <View style={styles.titleRows_2}>
                            <Text style={{
                                fontSize: 8, fontWeight: 'bold', color: '#000',
                            }}>{`PRECIO NETO`}</Text>
                        </View>

                        <View style={styles.titleRows_2}>
                            <Text style={{
                                fontSize: 8, fontWeight: 'bold', color: '#000',
                            }}>{`TOTAL`}</Text>
                        </View>

                        <View style={styles.titleRows}>
                            <Text style={{
                                fontSize: 8, fontWeight: 'bold', color: '#000',
                            }}>{`BODEGA`}</Text>
                        </View>
                    </View>

                    {shoppingCart.map((items, i) => {
                        const clientDis = parseFloat(items.clientDis)
                        const totalDiscount = items.disProd + clientDis
                        const resultDiscount = items.realPrice * (totalDiscount / 100)
                        const netPrice = items.realPrice - resultDiscount
                        const total = items.amount * netPrice

                        return (
                            <View style={{
                                width: '100%', backgroundColor: i % 2 === 0 ? '#F6F9FB' : '#FFF',
                                padding: 5, marginTop: 10, display: 'flex', flexDirection: 'row',
                                alignItems: 'center'
                            }} key={i}>
                                <View style={styles.titleRows}>
                                    <Text style={{
                                        fontSize: 8, fontWeight: 'bold', color: '#333',
                                    }}>{`${items.cod} ${items.name} ${items.manufacturers}`}</Text>
                                </View>

                                <View style={styles.titleRows_2}>
                                    <Text style={{
                                        fontSize: 8, fontWeight: 'bold', color: '#333',
                                    }}>{`$ ${items.realPrice.toFixed(2)}`}</Text>
                                </View>


                                <View style={styles.titleRows_2}>
                                    <Text style={{
                                        fontSize: 8, fontWeight: 'bold', color: '#333',
                                    }}>{items.amount}</Text>
                                </View>

                                <View style={styles.titleRows_2}>
                                    <Text style={{
                                        fontSize: 8, fontWeight: 'bold', color: '#333',
                                    }}>{`% ${items.disProd.toFixed(2)}`}</Text>
                                </View>

                                <View style={styles.titleRows_2}>
                                    <Text style={{
                                        fontSize: 8, fontWeight: 'bold', color: '#333',
                                    }}>{`% ${parseFloat(items.clientDis).toFixed(2)}`}</Text>
                                </View>

                                <View style={styles.titleRows_2}>
                                    <Text style={{
                                        fontSize: 8, fontWeight: 'bold', color: '#333',
                                    }}>{`$ ${netPrice.toFixed(2)}`}</Text>
                                </View>

                                <View style={styles.titleRows_2}>
                                    <Text style={{
                                        fontSize: 8, fontWeight: 'bold', color: '#333',
                                    }}>{`$ ${total.toFixed(2)}`}</Text>
                                </View>

                                <View style={styles.titleRows}>
                                    <Text style={{
                                        fontSize: 8, fontWeight: 'bold', color: '#333',
                                    }}>{`${items.storage}`}</Text>
                                    <Text style={{
                                        fontSize: 5, fontWeight: 'bold', color: '#333',
                                    }}>{`${items.direction}`}</Text>
                                </View>
                            </View>
                        )
                    })}

                    {/*TOTALS*/}

                    <View style={{
                        width: '74%', padding: 5, marginTop: 10, display: 'flex', flexDirection: 'row',
                        alignItems: 'center', justifyContent: 'space-between'
                    }}>
                        <View style={{
                            display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start',
                            width: '60%', padding: 5, marginTop: 10
                        }}>
                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: 'black', marginTop: 5
                            }}>  {`Número de comprobante/observación :`}</Text>

                            <Text style={{
                                fontSize: 10, fontWeight: 'bold', color: 'red', marginTop: 5
                            }}>  {`${dataOrder.observation}`}</Text>


                        </View>
                        <View style={{
                            width: '40%', padding: 5, marginTop: 10, display: 'flex',
                            alignItems: 'flex-end'
                        }}>
                            <Text style={{
                                fontSize: 9, fontWeight: 'bold', color: '#333', marginTop: 5
                            }}>{`SUBTOTAL         $ ${subTotal.toFixed(2)}`}</Text>


                            <Text style={{
                                fontSize: 9, fontWeight: 'bold', color: '#333', marginTop: 5
                            }}>{`COSTO DE ENVIO           $${deliveryCost.toFixed(2)}`}</Text>

                            <Text style={{
                                fontSize: 9, fontWeight: 'bold', color: '#333', marginTop: 5
                            }}>{`SUBTOTAL 2        $ ${subTotal_2.toFixed(2)}`}</Text>

                            <Text style={{
                                fontSize: 9, fontWeight: 'bold', color: '#333', marginTop: 5,
                            }}>{`IVA  15%        $ ${iva.toFixed(2)}`}</Text>

                            <Text style={{
                                fontSize: 12, fontWeight: 'bold', color: 'red', marginTop: 5
                            }}>{`TOTAL   $ ${total.toFixed(2)}`}</Text>
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    )

}

const MyDoc2 = ({
                    user, dataUser, matches, dataOrder, shoppingCart, subTotal, deliveryCost,
                    subTotal_2, iva, total,
                }) => (

    <Document>
        <Page size="A4" style={styles.page}>
            {/*<Text style={{fontSize: 22, fontWeight: 'bold', color: '#333'}}>MAZPARTES</Text>*/}

            <Image
                style={styles.image}
                src={`${logo}`}
            />

            <View style={styles.rowContainer}>
                <Text style={{
                    fontSize: 14, fontWeight: 'bold', color: '#2976D2',
                }}>DATOS DEL CLIENTE</Text>

                <Text style={{
                    fontSize: 12, fontWeight: 'bold', color: '#333',
                }}>{`Pedido # ${dataOrder?.orderNumber}`}</Text>
            </View>

            <View style={styles.rowContainer_2}>

                <View style={styles.rowViews}>
                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: '#000'
                    }}>{`Cliente :`}
                    </Text>

                    <Text style={{
                        fontSize: 10, fontWeight: '500', color: '#333', marginLeft: 5
                    }}>{`${user?.displayName}`}
                    </Text>

                </View>

                <View style={styles.rowViews}>
                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: '#000',
                    }}>{`Identificación :`}</Text>

                    <Text style={{
                        fontSize: 10, fontWeight: '555', color: '#333', marginLeft: 5
                    }}>{`${dataUser?.idClient}`}</Text>
                </View>

            </View>

            <View style={styles.rowContainer_2}>

                <View style={styles.rowViews}>
                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: '#000',
                    }}>{`Email :`}</Text>

                    <Text style={{
                        fontSize: 10, fontWeight: '555', color: '#333', marginLeft: 5
                    }}>{`${dataUser?.email}`}</Text>
                </View>

                <View style={styles.rowViews}>

                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: '#000', marginTop: 5
                    }}>{`Celular :`}</Text>

                    <Text style={{
                        fontSize: 10, fontWeight: '555', color: '#333', marginTop: 5, marginLeft: 5
                    }}>{`${dataUser?.phoneNumber}`}</Text>
                </View>

                <View style={styles.rowViews}>

                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: '#000', marginTop: 5
                    }}>{`Num.  convencional :`}</Text>

                    <Text style={{
                        fontSize: 10, fontWeight: '555', color: '#333', marginTop: 5, marginLeft: 5
                    }}>{`${dataUser?.businessNumber}`}</Text>
                </View>

            </View>

            <View style={{
                width: '100%', padding: 5, display: ' flex', flexDirection: 'row',
                alignItems: 'center', justifyContent: 'space-between'
            }}>

                <View style={styles.rowViews}>
                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: '#000',
                    }}>{`Provincia :`}
                    </Text>

                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                    }}>{`${dataUser?.province}`}
                    </Text>
                </View>


                <View style={styles.rowViews}>

                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: '#000', marginLeft: 15
                    }}>{`Ciudad :`}</Text>

                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                    }}>{`${dataUser?.city}`}</Text>

                </View>

                <View style={styles.rowViews}>

                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: '#000', marginLeft: 15
                    }}>{`Calle principal :`}</Text>

                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                    }}>{`${dataUser?.mainStreet}`}</Text>
                </View>
            </View>

            <View style={{
                width: '100%', display: ' flex', flexDirection: 'row',
                alignItems: 'center', justifyContent: 'space-between', padding: 5
            }}>

                <View style={styles.rowViews}>
                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: '#000'
                    }}>{`Num. Casa/Dpto :`}</Text>

                    <Text style={{
                        fontSize: 10, fontWeight: '500', color: '#333', marginLeft: 5
                    }}>{`${dataUser?.houseNumber}`}</Text>

                </View>

                <View style={styles.rowViews}>
                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: '#000', marginLeft: 15
                    }}>{`Calle secundaria :`}
                    </Text>

                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                    }}>{`${dataUser?.secondaryStreet}`}
                    </Text>
                </View>

            </View>

            {dataOrder?.delivery &&
                <View style={{width: '100%', marginTop: 7, display: ' flex', flexDirection: 'row'}}>
                    <View style={styles.rowViews}>
                        <Text style={{
                            fontSize: 10, fontWeight: 'bold', color: '#000', marginLeft: 5,
                        }}>{`Referencia :`}</Text>

                        <Text style={{
                            fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                        }}>{`${dataUser?.reference}`}</Text>
                    </View>
                </View>}


            <View style={styles.rowContainer}>
                <Text style={{
                    fontSize: 14, fontWeight: 'bold', color: '#2976D2',
                }}>DATOS DEL PEDIDO</Text>
                {dataOrder?.delivery &&
                    <Text style={{
                        fontSize: 12, fontWeight: 'bold', color: 'gray',
                    }}>{`Dirección de entrega`}</Text>}

            </View>

            {dataOrder?.delivery &&
                <View style={{
                    width: '100%', padding: 5, display: ' flex', flexDirection: 'row',
                    alignItems: 'center', justifyContent: 'space-between', marginTop: 5
                }}>

                    <View style={styles.rowViews}>
                        <Text style={{
                            fontSize: 10, fontWeight: 'bold', color: '#000',
                        }}>{`Provincia :`}
                        </Text>

                        <Text style={{
                            fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                        }}>{`${dataUser?.deliveryProvince}`}
                        </Text>
                    </View>


                    <View style={styles.rowViews}>

                        <Text style={{
                            fontSize: 10, fontWeight: 'bold', color: '#000', marginLeft: 15
                        }}>{`Ciudad :`}</Text>

                        <Text style={{
                            fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                        }}>{`${dataUser?.deliveryCity}`}</Text>

                    </View>

                    <View style={styles.rowViews}>

                        <Text style={{
                            fontSize: 10, fontWeight: 'bold', color: '#000', marginLeft: 15
                        }}>{`Calle principal :`}</Text>

                        <Text style={{
                            fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                        }}>{`${dataUser?.deliveryMainStreet}`}</Text>
                    </View>
                </View>}

            {dataOrder?.delivery &&
                <View style={{
                    width: '100%', display: ' flex', flexDirection: 'row',
                    alignItems: 'center', justifyContent: 'space-between', padding: 5
                }}>

                    <View style={styles.rowViews}>
                        <Text style={{
                            fontSize: 10, fontWeight: 'bold', color: '#000'
                        }}>{`Num. Casa/Dpto :`}</Text>

                        <Text style={{
                            fontSize: 10, fontWeight: '500', color: '#333', marginLeft: 5
                        }}>{`${dataUser?.deliveryHouseNumber}`}</Text>

                    </View>

                    <View style={styles.rowViews}>
                        <Text style={{
                            fontSize: 10, fontWeight: 'bold', color: '#000', marginLeft: 15
                        }}>{`Calle secundaria :`}
                        </Text>

                        <Text style={{
                            fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                        }}>{`${dataUser?.deliverySecondaryStreet}`}
                        </Text>
                    </View>


                </View>}

            <View style={{
                width: '100%', display: ' flex', flexDirection: 'row',
                alignItems: 'center', justifyContent: 'space-between', marginTop: 5
            }}>
                {dataOrder?.delivery &&
                    <View style={styles.rowViews}>
                        <Text style={{
                            fontSize: 10, fontWeight: 'bold', color: '#000', marginLeft: 5
                        }}>{`Referencia :`}</Text>

                        <Text style={{
                            fontSize: 10, fontWeight: 'bold', color: '#333', marginLeft: 5
                        }}>{`${dataUser?.deliveryReference}`}</Text>

                    </View>}
            </View>
            <View style={{height: 5}}/>

            <View style={styles.rowContainer_2}>

                <View style={styles.rowViews}>
                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: '#000',
                    }}>{`Fecha de pedido :`}
                    </Text>

                    <Text style={{
                        fontSize: 10, fontWeight: '500', color: '#333', marginLeft: 5
                    }}>{`${dataOrder?.orderDate}`}
                    </Text>
                </View>

                <View style={styles.rowViews}>

                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: '#000',
                    }}>{`Estado :`}</Text>

                    <Text style={{
                        fontSize: 10, fontWeight: '500', color: '#333', marginLeft: 5
                    }}>{dataOrder?.status}</Text>
                </View>

                <View style={styles.rowViews}>
                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: '#000',
                    }}>{`Forma de pago :`}</Text>

                    <Text style={{
                        fontSize: 10, fontWeight: '500', color: '#333', marginLeft: 5
                    }}>{`${dataOrder?.payment}`}</Text>

                </View>
            </View>


            {dataOrder.payment === 'Credito' &&
                <View style={styles.rowContainer_2}>

                    <View style={styles.rowViews}>
                        <Text style={{
                            fontSize: 10, fontWeight: 'bold', color: '#000',
                        }}>{`Días de plazo :`}
                        </Text>

                        <Text style={{
                            fontSize: 10, fontWeight: '500', color: '#333',
                        }}>{`${dataUser?.paymentDeadline}`}
                        </Text>
                    </View>

                    <View style={styles.rowViews}>

                        <Text style={{
                            fontSize: 10, fontWeight: 'bold', color: '#000',
                        }}>{`Número de cuotas :`}</Text>

                        <Text style={{
                            fontSize: 10, fontWeight: '500', color: '#333',
                        }}>{`${dataUser?.numberInstallments}`}</Text>

                    </View>
                </View>
            }

            {dataOrder?.delivery &&
                <View style={{marginLeft: 5}}>
                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: 'red',
                    }}>{`ENTREGA A DOMICILIO`}</Text>
                </View>}

            {/*GRID*/}

            <View style={styles.rowContainer}>
                <Text style={{
                    fontSize: 14, fontWeight: 'bold', color: '#2976D2',
                }}>DETALLE</Text>
            </View>

            <View style={{
                width: '100%', background: '#FAFAFA', padding: 5, marginTop: 10,
                display: 'flex', flexDirection: 'row', alignItems: 'center'
            }}>
                <View style={styles.titleRows}>
                    <Text style={{
                        fontSize: 8, fontWeight: 'bold', color: '#000',
                    }}>{`PRODUCTOS`}</Text>
                </View>

                <View style={styles.titleRows_2}>
                    <Text style={{
                        fontSize: 8, fontWeight: 'bold', color: '#000',
                    }}>{`PRECIO SIN IMP.`}</Text>
                </View>

                <View style={styles.titleRows_2}>
                    <Text style={{
                        fontSize: 8, fontWeight: 'bold', color: '#000',
                    }}>{`CANTIDAD`}</Text>
                </View>

                <View style={styles.titleRows_2}>
                    <Text style={{
                        fontSize: 8, fontWeight: 'bold', color: '#000',
                    }}>{`%DCTO. PROD `}</Text>
                </View>

                <View style={styles.titleRows_2}>
                    <Text style={{
                        fontSize: 8, fontWeight: 'bold', color: '#000',
                    }}>{`%DCTO. CTE`}</Text>
                </View>

                <View style={styles.titleRows_2}>
                    <Text style={{
                        fontSize: 8, fontWeight: 'bold', color: '#000',
                    }}>{`PRECIO NETO`}</Text>
                </View>

                <View style={styles.titleRows_2}>
                    <Text style={{
                        fontSize: 8, fontWeight: 'bold', color: '#000',
                    }}>{`TOTAL`}</Text>
                </View>

                <View style={styles.titleRows}>
                    <Text style={{
                        fontSize: 8, fontWeight: 'bold', color: '#000',
                    }}>{`BODEGA`}</Text>
                </View>
            </View>

            {shoppingCart.map((items, i) => {
                const clientDis = parseFloat(items.clientDis)
                const totalDiscount = items.disProd + clientDis
                const resultDiscount = items.realPrice * (totalDiscount / 100)
                const netPrice = items.realPrice - resultDiscount
                const total = items.amount * netPrice

                return (
                    <View style={{
                        width: '100%', backgroundColor: i % 2 === 0 ? '#F6F9FB' : '#FFF',
                        padding: 5, marginTop: 10, display: 'flex', flexDirection: 'row',
                        alignItems: 'center'
                    }} key={i}>
                        <View style={styles.titleRows}>
                            <Text style={{
                                fontSize: 8, fontWeight: 'bold', color: '#333',
                            }}>{`${items.cod} ${items.name} ${items.manufacturers}`}</Text>
                        </View>

                        <View style={styles.titleRows_2}>
                            <Text style={{
                                fontSize: 8, fontWeight: 'bold', color: '#333',
                            }}>{`$ ${items.realPrice.toFixed(2)}`}</Text>
                        </View>


                        <View style={styles.titleRows_2}>
                            <Text style={{
                                fontSize: 8, fontWeight: 'bold', color: '#333',
                            }}>{items.amount}</Text>
                        </View>

                        <View style={styles.titleRows_2}>
                            <Text style={{
                                fontSize: 8, fontWeight: 'bold', color: '#333',
                            }}>{`% ${items.disProd.toFixed(2)}`}</Text>
                        </View>

                        <View style={styles.titleRows_2}>
                            <Text style={{
                                fontSize: 8, fontWeight: 'bold', color: '#333',
                            }}>{`% ${parseFloat(items.clientDis).toFixed(2)}`}</Text>
                        </View>

                        <View style={styles.titleRows_2}>
                            <Text style={{
                                fontSize: 8, fontWeight: 'bold', color: '#333',
                            }}>{`$ ${netPrice.toFixed(2)}`}</Text>
                        </View>

                        <View style={styles.titleRows_2}>
                            <Text style={{
                                fontSize: 8, fontWeight: 'bold', color: '#333',
                            }}>{`$ ${total.toFixed(2)}`}</Text>
                        </View>

                        <View style={styles.titleRows}>
                            <Text style={{
                                fontSize: 8, fontWeight: 'bold', color: '#333',
                            }}>{`${items.storage}`}</Text>
                            <Text style={{
                                fontSize: 5, fontWeight: 'bold', color: '#333',
                            }}>{`${items.direction}`}</Text>
                        </View>
                    </View>
                )
            })}

            {/*TOTALS*/}

            <View style={{
                width: '74%', padding: 5, marginTop: 10, display: 'flex', flexDirection: 'row',
                alignItems: 'center', justifyContent: 'space-between'
            }}>
                <View style={{
                    display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start',
                    width: '60%', padding: 5, marginTop: 10
                }}>
                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: 'black', marginTop: 5
                    }}>  {`Número de comprobante/observación :`}</Text>

                    <Text style={{
                        fontSize: 10, fontWeight: 'bold', color: 'red', marginTop: 5
                    }}>  {`${dataOrder.observation}`}</Text>


                </View>
                <View style={{
                    width: '40%', padding: 5, marginTop: 10, display: 'flex',
                    alignItems: 'flex-end'
                }}>
                    <Text style={{
                        fontSize: 9, fontWeight: 'bold', color: '#333', marginTop: 5
                    }}>{`SUBTOTAL         $ ${subTotal.toFixed(2)}`}</Text>


                    <Text style={{
                        fontSize: 9, fontWeight: 'bold', color: '#333', marginTop: 5
                    }}>{`COSTO DE ENVIO           $${deliveryCost.toFixed(2)}`}</Text>

                    <Text style={{
                        fontSize: 9, fontWeight: 'bold', color: '#333', marginTop: 5
                    }}>{`SUBTOTAL 2        $ ${subTotal_2.toFixed(2)}`}</Text>

                    <Text style={{
                        fontSize: 9, fontWeight: 'bold', color: '#333', marginTop: 5,
                    }}>{`IVA          $ ${iva.toFixed(2)}`}</Text>

                    <Text style={{
                        fontSize: 12, fontWeight: 'bold', color: 'red', marginTop: 5
                    }}>{`TOTAL   $ ${total.toFixed(2)}`}</Text>
                </View>
            </View>


        </Page>
    </Document>

);


function OrdersPDF() {

    const {dataStore} = useContext(StoreContext)

    const {user, ivaValue} = dataStore

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [snacks, setSnacks] = useState({message: '', type: 'success'})
    const [dataUser, setDataUser] = useState(null)
    const [dataOrder, setDataOrder] = useState(null)
    const [isReady, setIsReady] = useState(false)
    const [shoppingCart, setShoppingCart] = useState(null)
    const [deliveryCost, setDeliveryCost] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [subTotal_2, setSubTotal_2] = useState(0);
    const [iva, setIva] = useState(0);
    const [total, setTotal] = useState(0);
    const [uidOrder, setUidOrder] = useState('')

    const navigate = useNavigate();
    const matches = useMediaQuery('(min-width:768px)');
    window.location.hash = "no-back-button";

    //const anchorRef = useRef(null);
    const openMenu = Boolean(anchorEl);

    useEffect(() => {

        const GetUidOder = async () => {
            const docRef = doc(db, 'clients', user.uid, 'uidOrder', 'uidOrder001');
            const docSnap = await getDoc(docRef);
            setUidOrder(docSnap.data().uidOrder)
        }

        user && GetUidOder()

    }, [user])

    useEffect(() => {

        let isMounted = true;

        if (user && uidOrder && isMounted) {
            setTimeout(() => {
                setIsReady(true)
            }, 400);
        }

        return () => {
            isMounted = false
        }

    }, [user, uidOrder])

    useEffect(() => {

        let isMounted = true

        const GetDataUser = async () => {
            const uid = user.uid
            const docRef = doc(db, "clients", uid);
            const docSnap = await getDoc(docRef);
            const type = docSnap.data()?.type
            let displayName = type === 'Judirico' ? docSnap.data().tradeName : `${docSnap.data().name}  ${docSnap.data().lastName}`;
            setDataUser({...docSnap.data(), displayName})
        }

        (isMounted && user) && GetDataUser()

        return (() => {
            isMounted = false
        })

    }, [user])

    useEffect(() => {

        let isMounted = true

        const GetDataOrder = async () => {

            const uid = user.uid
            const docRef = doc(db, "clients", uid, 'orders', uidOrder);
            const docSnapOrder = await getDoc(docRef);
            setDeliveryCost(parseFloat(docSnapOrder.data().deliveryCost))
            setShoppingCart(docSnapOrder.data().data)
            setDataOrder(docSnapOrder.data())
        }


        (isMounted && uidOrder) && GetDataOrder()

        return (() => {
            isMounted = false
        })

    }, [dataUser, uidOrder])

    useEffect(() => {

        let isMounted = true

        const GetDetail = async () => {

            let subTotal = 0

            shoppingCart.forEach(items => {

                const clientDis = parseFloat(items.clientDis)
                const totalDiscount = items.disProd + clientDis
                const resultDiscount = items.realPrice * (totalDiscount / 100)
                const netPrice = items.realPrice - resultDiscount
                const total = items.amount * netPrice
                subTotal = total + subTotal
            })

            const subTotal_2 = subTotal + deliveryCost
            const iva =ivaValue / 100
            const resultIva = subTotal_2 * iva
            const total = subTotal_2 + resultIva

            setSubTotal(subTotal)
            setSubTotal_2(parseFloat(subTotal_2))
            setIva(resultIva)
            setTotal(parseFloat(total))

        }

        if (user && isMounted && shoppingCart) {
            GetDetail().catch(() => null)
        }


        return (() => {
            isMounted = false
        })


    }, [user, shoppingCart, deliveryCost])

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <div className="wrapper-detail">
            <header>
                <div className="header-container">
                    <div className="contacts-container">


                        <div style={{width: '100%', cursor: 'pointer'}} onClick={() => navigate('/')}>
                            <img alt="" src={logo} width={matches ? '100%' : '80%'} height={matches ? 120 : 100}/>
                        </div>
                    </div>
                    <div className="session-container" style={{position: 'relative', zIndex: 2000}}>
                        {user ?
                            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">

                                <span style={{fontSize: 12, color: '#333', fontWeight: '500'}}>
                                    {`${user.displayName} ( ${user.typeClient} )`}
                                </span>

                                <IconButton style={{color: 'red'}}
                                            id="basic-button"
                                            aria-controls="basic-menu"
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClickMenu}
                                >
                                    <FilterListOutlinedIcon style={{color: '#282A36'}}/>
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={handleCloseMenu}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => {
                                        signOut(auth).then(() => {
                                            dataStore.ShoppingCart([])
                                            setSnacks({...snacks, message: 'Saliendo..!', type: 'info'})
                                            navigate('/')
                                        }).catch(() => {
                                            setOpen(true)
                                            setSnacks({
                                                ...snacks,
                                                message: 'Error inesperado intentolo otra vez!',
                                                type: 'error'
                                            })
                                        })
                                    }
                                    }>Cerrar sesión</MenuItem>
                                    <MenuItem onClick={() => {
                                        navigate('/order-history')
                                    }}>Historial de pedidos</MenuItem>
                                    <MenuItem onClick={() => {
                                        navigate('/update-password')
                                    }
                                    }>Actualizar contraseña</MenuItem>

                                </Menu>

                            </Stack> :
                            <Stack spacing={2} direction="row">
                                <Button style={{textTransform: 'none'}} variant="text"
                                        onClick={() => {
                                            navigate('/session')
                                            /// dataStore.Path(path)
                                        }}
                                >Iniciar</Button>
                                <Button
                                    onClick={() => {
                                        navigate('/session')
                                        //dataStore.Path(path)
                                    }}
                                    style={{textTransform: 'none', backgroundColor: 'red'}}
                                    variant="contained" disableElevation>
                                    Registarse
                                </Button>
                            </Stack>
                        }
                    </div>
                </div>
                <div style={{width: '80%',}}>
                    <Button variant="text" startIcon={<ArrowBackIcon/>}
                            onClick={async () => {
                                //dataStore.BackDetailHome(true)
                                // dataStore.UpdateFilter({...filterData})
                                navigate("/order-history")
                            }}>Volver</Button>
                </div>
            </header>
            <div className="main-detail-container">
                {(isReady && dataOrder) &&
                    <div>
                        {matches &&
                            <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                                <MyDoc user={dataUser} dataUser={dataUser} matches={matches} dataOrder={dataOrder}
                                       shoppingCart={shoppingCart} subTotal={subTotal} deliveryCost={deliveryCost}
                                       subTotal_2={subTotal_2} iva={iva} total={total}/>
                            </div>
                        }

                        {!matches &&
                            <div style={{width: '95%', margin: 'auto'}}>
                                <PDFDownloadLink
                                    document={<MyDoc2 user={dataUser} dataUser={dataUser} matches={matches}
                                                      dataOrder={dataOrder}
                                                      shoppingCart={shoppingCart} subTotal={subTotal}
                                                      deliveryCost={deliveryCost}
                                                      subTotal_2={subTotal_2} iva={iva} total={total}/>}
                                    fileName={`${dataOrder?.orderNumber}.pdf`}>
                                    {({loading,}) => {
                                        return (
                                            <Button variant="outlined" size="small" style={{textDecoration: 'none'}}
                                                    startIcon={<PictureAsPdfIcon/>}>
                                                {loading ? 'Cargando documento...' : 'Descargar documento'}
                                            </Button>
                                        )
                                    }}
                                </PDFDownloadLink>
                            </div>}
                    </div>}
            </div>
        </div>
    )
}

export default observer(OrdersPDF)


const styles = StyleSheet.create({
    root: {
        width: "80%",
        height: '100vh',
        margin: "auto"
    },
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        display: 'flex',
        flexDirection: 'column',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },

    rowContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#FADADA',
        padding: 5
    },

    rowContainer_2: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#FDFDFD',
        padding: 5
    },


    titleRows: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '26%'
    },


    titleRows_2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '8%'
    },

    image: {
        width: 170,
        height: 100
    },
    unitsContainer: {
        height: 250,
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },

    gridsHeaders: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 10,
        backgroundColor: '#0087CB',
        padding: 5,
    },

    rowViews: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }
});
