import React, {useState, useEffect, useContext} from 'react';
import Typography from '@mui/material/Typography';
import {Formik} from 'formik';
import {useStyles} from "../../../utils/Styles";
import {clientsSchema, iniClientValues} from "../../../utils/YupSchemas";
import InputForm from "../../../palette/InputForm";
import {sendRequestPhone} from "../../../utils/ValidatesDocuments";
import Text from "../../../palette/Text";
import {Autocomplete} from "@mui/lab";
import TextField from '@mui/material/TextField';
import {cities, province} from "../../../utils/ProvincieCities";
import {Filters} from "../../../utils/HelpFunctions";
import SubmitButton from "../../../palette/SubmitButton";
import {createUserWithEmailAndPassword, updateProfile} from "firebase/auth";
import {auth, db} from "../../../firebase/Firebase";
import {observer} from "mobx-react-lite";
import {StoreContext} from "../../../store/Context";
import {useNavigate} from "react-router-dom";
import {deleteDoc, doc, setDoc} from "firebase/firestore";
import CustomizedSnackbars from "../../../palette/SnacksBar";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import {format} from "date-fns";
import {es} from 'date-fns/locale';

function Register() {

    const {dataStore} = useContext(StoreContext)
    const {path} = dataStore

    const [validatePhoneNumber, setValidatePhoneNumber] = useState('')
    const [validatePhoneNumberError, setValidatePhoneNumberError] = useState(true)
    const [valueProvinces, setValueProvince] = useState(null);
    const [inputValueProvinces, setInputValueProvinces] = useState('');
    const [provinceSelect, setProvinceSelect] = useState('')
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [citiesArr, setCitiesArr] = useState([])
    const [loading, setLoading] = useState(false)
    const [textButton, setTextButton] = useState('Registrarse')
    const [open, setOpen] = useState(false)
    const [snacks, setSnacks] = useState({message: '', type: 'success'})

    const classes = useStyles();
    const navigate = useNavigate();


    useEffect(() => {
        const ValidatePhone = async () => {
            if (validatePhoneNumber !== '') {
                const result = await sendRequestPhone(validatePhoneNumber, 10)
                setValidatePhoneNumberError(result)
            }
        }

        ValidatePhone().then(() => null)

    }, [validatePhoneNumber])

    useEffect(() => {
        let isMounted = true;

        setCitiesArr([])
        setValue(null)
        setInputValue('')

        const filterProvince = Filters(province, 'province', provinceSelect)
        const id = filterProvince[0]?.id
        const filtered = Filters(cities, 'id', parseInt(id))
        isMounted && setCitiesArr(filtered)

        return () => {
            isMounted = false
        }

    }, [provinceSelect, inputValueProvinces])

    const onSubmit = (values) => {

        const email = values.email
        const password = values.password
        const date = format(new Date(), 'yyyy-MM-dd HH:mm')
        const month = format(new Date(), 'LLLL', {locale: es})
        const year = format(new Date(), 'yyyy')

        const newValues = {
            ...values, name: values.name, lastName: values.lastName, email: values.email, status: 'Activo',
            phoneNumber: values.phoneNumber, province: values.province, city: values.city, type: 'Natural',
            purchaseQuota: 2000, seller: 'Oficina', typeClient: 'Final', numberInstallments: 0, discount: [],
            date, month, year
        }

        setOpen(true)
        setTextButton('Iniciando')
        setLoading(true)


        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {

                updateProfile(auth.currentUser, {
                    displayName: `${values.name} ${values.lastName}`,
                }).then(async () => {
                    setOpen(true)
                    setSnacks({...snacks, message: 'Cliente registrado exitosamente!', type: 'success'})
                    const user = userCredential.user;
                    const uid = user.uid;
                    const displayName = user.displayName;
                    await setDoc(doc(db, "clients", uid), newValues);
                    await deleteDoc(doc(db, "clients", uid, 'cart', 'cart001'))
                    dataStore.ShoppingCart([])
                    dataStore.User({uid, displayName, typeClient: newValues.typeClient})
                    setLoading(false)
                    navigate(path ? path : '/')
                    setTextButton('Registrarse')

                }).catch(() => {
                    setOpen(true)
                    setSnacks({...snacks, message: 'Error inesperado intentolo otra vez!', type: 'error'})
                    setTextButton('Registrarse')
                    setLoading(false)
                });
            })
            .catch(() => {
                setOpen(true)
                setSnacks({...snacks, message: 'Usuario ya registrado!', type: 'error'})
                setTextButton('Registrarse')
                setLoading(false)
            });
    };

    return (
        <div className={'register-container'}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography component="h2" variant="h5">
                    NUEVO CLIENTE
                </Typography>
            </div>

            <br/>

            <Formik
                initialValues={iniClientValues}
                validationSchema={clientsSchema}
                onSubmit={onSubmit}
            >
                {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                  }) => {

                    setValidatePhoneNumber(values.phoneNumber)

                    return (
                        <>

                            <InputForm label="Nombres" full field={'name'} errors={errors}
                                       handleChange={handleChange} values={values}
                                       handleBlur={handleBlur}
                                       maxLength={30} small
                            />

                            <InputForm label="Apellidos" full field={'lastName'} errors={errors}
                                       handleChange={handleChange} values={values}
                                       handleBlur={handleBlur} top={12}
                                       maxLength={30} small
                            />

                            <InputForm label="Correo electrónico" full field={'email'} errors={errors}
                                       handleChange={handleChange} values={values}
                                       handleBlur={handleBlur} top={12}
                                       maxLength={30} small
                            />
                            <div style={{width: '100%', marginTop: 12}}>
                                <InputForm label="No. Celular" full field={'phoneNumber'}
                                           errors={errors}
                                           handleChange={handleChange} values={values}
                                           handleBlur={handleBlur} maxLength={10} small
                                />
                                {!validatePhoneNumberError &&
                                <Text text={'Número inválido'} size={10} color={'red'}/>}
                            </div>
                            <div style={{height: 8}}/>

                            <Autocomplete
                                id="combo-box"
                                name="province"
                                options={province}
                                getOptionLabel={(option) => option.province}
                                style={{marginTop: 7, width: '100%'}}
                                size={'small'}
                                value={valueProvinces}
                                onChange={(event, newValue) => {
                                    setValueProvince(newValue);
                                }}
                                onInputChange={(event, newInputValue) => {
                                    setInputValueProvinces(newInputValue);
                                }}
                                inputValue={inputValueProvinces}
                                renderInput={(params) => {

                                    values.province = params.inputProps.value
                                    setProvinceSelect(values.province)

                                    return (
                                        <TextField {...params}
                                                   label="Provincia"
                                                   variant="outlined"
                                                   name="province"
                                                   className={errors.province ? null : classes.formInput}
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   value={values.province}
                                                   error={!!errors.province}
                                                   helperText={errors.province}
                                                   size={'small'}
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                        />
                                    )
                                }}
                            />
                            <div style={{height: 8}}/>

                            <Autocomplete
                                id="combo-box"
                                name="cities"
                                options={citiesArr}
                                getOptionLabel={(option) => option.city}
                                noOptionsText={'Escoja la ciudad'}
                                style={{marginTop: 7, width: '100%'}}
                                size={'small'}
                                value={value}
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                inputValue={inputValue}

                                renderInput={(params) => {

                                    values.city = params.inputProps.value
                                    //setCitySelect(values.city)

                                    return (
                                        <TextField {...params}
                                                   label="Ciudad"
                                                   variant="outlined"
                                                   name="city"
                                                   className={errors.city ? null : classes.formInput}
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   value={values.city}
                                                   error={!!errors.city}
                                                   helperText={errors.city}
                                                   size={'small'}
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                        />
                                    )
                                }
                                }
                            />

                            <InputForm label="Password" full field={'password'} errors={errors} top={10} small
                                       handleChange={handleChange} values={values} handleBlur={handleBlur}
                            />

                            <InputForm label="Confirmar contraseña" full field={'passwordConfirmation'}
                                       errors={errors} small
                                       handleChange={handleChange} values={values} handleBlur={handleBlur}
                                       top={10}
                            />
                            <SubmitButton text={textButton} type="submit" onClick={handleSubmit}
                                          loading={loading} icon={<HowToRegIcon/>}/>

                        </>
                    )
                }}
            </Formik>
            <CustomizedSnackbars open={open} setOpen={setOpen} message={snacks.message} type={snacks.type}/>
        </div>
    )
}

export default observer(Register)
