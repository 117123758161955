import React, {useContext, useEffect} from "react";
import {Routes, Route, useNavigate} from "react-router-dom";
import Home from "../components/home/Home";
import Details from "../components/details/Details";
import Session from "../components/session/Session";
import RecoverPassword from "../components/recover-password/RecoverPassword";
import UpdatePassword from "../components/update-password/UpdatePassword";
import ShoppingCart from "../components/shopping-cart/ShoppingCart";
import Order from "../components/order/Order";
import {doc, getDoc} from "firebase/firestore";
import {auth, db} from "../firebase/Firebase";
import {onAuthStateChanged, signOut} from "firebase/auth";
import {StoreContext} from "../store/Context";
import OrdersPDF from "../components/orders-pdf/OrdersPDF";
import TermsConditions from "../components/terms-conditions/TermsConditions";
import OrderHistory from "../components/order-history/OrderHistory";

function IndexRoutes() {

    const {dataStore} = useContext(StoreContext)
    const {triggerUserData} = dataStore


    window.addEventListener("beforeunload", (ev) => {
        ev.preventDefault();
        return ev.returnValue = 'Are you sure you want to close?';
    })

    const navigate = useNavigate();

    useEffect(() => {

        const GetCart = async (user) => {
            const uid = user.uid
            const docRef = doc(db, "clients", uid, 'cart', 'cart001');
            try {
                const doc = await getDoc(docRef);
                const shoppingCart = doc.data().data
                dataStore.ShoppingCart(shoppingCart)
                dataStore.UIDCart(doc.data().uidCart)
            } catch (e) {
                // console.log("Error getting cached document:", e);
            }
        }

        try {
            onAuthStateChanged(auth, async (user) => {

                if (user) {

                    GetCart(user).catch(() => null)
                    const uid = user.uid;
                    const docRef = doc(db, "clients", uid);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        const city = docSnap.data()?.city ? docSnap.data()?.city : null
                        const type = docSnap.data()?.type
                        const purchaseQuota = docSnap.data()?.purchaseQuota
                        const paymentDeadline = docSnap.data()?.paymentDeadline
                        const idClient = docSnap.data()?.idClient
                        const phoneNumber = docSnap.data()?.phoneNumber
                        const email = docSnap.data()?.email
                        const deliveryCity = docSnap.data()?.deliveryCity
                        let displayName = type === 'Judirico' ? docSnap.data().tradeName : `${docSnap.data().name}  ${docSnap.data().lastName}`;
                        const discount = docSnap.data()?.discount
                        const typeClient = docSnap.data()?.typeClient
                        dataStore.User({
                            uid, displayName, typeClient, discount, city, paymentDeadline, purchaseQuota,
                            deliveryCity, type, idClient, phoneNumber, email
                        })
                    } else {
                        signOut(auth).then(() => {
                            navigate('/')
                        })
                    }

                } else {
                    dataStore.User(null)
                }
            });

        } catch (e) {
           // console.log(e)
        }
    }, [triggerUserData])

    return (

        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/product-details" element={<Details/>}/>
            <Route path="/session" element={<Session/>}/>
            <Route path="/recover-password" element={<RecoverPassword/>}/>
            <Route path="/update-password" element={<UpdatePassword/>}/>
            <Route path="/shopping-cart" element={<ShoppingCart/>}/>
            <Route path="/order" element={<Order/>}/>
            <Route path="/orders-pdf" element={<OrdersPDF/>}/>
            <Route path="/terms-conditions" element={<TermsConditions/>}/>
            <Route path="/order-history" element={<OrderHistory/>}/>
        </Routes>
    )
}


export default IndexRoutes
