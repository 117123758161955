import {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Stack from "@mui/material/Stack";
import Text from "../../../palette/Text";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {signOut} from "firebase/auth";
import {auth} from "../../../firebase/Firebase";
import Button from "@mui/material/Button";
import {StoreContext} from "../../../store/Context";
import {observer} from "mobx-react-lite";
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

function UserOptions(props) {

    const {path,} = props;

    const {dataStore} = useContext(StoreContext)
    const {user} = dataStore

    const [open, setOpen] = useState(false);
    const [openSnack, setOpenSnak] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [snacks, setSnacks] = useState({message: '', type: 'success'})

    const anchorRef = useRef(null);
    const openMenu = Boolean(anchorEl);

    const navigate = useNavigate();


    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };


    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    return (

        <div className={'user-o-container'}>
            {user ?
                <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">

                    <div className={'icon-user-pos-2'}>
                        <IconButton style={{color: 'red'}}
                                    id="basic-button"
                                    aria-controls="basic-menu"
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClickMenu}
                        >
                            <FilterListOutlinedIcon style={{color: 'white'}}/>
                        </IconButton>
                    </div>

                    <div className={'user-display-container'}>
                        <Text text={`${user.displayName}`} size={10} weeigh={'700'}/>
                        <Text text={`( ${user.typeClient} )`} size={10} weeigh={'700'}/>
                    </div>

                    <div className={'icon-user-pos-1'}>
                        <IconButton style={{color: 'red'}}
                                    id="basic-button"
                                    aria-controls="basic-menu"
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClickMenu}
                        >
                            <FilterListOutlinedIcon style={{color: 'white'}}/>
                        </IconButton>
                    </div>


                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleCloseMenu}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={() => {
                            signOut(auth).then(() => {
                                dataStore.ShoppingCart([])
                                setOpenSnak(true)
                                window.localStorage.clear()
                                setSnacks({...snacks, message: 'Saliendo..!', type: 'info'})
                                navigate('/')
                            }).catch(() => {
                                setOpen(true)
                                setSnacks({
                                    ...snacks,
                                    message: 'Error inesperado intentolo otra vez!',
                                    type: 'error'
                                })
                            })
                        }
                        }>Cerrar sesión</MenuItem>
                        <MenuItem onClick={() => {
                            navigate('/order-history')
                        }}>Historial de pedidos</MenuItem>
                        <MenuItem onClick={() => {
                            navigate('/update-password')
                        }
                        }>Actualizar contraseña</MenuItem>
                    </Menu>


                </Stack> :
                <Stack spacing={2} direction="row">

                    <Button style={{textTransform: 'none', color: '#FAFAFA'}} variant="text"
                            onClick={() => {
                                navigate('/session')
                                dataStore.Path(path)
                            }}
                    >Iniciar</Button>
                    <Button
                        onClick={() => {
                            navigate('/session')
                            dataStore.Path(path)
                        }}
                        style={{textTransform: 'none', backgroundColor: 'red', borderRadius: 25}}
                        variant="contained" disableElevation>
                        Registarse
                    </Button>

                </Stack>
            }

        </div>

    )
}

export default observer(UserOptions)
