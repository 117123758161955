import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {useContext, useEffect, useState} from "react";
import {useStyles} from "../../../utils/Styles";
import {FilterDelete, Filters, Order} from "../../../utils/HelpFunctions";
import {collection, query, where, getDocs} from "firebase/firestore";
import {db} from "../../../firebase/Firebase";
import {StoreContext} from "../../../store/Context";
import {observer} from "mobx-react-lite";

function FilterGSGNP(props) {

    const {dataStore} = useContext(StoreContext)
    const {filterData, showNoStock, globalFilter, globalFilterCombination} = dataStore

    const {
        groups, valueGroups, setValueGroups, inputValueGroups, setInputValueGroups, valueSubGroups,
        setValueSubGroups, inputValueSubGroups, setInputValueSubGroups, subGroups, setSubGroups,
        schemasList, setSchemasList, inputValueSchema, setInputValueSchemas, valueSchema, setValueSchema,
        trigger, setTrigger, schemas, setTriggerProd, triggerProd, setTriggerSubGroup, triggerSubGroup,
        setTriggerGroup, triggerGroup
    } = props


    const [labelText, setLabelText] = useState('Sub grupos')

    const classes = useStyles();

    useEffect(() => {

        let isMounted = true

        const GetSubGroup = async () => {
            if (inputValueSubGroups !== '') {

                //const filterSchemas = FilterDelete(schemas, 'subGroup', inputValueSubGroups)
                const filterSchemas_2 = Filters(schemas, 'subGroup', inputValueSubGroups)

                setInputValueSchemas('')
                const data = {...filterData, schemaArr: filterSchemas_2, subGroup: true}
                setSchemasList(Order(filterSchemas_2, 'name'))
                dataStore.SchemaFilter(null)
                await dataStore.FilterData(data, showNoStock)
            } else {
                const data = {...filterData, schemaArr: [], subGroup: false}
                if (valueGroups) {
                    dataStore.UpdateFilter(data, showNoStock)
                    setTriggerGroup(!triggerGroup)
                } else {
                    await dataStore.FilterData(data, showNoStock)
                    const {vehicles, models, qYear, manufacturers, schema, groups, subGroup, schemaArr} = data
                    if (vehicles === '' && models === '' && qYear === '' && manufacturers === ''
                        && schema === '' && groups === false && subGroup === false && schemaArr.length === 0) {
                        setTriggerProd(!triggerProd)
                    }
                }
            }
        }

        isMounted && GetSubGroup()

        return (() => {
            isMounted = false
        })

    }, [valueSubGroups, inputValueSubGroups, triggerSubGroup])

    useEffect(() => {

        let isMounted = true

        const GetGroup = async () => {

            if (valueGroups) {
                setLabelText('Cargando...')
                setInputValueSubGroups('')
                //setInputValueSchemas('')
                const filter = Filters(groups, 'group', inputValueGroups)
                const docId = filter[0].docId;
                const q = query(collection(db, "subGroups"), where("docId", "==", docId));
                const querySnapshot = await getDocs(q);
                const array = []

                /*Get Sub groups*/
                querySnapshot.forEach((doc) => {
                    const newObject = {...doc.data(), docIdSubGroup: doc.id}
                    array.push(newObject);
                });

                setSubGroups(array)
                setLabelText('Sub grupos')

                /*Get Schemas*/
                const qSchema = query(collection(db, "schemas"), where("groupDocId", "==", docId));
                const querySnapshotSchemas = await getDocs(qSchema);
                const arraySchemas = []
                querySnapshotSchemas.forEach((doc) => {
                    const newObject = {...doc.data()}
                    arraySchemas.push(newObject);
                });

                const data = {...filterData, schemaArr: arraySchemas, groups: true}
                await dataStore.FilterData(data, showNoStock)
                setSchemasList(Order(arraySchemas, 'name'))
            } else {

                const data = {...filterData, schemaArr: [], groups: false}


                if (!data.groups && !data.subGroup) {
                    setSchemasList(Order(schemas, 'name'))
                }


                if (inputValueSubGroups !== '') {
                    dataStore.UpdateFilter(data, showNoStock)
                    setTriggerSubGroup(!triggerSubGroup)
                } else {
                    //await dataStore.FilterData(data)
                    const {vehicles, models, qYear, manufacturers, schema, groups, subGroup, schemaArr} = data

                    if (vehicles === '' && models === '' && qYear === '' && manufacturers === '' && schema === ''
                        && groups === false && subGroup === false && schemaArr.length === 0) {
                        setTriggerProd(!triggerProd)
                    }
                }
            }
        }

        isMounted && GetGroup()

        return (() => {
            isMounted = false
        })

    }, [valueGroups, triggerGroup])

    useEffect(() => {
        let isMounted = true

        const GetSchema = async () => {

            if (valueSchema) {

                const data = {...filterData, schema: inputValueSchema}

                const dataGlobal = {...globalFilterCombination, schema: inputValueSchema}
                dataStore.GlobalFilterCombination(dataGlobal)


                if (globalFilter) {
                    const q = query(collection(db, "schemas"), where("name", "==", inputValueSchema));
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                        dataStore.SchemaFilter(doc.data())
                    });
                    return
                }


                await dataStore.FilterData(data, showNoStock)

                const q = query(collection(db, "schemas"), where("name", "==", inputValueSchema));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    dataStore.SchemaFilter(doc.data())
                });

            } else {

                const dataGlobal_1 = {...globalFilterCombination, schema: inputValueSchema}
                dataStore.GlobalFilterCombination(dataGlobal_1)


                if (globalFilter) {
                    dataStore.SchemaFilter(null)
                    return
                }


                const data = {...filterData, schema: ''}
                const dataGlobal = {...globalFilterCombination, schema: ''}
                dataStore.GlobalFilterCombination(dataGlobal)

                if (globalFilter) {
                    return
                }

                if (inputValueSubGroups !== '') {
                    dataStore.UpdateFilter(data, showNoStock)
                    // setTriggerSubGroup(!triggerSubGroup)
                }

                if (valueGroups) {
                    dataStore.UpdateFilter(data, showNoStock)
                    // setTriggerGroup(!triggerGroup)
                } else {
                    const data = {...filterData, schema: inputValueSchema, groups: false}
                    await dataStore.FilterData(data)
                }

                if (inputValueSchema === '') {
                    dataStore.SchemaFilter(null)
                }

                await dataStore.FilterData(data)

                if (valueGroups == null && inputValueSubGroups === '') {

                    const {vehicles, models, qYear, manufacturers, schema, groups, subGroup, schemaArr} = data

                    if (vehicles === '' && models === '' && qYear === '' && manufacturers === '' && schema === ''
                        && groups === false && subGroup === false && schemaArr.length === 0) {
                        setTriggerProd(!triggerProd)
                    }
                }

            }
        }

        isMounted && GetSchema()

        return (() => {
            isMounted = false
        })

    }, [valueSchema])

    return (
        <div className="filter-container">
            <span style={{marginTop: 10, color: '#282A36'}}>Grupo/Sub grupo/Nombre producto</span>
            <Autocomplete
                id="groups"
                style={{marginTop: 10}}
                freeSolo
                fullWidth
                options={groups?.map((option) => option.group)}
                value={valueGroups}
                noOptionsText={'Grupo'}
                onChange={(event, newValue) => {
                    newValue === null && setTrigger(!trigger)
                    //newValue === null && setInputValueSubGroups('')
                    // newValue === null && setInputValueSchemas('')
                    setValueGroups(newValue);
                }}
                inputValue={inputValueGroups}
                onInputChange={(event, newInputValue) => {
                    setInputValueGroups(newInputValue);
                }}
                disabled={globalFilter}
                renderInput={(params) =>
                    <TextField {...params} label="Grupo" fullWidth size="small" className={classes.formInput}/>
                }
            />

            <Autocomplete
                id="groups"
                style={{marginTop: 10}}
                freeSolo
                fullWidth
                //disabled={!valueGroups}
                options={subGroups?.map((option) => option.subGroup)}
                value={valueSubGroups}
                noOptionsText={'Sub grupo'}
                onChange={(event, newValue) => {
                    setValueSubGroups(newValue);
                }}
                inputValue={inputValueSubGroups}
                onInputChange={(event, newInputValue) => {
                    setInputValueSubGroups(newInputValue);
                }}
                disabled={globalFilter}
                renderInput={(params) =>
                    <TextField {...params} label={labelText} fullWidth size="small" className={classes.formInput}/>
                }
            />

            <Autocomplete
                id="schema"
                style={{marginTop: 10}}
                freeSolo
                fullWidth
                //disabled={!valueSubGroups || schemasList.length === 0}
                options={schemasList?.map((option) => option.name)}
                value={valueSchema}
                noOptionsText={'Nombre producto'}
                onChange={(event, newValue) => {
                    setValueSchema(newValue);
                }}
                inputValue={inputValueSchema}
                onInputChange={(event, newInputValue) => {
                    setInputValueSchemas(newInputValue);
                }}
                renderInput={(params) =>
                    <TextField {...params} label={'Nombre producto'}
                               fullWidth size="small" className={classes.formInput}/>
                }
            />

        </div>
    )
}

export default observer(FilterGSGNP)
