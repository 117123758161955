import TextField from '@mui/material/TextField';
import {useStyles} from "../utils/Styles";
import {useEffect, useState} from "react";

function InputForm(props) {

    const {
        field, label, errors, handleChange, handleBlur, values, full, small, uppercase, lowercase,
        top, width, left, rows, multiline, disabled, maxLength
    } = props

    const [showPassword] = useState(false)


    useEffect(() => {


    }, [showPassword])

    const classes = useStyles();

    return (
        <TextField
            style={{marginTop: top, width: width, marginLeft: left,}}
            label={label}
            variant="outlined"
            fullWidth={full}
            name={field}
            className={errors[field] ? null : classes.formInput}
            onChange={handleChange}
            onBlur={handleBlur}
            multiline={multiline}
            size={small ? 'small' : null}
            rows={rows}
            type={field === 'password' || field === 'passwordConfirmation' ? 'password' : 'default'}
            //security={true}
            // rowsMax={rowsMax}
            value={values[field]}
            error={!!errors[field]}
            helperText={errors[field]}
            inputProps={{
                maxLength: maxLength,
                style: {textTransform: uppercase ? "uppercase" : lowercase ? 'lowercase' : null}
            }}
            disabled={disabled}
        />
    )
}


export default InputForm
