export const province = [
    {id: '1', province: 'AZUAY'},
    {id: '2', province: 'BOLIVAR'},
    {id: '3', province: 'CAÑAR'},
    {id: '4', province: 'CARCHI'},
    {id: '5', province: 'COTOPAXI'},
    {id: '6', province: 'CHIMBORAZO'},
    {id: '7', province: 'EL ORO'},
    {id: '8', province: 'ESMERALDAS'},
    {id: '9', province: 'GUAYAS'},
    {id: '10', province: 'IMBABURA'},
    {id: '11', province: 'LOJA'},
    {id: '12', province: 'LOS RIOS'},
    {id: '13', province: 'MANABÍ'},
    {id: '14', province: 'MORONA SANTIAGO'},
    {id: '15', province: 'NAPO'},
    {id: '16', province: 'PASTAZA'},
    {id: '17', province: 'PICHINCHA'},
    {id: '18', province: 'TUNGURAHUA'},
    {id: '19', province: 'ZAMORA CHINCHIPE'},
    {id: '20', province: 'GALAPAGOS'},
    {id: '21', province: 'SUCUMBIOS'},
    {id: '22', province: 'ORELLANA'},
    {id: '23', province: 'SANTO DOMINGO DE LOS TSACHILAS'},
    {id: '24', province: 'SANTA ELENA'},
]

export const cities = [
    {id: 1, city: 'CUENCA', deliveryCost: 2.00},
    {id: 1, city: 'GIRÓN', deliveryCost: 2.00},
    {id: 1, city: 'GUALACEO', deliveryCost: 2.00},
    {id: 1, city: 'NABÓN', deliveryCost: 2.00},
    {id: 1, city: 'PAUTE', deliveryCost: 2.00},
    {id: 1, city: 'PUCARÁ', deliveryCost: 2.00},
    {id: 1, city: 'SAN FERNANDO', deliveryCost: 2.00},
    {id: 1, city: 'SANTA ISABEL', deliveryCost: 2.00},
    {id: 1, city: 'SIGSIG', deliveryCost: 2.00},
    {id: 1, city: 'OÑA', deliveryCost: 2.00},
    {id: 1, city: 'CHORDELEG', deliveryCost: 2.00},
    {id: 1, city: 'EL PAN', deliveryCost: 2.00},
    {id: 1, city: 'SEVILLA DE ORO', deliveryCost: 2.00},
    {id: 1, city: 'GUACHAPALA', deliveryCost: 2.00},
    {id: 1, city: 'CAMILO PONCE ENRIQUEZ', deliveryCost: 2.00},

    {id: 2, city: 'GUARANDA', deliveryCost: 2.00},
    {id: 2, city: 'CHIMBO', deliveryCost: 2.00},
    {id: 2, city: 'ECHEANDÍA', deliveryCost: 2.00},
    {id: 2, city: 'SAN MIGUEL', deliveryCost: 2.00},
    {id: 2, city: 'CALUMA', deliveryCost: 2.00},
    {id: 2, city: 'LAS NAVES', deliveryCost: 2.00},

    {id: 3, city: 'AZOGUES', deliveryCost: 2.00},
    {id: 3, city: 'BIBLIÁN', deliveryCost: 2.00},
    {id: 3, city: 'CAÑAR', deliveryCost: 2.00},
    {id: 3, city: 'LA TRONCAL', deliveryCost: 2.00},
    {id: 3, city: 'EL TAMBO', deliveryCost: 2.00},
    {id: 3, city: 'DÉLEG', deliveryCost: 2.00},
    {id: 3, city: 'SUSCAL', deliveryCost: 2.00},

    {id: 4, city: 'TULCÁN', deliveryCost: 2.00},
    {id: 4, city: 'BOLÍVAR', deliveryCost: 2.00},
    {id: 4, city: 'ESPEJO', deliveryCost: 2.00},
    {id: 4, city: 'MIRA', deliveryCost: 2.00},
    {id: 4, city: 'MONTÚFAR', deliveryCost: 2.00},
    {id: 4, city: 'SAN PEDRO DE HUACA', deliveryCost: 2.00},

    {id: 5, city: 'LATACUNGA', deliveryCost: 2.00},
    {id: 5, city: 'LA MANÁ', deliveryCost: 2.00},
    {id: 5, city: 'PANGUA', deliveryCost: 2.00},
    {id: 5, city: 'PUJILI', deliveryCost: 2.00},
    {id: 5, city: 'SALCEDO', deliveryCost: 2.00},
    {id: 5, city: 'SAQUISILI', deliveryCost: 2.00},
    {id: 5, city: 'SIGCHOS', deliveryCost: 2.00},

    {id: 6, city: 'RIOBAMBA', deliveryCost: 2.00},
    {id: 6, city: 'ALAUSÍ', deliveryCost: 2.00},
    {id: 6, city: 'COLTA', deliveryCost: 2.00},
    {id: 6, city: 'CHAMBO', deliveryCost: 2.00},
    {id: 6, city: 'CHUNCHI', deliveryCost: 2.00},
    {id: 6, city: 'GUAMOTE', deliveryCost: 2.00},
    {id: 6, city: 'GUANO', deliveryCost: 2.00},
    {id: 6, city: 'PALLATANGA', deliveryCost: 2.00},
    {id: 6, city: 'PENIPE', deliveryCost: 2.00},
    {id: 6, city: 'CUMANDÁ', deliveryCost: 2.00},

    {id: 7, city: 'MACHALA', deliveryCost: 2.00},
    {id: 7, city: 'ARENILLAS', deliveryCost: 2.00},
    {id: 7, city: 'ATAHUALPA', deliveryCost: 2.00},
    {id: 7, city: 'BALSAS', deliveryCost: 2.00},
    {id: 7, city: 'CHILLA', deliveryCost: 2.00},
    {id: 7, city: 'EL GUABO', deliveryCost: 2.00},
    {id: 7, city: 'HUAQUILLAS', deliveryCost: 2.00},
    {id: 7, city: 'MARCABELI', deliveryCost: 2.00},
    {id: 7, city: 'PASAJE', deliveryCost: 2.00},
    {id: 7, city: 'PIÑAS', deliveryCost: 2.00},
    {id: 7, city: 'PORTOVELO', deliveryCost: 2.00},
    {id: 7, city: 'SANTA ROSA', deliveryCost: 2.00},
    {id: 7, city: 'ZARUMA', deliveryCost: 2.00},
    {id: 7, city: 'LAS LAJAS', deliveryCost: 2.00},

    {id: 8, city: 'ESMERALDAS', deliveryCost: 2.00},
    {id: 8, city: 'ELOY ALFARO', deliveryCost: 2.00},
    {id: 8, city: 'MUISNE', deliveryCost: 2.00},
    {id: 8, city: 'QUININDÉ', deliveryCost: 2.00},
    {id: 8, city: 'SAN LORENZO', deliveryCost: 2.00},
    {id: 8, city: 'ATACAMES', deliveryCost: 2.00},
    {id: 8, city: 'RIO VERDE', deliveryCost: 2.00},
    {id: 8, city: 'LA CONCORDIA', deliveryCost: 2.00},

    {id: 9, city: 'GUAYAQUIL', deliveryCost: 2.00},
    {id: 9, city: 'ALFREDO BAQUERIZO MORENO', deliveryCost: 2.00},
    {id: 9, city: 'BALAO', deliveryCost: 2.00},
    {id: 9, city: 'BALZAR', deliveryCost: 2.00},
    {id: 9, city: 'COLIMES', deliveryCost: 2.00},
    {id: 9, city: 'DAULE', deliveryCost: 2.00},
    {id: 9, city: 'DURÁN', deliveryCost: 2.00},
    {id: 9, city: 'EL EMPALME', deliveryCost: 2.00},
    {id: 9, city: 'EL TRIUNFO', deliveryCost: 2.00},
    {id: 9, city: 'MILAGRO', deliveryCost: 2.00},
    {id: 9, city: 'NARANJAL', deliveryCost: 2.00},
    {id: 9, city: 'NARANJITO', deliveryCost: 2.00},
    {id: 9, city: 'PALESTINA', deliveryCost: 2.00},
    {id: 9, city: 'PEDRO CARBO', deliveryCost: 2.00},
    {id: 9, city: 'SAMBORONDÓN', deliveryCost: 2.00},
    {id: 9, city: 'SANTA LUCÍA', deliveryCost: 2.00},
    {id: 9, city: 'SALITRE', deliveryCost: 2.00},
    {id: 9, city: 'SAN JACINTO DE YAGUACHI', deliveryCost: 2.00},
    {id: 9, city: 'PLAYAS', deliveryCost: 2.00},
    {id: 9, city: 'SIMÓN BOLÍVAR', deliveryCost: 2.00},
    {id: 9, city: 'CORONEL MARCELINO MALIDUEÑA', deliveryCost: 2.00},
    {id: 9, city: 'LOMAS DE SARGENTILLO', deliveryCost: 2.00},
    {id: 9, city: 'NOBOL', deliveryCost: 2.00},
    {id: 9, city: 'GENERAL ANTONIO ELIZALDE', deliveryCost: 2.00},
    {id: 9, city: 'ISIDRO AYORA', deliveryCost: 2.00},

    {id: 10, city: 'IBARRA', deliveryCost: 2.00},
    {id: 10, city: 'ANTONIO ANTE', deliveryCost: 2.00},
    {id: 10, city: 'COTACACHI', deliveryCost: 2.00},
    {id: 10, city: 'OTAVALO', deliveryCost: 2.00},
    {id: 10, city: 'PIMAMPIRO', deliveryCost: 2.00},
    {id: 10, city: 'SAN MIGUEL DE URCUQUÍ', deliveryCost: 2.00},

    {id: 11, city: 'LOJA', deliveryCost: 2.00},
    {id: 11, city: 'CALVAS', deliveryCost: 2.00},
    {id: 11, city: 'CATAMAYO', deliveryCost: 2.00},
    {id: 11, city: 'CELICA', deliveryCost: 2.00},
    {id: 11, city: 'CHAGUARPAMBA', deliveryCost: 2.00},
    {id: 11, city: 'ESPÍNDOLA', deliveryCost: 2.00},
    {id: 11, city: 'GONZANAMÁ', deliveryCost: 2.00},
    {id: 11, city: 'MACARÁ', deliveryCost: 2.00},
    {id: 11, city: 'PALTAS', deliveryCost: 2.00},
    {id: 11, city: 'PUYANGO', deliveryCost: 2.00},
    {id: 11, city: 'SARAGURO', deliveryCost: 2.00},
    {id: 11, city: 'SOZORANGA', deliveryCost: 2.00},
    {id: 11, city: 'ZAPOTILLO', deliveryCost: 2.00},
    {id: 11, city: 'PINDAL', deliveryCost: 2.00},
    {id: 11, city: 'QUILANGA', deliveryCost: 2.00},
    {id: 11, city: 'OLMEDO', deliveryCost: 2.00},

    {id: 12, city: 'BABAHOYO', deliveryCost: 2.00},
    {id: 12, city: 'BABA', deliveryCost: 2.00},
    {id: 12, city: 'MONTALVO', deliveryCost: 2.00},
    {id: 12, city: 'PUEBLO VIEJO', deliveryCost: 2.00},
    {id: 12, city: 'QUEVEDO', deliveryCost: 2.00},
    {id: 12, city: 'URDANETA', deliveryCost: 2.00},
    {id: 12, city: 'VENTANAS', deliveryCost: 2.00},
    {id: 12, city: 'VINCES', deliveryCost: 2.00},
    {id: 12, city: 'PALENQUE', deliveryCost: 2.00},
    {id: 12, city: 'BUENA FÉ', deliveryCost: 2.00},
    {id: 12, city: 'VALENCIA', deliveryCost: 2.00},
    {id: 12, city: 'MOCACHE', deliveryCost: 2.00},
    {id: 12, city: 'QUINSALOMA', deliveryCost: 2.00},


    {id: 13, city: 'PORTOVIEJO', deliveryCost: 2.00},
    {id: 13, city: 'BOLÍVAR', deliveryCost: 2.00},
    {id: 13, city: 'CHONE', deliveryCost: 2.00},
    {id: 13, city: 'EL CARMEN', deliveryCost: 2.00},
    {id: 13, city: 'FLAVIO ALFARO', deliveryCost: 2.00},
    {id: 13, city: 'JIPIJAPA', deliveryCost: 2.00},
    {id: 13, city: 'JUNÍN', deliveryCost: 2.00},
    {id: 13, city: 'MANTA', deliveryCost: 2.00},
    {id: 13, city: 'MONTECRISTI', deliveryCost: 2.00},
    {id: 13, city: 'PAJÁN', deliveryCost: 2.00},
    {id: 13, city: 'PICHINCHA', deliveryCost: 2.00},
    {id: 13, city: 'ROCAFUERTE', deliveryCost: 2.00},
    {id: 13, city: 'SANTA ANA', deliveryCost: 2.00},
    {id: 13, city: 'SUCRE', deliveryCost: 2.00},
    {id: 13, city: 'TOSAGUA', deliveryCost: 2.00},
    {id: 13, city: '203 DE MAYO', deliveryCost: 2.00},
    {id: 13, city: 'PEDERNALES', deliveryCost: 2.00},
    {id: 13, city: 'OLMEDO', deliveryCost: 2.00},
    {id: 13, city: 'PUERTO LÓPEZ', deliveryCost: 2.00},
    {id: 13, city: 'JAMA', deliveryCost: 2.00},
    {id: 13, city: 'JARAMIJÓ', deliveryCost: 2.00},
    {id: 13, city: 'SAN VICENTE', deliveryCost: 2.00},

    {id: 14, city: 'MACAS', deliveryCost: 2.00},
    {id: 14, city: 'GUALAQUIZA', deliveryCost: 2.00},
    {id: 14, city: 'LIMÓN INDANZA', deliveryCost: 2.00},
    {id: 14, city: 'PALORA', deliveryCost: 2.00},
    {id: 14, city: 'SANTIAGO', deliveryCost: 2.00},
    {id: 14, city: 'SUCÚA', deliveryCost: 2.00},
    {id: 14, city: 'HUAMBOYA', deliveryCost: 2.00},
    {id: 14, city: 'SAN JUAN BOSCO', deliveryCost: 2.00},
    {id: 14, city: 'TAISHA', deliveryCost: 2.00},
    {id: 14, city: 'LOGROÑO', deliveryCost: 2.00},
    {id: 14, city: 'PABLO SEXTO', deliveryCost: 2.00},
    {id: 14, city: 'TIWINTZA', deliveryCost: 2.00},

    {id: 15, city: 'TENA', deliveryCost: 2.00},
    {id: 15, city: 'ARCHIDONA', deliveryCost: 2.00},
    {id: 15, city: 'EL CHACO', deliveryCost: 2.00},
    {id: 15, city: 'QUIJOS', deliveryCost: 2.00},
    {id: 15, city: 'CARLOS JULIO AROSEMA TOLA', deliveryCost: 2.00},

    {id: 16, city: 'PUYO', deliveryCost: 2.00},
    {id: 16, city: 'MERA', deliveryCost: 2.00},
    {id: 16, city: 'SANTA CLARA', deliveryCost: 2.00},
    {id: 16, city: 'ARAJUNO', deliveryCost: 2.00},

    {id: 17, city: 'QUITO', deliveryCost: 2.00},
    {id: 17, city: 'CAYAMBE', deliveryCost: 2.00},
    {id: 17, city: 'MEJIA', deliveryCost: 2.00},
    {id: 17, city: 'PEDRO MONCAYO', deliveryCost: 2.00},
    {id: 17, city: 'RUMIÑAHUI', deliveryCost: 2.00},
    {id: 17, city: 'SAN MIGUEL DE LOS BANCOS', deliveryCost: 2.00},
    {id: 17, city: 'PEDRO VICENTE MALDONADO', deliveryCost: 2.00},
    {id: 17, city: 'PUERTO QUITO', deliveryCost: 2.00},

    {id: 18, city: 'AMBATO', deliveryCost: 2.00},
    {id: 18, city: 'BAÑOS DE AGUA SANTA', deliveryCost: 2.00},
    {id: 18, city: 'CEVALLOS', deliveryCost: 2.00},
    {id: 18, city: 'MOCHA', deliveryCost: 2.00},
    {id: 18, city: 'PATATE', deliveryCost: 2.00},
    {id: 18, city: 'QUERO', deliveryCost: 2.00},
    {id: 18, city: 'SAN PEDRO DE PELILEO', deliveryCost: 2.00},
    {id: 18, city: 'SANTIAGO DE PÍLLARO', deliveryCost: 2.00},
    {id: 18, city: 'TISALEO', deliveryCost: 2.00},

    {id: 19, city: 'ZAMORA', deliveryCost: 2.00},
    {id: 19, city: 'CHINCHIPE', deliveryCost: 2.00},
    {id: 19, city: 'NANGARITZA', deliveryCost: 2.00},
    {id: 19, city: 'YACUAMBI', deliveryCost: 2.00},
    {id: 19, city: 'YANTZAZA', deliveryCost: 2.00},
    {id: 19, city: 'EL PANGUI', deliveryCost: 2.00},
    {id: 19, city: 'CENTINELA DEL CÓNDOR', deliveryCost: 2.00},
    {id: 19, city: 'PALANDA', deliveryCost: 2.00},
    {id: 19, city: 'PAQUISHA', deliveryCost: 2.00},

    {id: 20, city: 'SAN CRISTÓBAL', deliveryCost: 2.00},
    {id: 20, city: 'ISABELA', deliveryCost: 2.00},
    {id: 20, city: 'SANTA CRUZ', deliveryCost: 2.00},

    {id: 21, city: 'LAGO AGRIO', deliveryCost: 2.00},
    {id: 21, city: 'GONZALO PIZARRO', deliveryCost: 2.00},
    {id: 21, city: 'PUTUMAYO', deliveryCost: 2.00},
    {id: 21, city: 'SHUSHUFINDI', deliveryCost: 2.00},
    {id: 21, city: 'SUCUMBIOS', deliveryCost: 2.00},
    {id: 21, city: 'CASCALES', deliveryCost: 2.00},
    {id: 21, city: 'CUYABENO', deliveryCost: 2.00},

    {id: 22, city: 'ORELLANA', deliveryCost: 2.00},
    {id: 22, city: 'AGUARICO', deliveryCost: 2.00},
    {id: 22, city: 'LA JOYA DE LOS SACHAS', deliveryCost: 2.00},
    {id: 22, city: 'LORETO', deliveryCost: 2.00},

    {id: 23, city: 'SANTO DOMINGO', deliveryCost: 2.00},

    {id: 24, city: 'SANTA ELENA', deliveryCost: 2.00},
    {id: 24, city: 'LA LIBERTAD', deliveryCost: 2.00},
    {id: 24, city: 'SALINAS', deliveryCost: 2.00},
]
