import Typography from '@mui/material/Typography';

function Text(props) {

    const {color, text, top, variant, bottom, left, size, weight, right} = props;

    return (
        <Typography variant={variant} gutterBottom
                    style={{color: color, marginTop: top, marginBottom: bottom, marginLeft: left, fontSize: size,
                    fontWeight: weight, marginRight: right}}>
            {text}
        </Typography>
    )
}

export default Text
