import Typography from "@mui/material/Typography";
import './recover.css'
import {initRecoverValues, recoverSchema} from "../../utils/YupSchemas";
import {Formik} from 'formik';
import InputForm from "../../palette/InputForm";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import GppGoodIcon from '@mui/icons-material/GppGood';
import SubmitButton from "../../palette/SubmitButton";
import {useState} from "react";
import axios from "axios";
import {recoverPasswordUrl, headers, errorMsg} from "../../utils/Const";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CustomizedSnackbars from "../../palette/SnacksBar";


const logo = 'https://firebasestorage.googleapis.com/v0/b/mazpartes-46c02.appspot.com/o/mazpartesLogo.png?alt=media&token=7540cf7d-e92a-4a50-82aa-a63ff218b35b'
const companyName = 'Mazpartes'

function Recover() {

    const [loading, setLoading] = useState(false)
    const [textButton, setTextButton] = useState('Enviar')
    const [mail, setEmail] = useState('')
    const [alert, setAlert] = useState(false)
    const [open, setOpen] = useState(false)
    const [snacks, setSnacks] = useState({message: '', type: 'success'})

    const navigate = useNavigate();

    const onSubmit = async (values) => {

        const data = {email: values.email, logo, companyName, userName: 'Cliente'}

        setLoading(true)
        setTextButton('Enviando')


        try {
            const res = await axios.post(recoverPasswordUrl, data, headers)


            if (res.data === 'OK') {
                setAlert(true)
                setEmail(data.email)
                setSnacks({...snacks, message: 'La contraseña ha sido enviada exitosamente a su correo', type: 'error'})
                setTimeout(() => {
                    setLoading(false)
                    setTextButton('Enviar')
                    setAlert(false)
                    navigate('/session')
                }, 3000)
            }

            if(res.data === 'ERROR'){
                setOpen(true)
                setLoading(false)
                setTextButton('Enviar')
                setAlert(false)
                setSnacks({...snacks, message: errorMsg, type: 'error'})
            }

            if(res.data === 'NE'){
                setOpen(true)
                setLoading(false)
                setTextButton('Enviar')
                setAlert(false)
                setSnacks({...snacks, message: 'Correo electrónico no registrado', type: 'error'})
            }

        } catch (e) {
            console.log(e)
            setLoading(false)
            setTextButton('Enviar')
            setAlert(false)
            setSnacks({...snacks, message: errorMsg, type: 'error'})
        }
    }

    return (
        <div className={'recover-main-container'}>
            <div className={'recover-container'}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography component="h2" variant="h5">
                        RECUPERAR CONTRASEÑA
                    </Typography>
                </div>

                <br/>
                <Formik
                    initialValues={initRecoverValues}
                    validationSchema={recoverSchema}
                    onSubmit={onSubmit}
                >
                    {({
                          values,
                          errors,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                      }) => {
                        return (
                            <>
                                <InputForm label="Correo electrónico" full field={'email'} errors={errors}
                                           handleChange={handleChange} values={values}
                                           handleBlur={handleBlur} top={12}
                                           maxLength={30} small
                                />

                                <div style={{
                                    display: 'flex', flexDirection: 'row', marginTop: 10, alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>

                                    <Button variant="text" startIcon={<ArrowBackIcon/>}
                                            onClick={async () => {
                                                navigate("/session")
                                            }}>Volver</Button>

                                    <SubmitButton text={textButton} type="submit" onClick={handleSubmit}
                                                  loading={loading} icon={<GppGoodIcon/>}/>

                                </div>
                            </>
                        )
                    }}
                </Formik>
                <br/>

                {alert ?
                    <Stack sx={{width: '100%'}} spacing={2}>
                        <Alert severity="success">{`Se ha enviado su nueva contraseña al correo ${mail}`}</Alert>
                    </Stack> :
                    <CustomizedSnackbars open={open} setOpen={setOpen} message={snacks.message} type={snacks.type}/>
                }

            </div>
        </div>
    )


}


export default Recover
