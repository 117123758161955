import {Formik} from 'formik';
import React, {useContext, useEffect, useState} from "react";
import {useStyles} from "../../utils/Styles";
import {sendRequestPhone, ValidarCedula, ValidateRUC} from "../../utils/ValidatesDocuments";
import {personalDataSchema, personalDataSchema_2} from "../../utils/YupSchemas";
import InputForm from "../../palette/InputForm";
import Button from "@mui/material/Button";
import Text from "../../palette/Text";
import TextField from "@mui/material/TextField";
import {Autocomplete} from "@mui/lab";
import {observer} from "mobx-react-lite";
import {StoreContext} from "../../store/Context";
import {collection, query, where, getDocs} from "firebase/firestore";
import {db} from "../../firebase/Firebase";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

function PersonalData(props) {

    const {dataStore} = useContext(StoreContext)
    const {user, personalData, clientData, directionData, deliveryData} = dataStore

    const {activeStep, handleBack, setActiveStep,} = props

    const [typeIdClient, setTypeIdClient] = useState(null)
    const [validateIdClient, setValidateIdClient] = useState(null)
    const [idClient, setIdClient] = useState('')
    const [maxLength, setMaxLength] = useState(10)
    const [validatePhoneNumber, setValidatePhoneNumber] = useState('')
    const [validatePhoneNumberError, setValidatePhoneNumberError] = useState(true)
    const [validateBusinessNumber, setValidateBusinessNumber] = useState('')
    const [validateBusinessNumberError, setValidateBusinessNumberError] = useState(true)
    const [valueTypeId, setValueTypeId] = useState(null);
    const [inputValueTypeId, setInputValueTypeId] = useState('');
    const [loading, setLoading] = useState(false)
    const [existClient, setExistClient] = useState(false)

    const classes = useStyles();

    useEffect(() => {
        setValueTypeId({typeId: personalData.typeId})
    }, [personalData])

    useEffect(() => {
        setLoading(false)
        if (typeIdClient === 'RUC') {
            setMaxLength(13)
            const result = ValidateRUC(idClient)
            setValidateIdClient(result)
        }

        if (typeIdClient === 'Cédula') {
            setMaxLength(10)
            const result = ValidarCedula(idClient)
            setValidateIdClient(result)
        }
    }, [typeIdClient, idClient])

    useEffect(() => {
        const ValidatePhone = async () => {
            if (validatePhoneNumber !== '') {
                const result = await sendRequestPhone(validatePhoneNumber, 10)
                setValidatePhoneNumberError(result)
            }

            if (validateBusinessNumber !== '') {
                // const result = await sendRequestPhone(validateBusinessNumber, 9)
                //setValidateBusinessNumberError(result)
            }

        }

        ValidatePhone().then(() => null)

    }, [validatePhoneNumber, validateBusinessNumber])


    const handlePersonalData = async (values) => {

        let exists = null

        if (personalData.idClient !== values.idClient) {
            const q = query(collection(db, "clients"), where("idClient", "==", values.idClient));

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                exists = doc.id
            })
        }

        if (exists) {
            setExistClient(true)
            return
        }

        setExistClient(false)

        const newData = {...clientData, ...values}

        newData.typeId = inputValueTypeId
        newData.businessNumber = values.businessNumber === undefined ? '' : values.businessNumber
        newData.tradeName = values.tradeName === undefined ? '' : values.tradeName
        // newData.name = values.tradeName === undefined ? '' : values.businessNumber
        // newData.tradeName = values.tradeName === undefined ? '' : values.businessNumber


        let personal;


        if (user?.type !== 'Judirico') {
            personal = {
                ...personalData, name: newData.name, lastName: newData.lastName, tradeName: '',
                email: newData.email, phoneNumber: newData.phoneNumber, typeId: newData.typeId,
                idClient: clientData.idClient, businessNumber: newData.businessNumber
            }
            const name = `${personalData.name} ${personalData.lastName}`
            dataStore.UpdateName(name)
        } else {
            personal = {
                ...personalData, name: '', lastName: '', tradeName: newData.tradeName,
                email: newData.email, phoneNumber: newData.phoneNumber, typeId: newData.typeId,
                idClient: clientData.idClient, businessNumber: newData.businessNumber
            }
            dataStore.UpdateName(newData.tradeName)
        }


        dataStore.RegisterClientModal(personal, directionData, deliveryData, newData)

        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }


    return (
        <>
            <Formik
                initialValues={personalData}
                validationSchema={user?.type !== 'Judirico' ? personalDataSchema : personalDataSchema_2}
                onSubmit={handlePersonalData}
            >
                {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                  }) => {

                    if (inputValueTypeId !== '') {
                        values.typeId = inputValueTypeId
                    }

                    setIdClient(values.idClient)
                    setValidatePhoneNumber(values.phoneNumber)
                    setValidateBusinessNumber(values.businessNumber)

                    return (
                        <>
                            {user?.type !== 'Judirico' &&
                            <InputForm label="Nombres" full field={'name'} errors={errors}
                                       handleChange={handleChange} values={values}
                                       handleBlur={handleBlur}
                                       maxLength={30} small
                            />}

                            {user?.type !== 'Judirico' &&

                            <InputForm label="Apellidos" full field={'lastName'} errors={errors}
                                       handleChange={handleChange} values={values}
                                       handleBlur={handleBlur} top={12}
                                       maxLength={30} small
                            />}

                            {user?.type === 'Judirico' &&
                            <InputForm label="Nombre comercial" full field={'tradeName'} errors={errors}
                                       handleChange={handleChange} values={values}
                                       handleBlur={handleBlur} maxLength={30} small
                            />
                            }


                            <InputForm label="Correo electrónico" full field={'email'} errors={errors}
                                       handleChange={handleChange} values={values}
                                       handleBlur={handleBlur} top={12}
                                       maxLength={30} small disabled={true}
                            />


                            <div style={{width: '100%', marginTop: 12}}>
                                <InputForm label="No. Celular" full field={'phoneNumber'}
                                           errors={errors}
                                           handleChange={handleChange} values={values}
                                           handleBlur={handleBlur} maxLength={10} small
                                />
                                {!validatePhoneNumberError &&
                                <Text text={'Número inválido'} size={10} color={'red'}/>}
                            </div>


                            <Autocomplete
                                id="combo-box"
                                name="typeId"
                                options={typeId}
                                getOptionLabel={(option) => option.typeId}
                                style={{marginTop: 15, width: '100%'}}
                                size={'small'}
                                value={valueTypeId}
                                onChange={(event, newValue) => {
                                    setValueTypeId(newValue);
                                }}
                                onInputChange={(event, newInputValue) => {
                                    setInputValueTypeId(newInputValue);
                                }}
                                inputValue={inputValueTypeId}


                                renderInput={(params) => {

                                    setTypeIdClient(params.inputProps.value)
                                    //values.typeId = params.inputProps.value
                                    //setTypeIdClient(values.typeId)
                                    return (
                                        <TextField {...params}
                                                   label="Tipo de identificacion"
                                                   variant="outlined"
                                                   name="typeId"
                                                   className={errors.typeId ? null : classes.formInput}
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   value={values.typeId}
                                                   error={!!errors.typeId}
                                                   helperText={errors.typeId}
                                                   size={'small'}
                                        />
                                    )
                                }}
                            />

                            <div style={{width: '100%', marginTop: 10}}>
                                <InputForm label="RUC/Cédula/Pasaporte" full field={'idClient'}
                                           errors={errors}
                                           handleChange={handleChange} values={values}
                                           handleBlur={handleBlur}
                                           maxLength={maxLength} small
                                />
                                {validateIdClient?.isValid === false &&
                                <Text text={validateIdClient?.error} size={10} color={'red'}/>}
                            </div>

                            <div style={{width: '100%', marginTop: 10}}>
                                <InputForm label="No. Convencional" full field={'businessNumber'}
                                           errors={errors}
                                           handleChange={handleChange} values={values}
                                           handleBlur={handleBlur} maxLength={9} small
                                />
                                {!validateBusinessNumberError &&
                                <Text text={'Número inválido'} size={10} color={'red'}/>}
                            </div>
                            {existClient &&
                            <Stack sx={{width: '100%'}} spacing={2}>
                                <div style={{height: 1}}/>
                                <Alert severity="warning">Cliente registardo!</Alert>
                            </Stack>
                            }

                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                sx={{mt: 1, mr: 1}}
                            >
                                {activeStep === 3 ? 'Finalizar' : 'Continuar'}
                            </Button>
                            <Button
                                disabled={true}
                                onClick={handleBack}
                                sx={{mt: 1, mr: 1}}
                            >
                                Atras
                            </Button>
                        </>
                    )
                }
                }
            </Formik>
        </>
    )

}

export default observer(PersonalData)

const typeId = [
    {typeId: 'RUC', ID: '1'},
    {typeId: 'Cédula', ID: '2'},
    {typeId: 'Pasaporte', ID: '3'},
]
