import {Formik} from 'formik';
import React, {useContext, useEffect, useState} from "react";
import {useStyles} from "../../utils/Styles";
import {clientDirectionSchema_1} from "../../utils/YupSchemas";
import InputForm from "../../palette/InputForm";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {Autocomplete} from "@mui/lab";
import {cities, province} from "../../utils/ProvincieCities";
import {Filters} from "../../utils/HelpFunctions";
import {observer} from "mobx-react-lite";
import {StoreContext} from "../../store/Context";
import {useNavigate} from "react-router-dom";
import {doc, setDoc} from "firebase/firestore";
import {db} from "../../firebase/Firebase";
import {values} from "mobx";
import CustomizedSnackbars from "../../palette/SnacksBar";

function ClientDirection(props) {

    const {dataStore} = useContext(StoreContext)
    const {user, personalData, clientData, directionData, deliveryData} = dataStore


    const {setActiveStep, checked, setOpenModalRegister} = props

    const classes = useStyles();

    const [valueProvinces, setValueProvince] = useState(null);
    const [inputValueProvinces, setInputValueProvinces] = useState('');
    const [provinceSelect, setProvinceSelect] = useState('')
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [citiesArr, setCitiesArr] = useState([])
    const [open, setOpen] = useState(false);
    const [snacks, setSnacks] = useState({message: '', type: 'success'})
    const [textButton, setTextButton] = useState('Continuar')
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();


    useEffect(() => {
        setValueProvince({province: directionData.province})
        setValue({city: directionData.city})
    }, [directionData])


    useEffect(() => {
        let isMounted = true;

        setCitiesArr([])

        if (provinceSelect !== '') {
            provinceSelect !== directionData.province && setInputValue('')
            provinceSelect !== directionData.province && setValue(null)
        }

        const filterProvince = Filters(province, 'province', provinceSelect)
        const id = filterProvince[0]?.id
        const filtered = Filters(cities, 'id', parseInt(id))
        isMounted && setCitiesArr(filtered)

        return () => {
            isMounted = false
        }
    }, [provinceSelect, directionData])


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const handleDirectionData = async (values) => {

        const uid = user.uid

        console.log(values)

        if (inputValueProvinces === '') {
            setOpen(true)
            setSnacks({...snacks, message: 'Verifique el campo provincia!', type: 'warning'})
            return
        }

        if (inputValue === '') {
            setOpen(true)
            setSnacks({...snacks, message: 'Verifique el campo ciudad!', type: 'warning'})
            return
        }

        const direction = {
            ...directionData, province: inputValueProvinces, city: inputValue,
            mainStreet: values.mainStreet, secondaryStreet: values.secondaryStreet,
            houseNumber: values.houseNumber, reference: values.reference
        }

        const newData = {...clientData, ...direction,}


        if (deliveryData.deliveryMainStreet) {
            dataStore.RegisterClientModal(personalData, direction, deliveryData, newData)
        } else {
            const delivery = {
                ...deliveryData, deliveryMainStreet: values.mainStreet, deliverySecondaryStreet: values.secondaryStreet,
                deliveryHouseNumber: values.houseNumber, deliveryReference: values.reference
            }
            dataStore.RegisterClientModal(personalData, direction, delivery, newData)
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1)

        setTextButton('Finalizando')
        setLoading(true)

        if (!checked) {
            try {
                await setDoc(doc(db, "clients", uid), newData);
                setActiveStep(0)
                setOpenModalRegister(false)
                navigate('/order')
                setTextButton('Finalizar')
            } catch (e) {
                setOpen(true)
                setSnacks({...snacks, message: 'Error inesperado intentolo otra vez!', type: 'error'})
                setTextButton('Finalizar')
                setLoading(false)
            }
        }else{

        }

    }


    return (
        <>
            <Formik
                initialValues={directionData}
                validationSchema={clientDirectionSchema_1}
                onSubmit={handleDirectionData}
            >
                {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                  }) => {

                    /*if (inputValueProvinces !== '') {
                        values.province = inputValueProvinces
                    }

                    if (inputValue !== '') {
                        values.city = inputValue
                    }*/

                    return (

                        <>
                            <Autocomplete
                                id="combo-box"
                                name="province"
                                options={province}
                                getOptionLabel={(option) => option.province}
                                style={{marginTop: 7, width: '100%'}}
                                size={'small'}
                                value={valueProvinces}
                                onChange={(event, newValue) => {
                                    setValueProvince(newValue);
                                }}
                                onInputChange={(event, newInputValue) => {
                                    setInputValueProvinces(newInputValue);
                                }}
                                inputValue={inputValueProvinces}
                                renderInput={(params) => {

                                    setProvinceSelect(params.inputProps.value)

                                    return (
                                        <TextField {...params}
                                                   label="Provincia"
                                                   variant="outlined"
                                                   name="province"
                                                   className={errors.province ? null : classes.formInput}
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   value={values.province}
                                                   error={!!errors.province}
                                                   helperText={errors.province}
                                                   size={'small'}
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                        />
                                    )
                                }}
                            />

                            <Autocomplete
                                id="combo-box"
                                name="cities"
                                options={citiesArr}
                                getOptionLabel={(option) => option.city}
                                noOptionsText={'Escoja una provincia'}
                                style={{marginTop: 10, width: '100%'}}
                                size={'small'}
                                value={value}
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                inputValue={inputValue}

                                renderInput={(params) => {

                                    //values.city = params.inputProps.value
                                    //setCitySelect(values.city)

                                    return (
                                        <TextField {...params}
                                                   label="Ciudad"
                                                   variant="outlined"
                                                   name="city"
                                                   className={errors.city ? null : classes.formInput}
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   value={values.city}
                                                   error={!!errors.city}
                                                   helperText={errors.city}
                                                   size={'small'}
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                        />
                                    )
                                }
                                }
                            />

                            <InputForm label="Calle principal" full field={'mainStreet'} errors={errors}
                                       handleChange={handleChange} values={values} top={15}
                                       handleBlur={handleBlur}
                                       maxLength={30} width={'100%'} small
                            />
                            <InputForm label="No. de casa/Dpto" full field={'houseNumber'}
                                       errors={errors} top={15}
                                       handleChange={handleChange} values={values}
                                       handleBlur={handleBlur}
                                       maxLength={5} width={'100%'} small
                            />

                            <InputForm label="Calle secundaria" full field={'secondaryStreet'}
                                       errors={errors} top={10}
                                       handleChange={handleChange} values={values}
                                       handleBlur={handleBlur}
                                       maxLength={30} width={'100%'} small
                            />
                            <InputForm label="Referencia" full field={'reference'} errors={errors}
                                       handleChange={handleChange} values={values}
                                       handleBlur={handleBlur} top={10}
                                       maxLength={50} width={'100%'} small
                            />
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                sx={{mt: 1, mr: 1}}
                            >
                                {!checked ? 'Finalizar' : textButton}
                            </Button>
                            {!checked ?
                                <Button
                                    disabled={checked}
                                    onClick={handleBack}
                                    sx={{mt: 1, mr: 1}}
                                >
                                    Atras
                                </Button> :
                                <Button
                                    onClick={handleBack}
                                    sx={{mt: 1, mr: 1}}
                                >
                                    Atras
                                </Button>

                            }

                        </>
                    )
                }
                }
            </Formik>

            <CustomizedSnackbars open={open} setOpen={setOpen} message={snacks.message} type={snacks.type}/>
        </>
    )

}

export default observer(ClientDirection)
