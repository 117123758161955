import './main.css'
import {useContext, useEffect, useState} from "react";
import {StoreContext} from "../../../store/Context";
import {observer} from "mobx-react-lite";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {FilterDelete, Filters, Order, UpdateArray} from "../../../utils/HelpFunctions"; // requires a loader
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../utils/useLocalStorage";
import {collection, query, where, getDocs, limit, doc, getDoc,} from "firebase/firestore";
import {auth, db} from "../../../firebase/Firebase";
import usePagination from "./Pagination";
import Pagination from '@mui/material/Pagination';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import {onAuthStateChanged} from "firebase/auth";
import MenuIcon from "@mui/icons-material/Menu";
import {Snackbar, useMediaQuery} from "@mui/material";

const vertical = 'button'
const horizontal = 'right'

function MainProducts(props) {

    const {dataStore, filtersStore} = useContext(StoreContext)
    const {
        mainProducts, filterData, showNoStock, loadingProducts, user, shoppingCart, controlStores,
        globalFilter, globalFilterProducts, ivaValue
    } = dataStore

    const {setCollapsed, collapsed, triggerProd} = props

    const [cod, setCod] = useLocalStorage("cod", "");
    const [schema, setSchema] = useLocalStorage("schema", "");
    const [dis, setDis] = useLocalStorage("dis", "");
    const [realPrice, setRealPrice] = useLocalStorage("realPrice", "");
    const [price, setPrice] = useLocalStorage("price", "");
    const [newPrice, setNewPrice] = useLocalStorage("newPrice", "");
    const [discounts, setDiscounts] = useState([])
    const [checked, setChecked] = useState(showNoStock)
    const [complete, setComplete] = useState(false)
    const [products, setProducts] = useState([])
    const [clientDiscountReload, setClientDiscountReload] = useState(null)
    const [amount, setAmount] = useState(0);
    const [open, setOpen] = useState(false);


    const typeClient = user?.typeClient

    const navigate = useNavigate();
    let [page, setPage] = useState(1);

    useEffect(() => {

        const Discount = () => {


            let key


            if (typeClient?.toLowerCase() === 'final') {
                key = 'final'
            }

            if (typeClient?.toLowerCase() === 'taller') {
                key = 'workshop'
            }

            if (typeClient?.toLowerCase() === 'almacén') {
                key = 'warehouse'
            }

            if (typeClient?.toLowerCase() === 'distribuidor') {
                key = 'distributor'
            }

            let arr = []


            mainProducts?.map(async (product, i) => {
                const q = query(collection(db, "manufacturers"), where("manufacturers", "==", product.manufacturers))

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    arr.push(doc.data())
                })

                if (arr.length === mainProducts.length) {

                    setComplete(true)

                    if (user) {
                        const discount = user?.discount


                        arr.forEach(items => {
                            discount?.forEach(data => {
                                if (data.manufacturers === items.manufacturers) {
                                    const sum = parseFloat(items[key]) + parseFloat(data.discounts)
                                    items[key] = sum.toString()
                                }
                            })
                        })

                        setDiscounts(arr)

                    } else {
                        setDiscounts(arr)
                    }
                }
            })
        }

        Discount()

    }, [user, mainProducts, showNoStock])

    useEffect(() => {

        if (user) {

            if (complete) {

                let key

                if (typeClient?.toLowerCase() === 'final') {
                    key = 'final'
                }

                if (typeClient?.toLowerCase() === 'taller') {
                    key = 'workshop'
                }

                if (typeClient?.toLowerCase() === 'almacén') {
                    key = 'warehouse'
                }

                if (typeClient?.toLowerCase() === 'distribuidor') {
                    key = 'distributor'
                }


                mainProducts?.forEach((product) => {
                    discounts?.forEach(items => {
                        if (product.manufacturers === items.manufacturers) {
                            product['discount'] = items[key]
                        }
                    })
                })

                setProducts(mainProducts)
            }
        } else {
            setProducts(mainProducts)
        }

    }, [complete, user, typeClient, discounts])

    useEffect(() => {
        setChecked(showNoStock)
    }, [showNoStock, triggerProd])

    useEffect(() => {

        try {
            onAuthStateChanged(auth, async (user) => {
                if (user) {
                    const uid = user.uid;
                    const docRef = doc(db, "clients", uid);
                    const docSnap = await getDoc(docRef);
                    const city = docSnap.data()?.city ? docSnap.data()?.city : null
                    const type = docSnap.data()?.type
                    const purchaseQuota = docSnap.data()?.purchaseQuota
                    const paymentDeadline = docSnap.data()?.paymentDeadline
                    const phoneNumber = docSnap.data()?.phoneNumber
                    const email = docSnap.data()?.email
                    const idClient = docSnap.data()?.idClient
                    const deliveryCity = docSnap.data()?.deliveryCity
                    let displayName = type === 'Judirico' ? docSnap.data().tradeName : `${docSnap.data()?.name}  ${docSnap.data()?.lastName}`;
                    const discount = docSnap.data()?.discount
                    const typeClient = docSnap.data()?.typeClient
                    setClientDiscountReload(discount)
                    dataStore.User({
                        uid, displayName, typeClient, discount, city, paymentDeadline, purchaseQuota,
                        deliveryCity, type, idClient, phoneNumber, email
                    })

                } else {
                    dataStore.User(null)
                }
            });

        } catch (e) {

        }
    }, [])

    const PER_PAGE = 10;

    const count = products?.length > 0 && Math.ceil(user ? products?.length / PER_PAGE : mainProducts.length / PER_PAGE);
    const _DATA = usePagination(user ? products : mainProducts, PER_PAGE);
    const matches = useMediaQuery('(min-width:768px)');
    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };

    const handleCheckBox = async (value) => {

        setChecked(value)

        if (globalFilter) {
            let filterStock;
            if (value) {
                filterStock = globalFilterProducts
            } else {
                filterStock = FilterDelete(globalFilterProducts, 'show', showNoStock)
            }
            dataStore.MainProducts(filterStock)
            return
        }

        if (!value) {
            const q = query(collection(db, "products"), where("status", "==", 'Activo'), where("stock", ">", 0),
                limit(10));
            const querySnapshot = await getDocs(q);
            const arr = []

            querySnapshot.forEach((doc) => {
                let stock = doc.data().stores.reduce(function (prev, cur) {
                    return prev + cur.stock;
                }, 0);

                arr.push({...doc.data(), docId: doc.id, stock, show: stock > 0})
            })

            const order = Order(arr, 'name')


            const {vehicles, models, qYear, manufacturers, schema, groups, subGroup, schemaArr} = filterData

            if (vehicles === '' && models === '' && qYear === '' && manufacturers === '' && schema === ''
                && groups === false && subGroup === false && schemaArr.length === 0) {
                dataStore.MainProducts(order, false)
                dataStore.ShowNoStock(value)
            } else {
                await dataStore.FilterData(filterData, false)
                dataStore.ShowNoStock(value)
            }

        } else {
            const q = query(collection(db, "products"), where("status", "==", 'Activo'),
                limit(10));
            const querySnapshot = await getDocs(q);
            const arr = []
            querySnapshot.forEach((doc) => {
                let stock = doc.data().stores.reduce(function (prev, cur) {
                    return prev + cur.stock;
                }, 0);

                arr.push({...doc.data(), docId: doc.id, stock, show: stock > 0})
            })

            const order = Order(arr, 'name')

            const {vehicles, models, qYear, manufacturers, schema, groups, subGroup, schemaArr} = filterData

            if (vehicles === '' && models === '' && qYear === '' && manufacturers === '' && schema === ''
                && groups === false && subGroup === false && schemaArr.length === 0) {
                dataStore.MainProducts(order, false)
            } else {
                await dataStore.FilterData(filterData, true)
            }


        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddCart = async (product, i, newPrice) => {

        onAuthStateChanged(auth, async (user) => {
            if (user && clientDiscountReload) {

                const cartArr = [...shoppingCart]

                let stores;


                if (controlStores) {
                    const filter = Filters(cartArr, 'cod', product.cod)
                    if (filter.length > 0) {
                        stores = controlStores
                    } else {
                        stores = Order(product.stores, 'order')
                    }

                } else {
                    stores = Order(product.stores, 'order')
                }


                let s = 0
                let cartNum = 0
                let q = 1
                let q_2 = 1
                let control = true


                const filterClientDiscount = Filters(clientDiscountReload, 'manufacturers', product?.manufacturers)
                const clientDis = filterClientDiscount.length > 0 ? filterClientDiscount[0].discounts : 0
                const disProd = product.discount - parseFloat(clientDis)


                const {cod, name, manufacturers, imagesArray, iva} = product

                const objCart = {
                    cod, name, manufacturers, price, newPrice, disProd, clientDis, iva,
                    image: imagesArray[0]
                }

                let filterStore = FilterDelete(stores, 'stock', 0)
                const storeName = filterStore[0].name


                let value = 0

                if (cartArr.length > 0) {

                    const stock = filterStore[0].stock


                    if (stock === q_2) {
                        q_2 = 0
                        const update = UpdateArray(stores, 'name', storeName, 'stock', 0)
                        dataStore.ControlStores(update)
                    }

                    if (stock > q) {
                        q_2 = stock - q_2
                        const update = UpdateArray(stores, 'name', storeName, 'stock', q_2)
                        dataStore.ControlStores(update)
                    }

                    if (stock < q_2) {
                        q_2 = stock - q_2
                        let val = q_2 < 0 ? 0 : q_2
                        const update = UpdateArray(stores, 'name', storeName, 'stock', val)
                        dataStore.ControlStores(update)
                    }


                } else {
                    const stock = filterStore[0].stock
                    if (stock === q_2) {
                        const update = UpdateArray(stores, 'name', storeName, 'stock', 0)
                        dataStore.ControlStores(update)
                    }

                    if (stock > q_2) {
                        q_2 = stock - q_2
                        const update = UpdateArray(stores, 'name', storeName, 'stock', q_2)
                        dataStore.ControlStores(update)
                    }

                    if (stock < q_2) {
                        q_2 = stock - q_2
                        let val = q_2 < 0 ? 0 : q_2
                        const update = UpdateArray(stores, 'name', storeName, 'stock', val)
                        dataStore.ControlStores(update)
                    }
                }


                filterStore.forEach(items => {

                    const stock = items.stock

                    if (control) {
                        if (q >= stock) {
                            if (stock > 0) {
                                s = stock
                                q = q - stock
                                cartNum = s + cartNum
                                const total = s * newPrice.toFixed(2)
                                cartArr.push({
                                    ...objCart, total, storage: items.name, amount: s, location: items.location,
                                    direction: items.direction, realPrice: items.price
                                })
                            }

                        } else {
                            s = stock - q
                            cartNum = s + cartNum
                            if (s > 0) {
                                control = false
                            }

                            const val = value === 1 ? value : q
                            const total = val * newPrice.toFixed(2)
                            cartArr.push({
                                ...objCart,
                                total,
                                storage: items.name,
                                amount: value === 1 ? value : q,
                                direction: items.direction,
                                realPrice: items.price,
                                location: items.location
                            })
                        }
                    }
                })


                let result = Object.values(cartArr.reduce(function (r, e) {
                    let key = e.cod + '|' + e.storage + '|' + e.direction;
                    if (!r[key]) r[key] = e;
                    else {
                        r[key].amount += e.amount;
                    }
                    return r;
                }, {}))

                let filterStock = FilterDelete(result, 'amount', 0)

                const filterProd = Filters(filterStock, 'cod', cod)

                let amount = filterProd.reduce(function (prev, cur) {
                    return prev + cur.amount;
                }, 0);


                if (product.stock === amount) {
                    const update = UpdateArray(mainProducts, 'cod', cod, 'mainCart', true)
                    dataStore.MainProducts(update)
                }
                setAmount(amount)
                setOpen(true);
                dataStore.ShoppingCart(filterStock)

            } else {
                navigate('/session')
            }
        })

    }

    const handlerCopy = (e) => {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
    }

    return (
        <div>
            <div style={{
                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between',

            }}>

                {(!matches && !collapsed) &&
                <div style={{
                    display: 'flex', alignItems: 'center', height: 40,
                }}>
                    <IconButton aria-label="delete" onClick={() => setCollapsed(!collapsed)}>
                        <MenuIcon/>
                    </IconButton>
                </div>}


                {(!collapsed && matches) &&
                <div style={{
                    display: 'flex', alignItems: 'center', height: 40,
                }}>
                    <IconButton aria-label="delete" onClick={() => setCollapsed(!collapsed)}>
                        <MenuIcon/>
                    </IconButton>
                </div>}
                {collapsed && <div/>}

                <div style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                    height: 40
                }}>
                    <Checkbox checked={checked} onChange={e => handleCheckBox(e.target.checked)}/>
                    <span style={{marginRight: 15}}>Mostrar productos sin stock</span>
                </div>
            </div>

            {products.length === 0 ?

                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={loadingProducts}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}>
                        <CircularProgress color="inherit"/>
                        <span style={{color: '#fff', fontWeight: '800'}}>Buscando...</span>
                    </div>
                </Backdrop> :

                <div>
                    {mainProducts?.length > 0 ?
                        <div className={mainProducts >= 3 ? "main-container" : 'main-container_fill'}>
                            {_DATA?.currentData().map((product, index) => {


                                const brandsModel = product.brandModels[0]

                                //const { models, initYear, endYear, motor, cylinder, observation} = brandsModel

                                const vehicles = brandsModel?.vehicles === undefined ? '' : brandsModel?.vehicles
                                const models = brandsModel?.models === undefined ? '' : brandsModel?.models
                                const initYear = brandsModel?.initYear === undefined ? '' : brandsModel?.initYear
                                const endYear = brandsModel?.endYear === undefined ? '' : brandsModel?.endYear
                                const motor = brandsModel?.motor === undefined ? '' : brandsModel?.motor
                                const cylinder = brandsModel?.cylinder === undefined ? '' : brandsModel?.cylinder
                                const observation = brandsModel?.observation === undefined ? '' : brandsModel?.observation

                                const discount = Filters(discounts, 'manufacturers', product.manufacturers)

                                let dis;

                                if (user) {
                                    dis = product.discount
                                } else {
                                    dis = parseFloat(discount[0]?.final)
                                }


                                const mainCart = product.mainCart
                                const stores = product.stores
                                const filters = Filters(stores, 'codProd', product.cod)

                                const orderStore = Order(filters, 'order')


                                const realPrice = orderStore[0]?.price
                                const iva = ivaValue / 100
                                const r = orderStore[0]?.price * iva


                                const price = orderStore[0]?.price + r
                                const np = price * (dis / 100)
                                const newPrice = price - np

                                let stock = orderStore.reduce(function (prev, cur) {
                                    return prev + cur.stock;
                                }, 0);


                                return (
                                    <div className={'products-container'} key={index}>
                                        {(dis > 0) &&

                                        <div className={'discount-container'}>
                                                <span style={{color: 'white', fontSize: 16}}>
                                                {user ? `${dis}%` : `${discount[0]?.final}%`}
                                             </span>
                                        </div>}

                                        <div className={'hack'}/>
                                        <div className={'hack_2'}/>
                                        <div className={'hack_3'}/>

                                        <div style={{position: 'relative'}}>
                                            <Carousel
                                                showArrows
                                                dynamicHeight
                                                // autoPlay
                                                //infiniteLoop
                                                showIndicators
                                                swipeScrollTolerance={100}
                                                showThumbs={false}
                                            >

                                                {product.imagesArray.map((image, i) => {
                                                    return (
                                                        <div key={i}>
                                                            <img src={image} alt={''}
                                                                 style={{borderRadius: 3, height: 350}}/>
                                                            <p className="legend"
                                                               onClick={async () => {
                                                                   filtersStore.BackHome(true)
                                                                   setCod(product.cod)
                                                                   setSchema(product.schema)
                                                                   setDis(dis)
                                                                   setNewPrice(newPrice)
                                                                   setPrice(price)
                                                                   setRealPrice(realPrice)
                                                                   await dataStore.GetProduct(product, product.cod, dis, realPrice, newPrice, price, user?.discount)
                                                                   await dataStore.GetSchema(product.schema)
                                                                   dataStore.BackDetailHome(false)
                                                                   dataStore.ControlStores(product.stores)
                                                                   navigate('/product-details')
                                                               }}>{product.name}</p>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </Carousel>
                                        </div>
                                        <div className="description-container" onCopy={(e) => handlerCopy(e)}>
                                            <div style={styles.spanRow}>
                                                <span>{product.name}</span>
                                                <span>{product.cod}</span>
                                            </div>
                                            <div style={styles.spanRow}>
                                     <span style={{
                                         color: 'gray',
                                         fontWeight: '600'
                                     }}>{`Fabricante: ${product.manufacturers}`}</span>
                                                <span style={{
                                                    color: 'gray',
                                                    fontWeight: '600'
                                                }}>{`Unidad: ${product.unitWeight}`}</span>
                                            </div>
                                            <div style={styles.spanRow}>
                                    <span style={{fontWeight: '600', color: '#333'}}>
                                        {`${vehicles}  ${models}  ${initYear} - ${endYear}`}
                                    </span>
                                            </div>
                                            <div style={styles.spanRow}>
                                    <span style={{fontWeight: '600', color: '#333'}}>
                                        {`Motor: ${motor}  Cilindraje: ${cylinder}  Observacion: ${observation}`}
                                    </span>
                                            </div>

                                            <div style={{height: 5}}/>

                                            <div style={styles.spanRow}>

                                                {dis > 0 ?
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center'
                                                    }}>
                                             <span style={{
                                                 fontWeight: 'bold', color: '#EF5350', fontSize: 16,
                                                 textDecoration: 'line-through'
                                             }}>
                                                   {`$ ${price.toFixed(2)}`}
                                             </span>
                                                        <span style={{
                                                            fontWeight: 'bold', color: '#0087CB', fontSize: 16,
                                                            marginLeft: 15
                                                        }}>
                                                      {`$ ${newPrice.toFixed(2)}`}
                                             </span>

                                                    </div> :

                                                    <span style={{fontWeight: 'bold', color: '#0087CB', fontSize: 16}}>
                                                   {`$ ${price.toFixed(2)}`}
                                             </span>
                                                }


                                                <span style={{fontWeight: '500', color: '#333'}}>
                                        {`Incluye iva`}
                                    </span>
                                            </div>

                                            <div style={styles.spanRow}>
                                                {stock > 0 ?
                                                    <span style={{fontWeight: '700', color: 'green', fontSize: 12}}>
                                       En stock
                                    </span> :
                                                    <span style={{fontWeight: '700', color: 'gray', fontSize: 12}}>
                                      Sin stock
                                    </span>
                                                }


                                                <IconButton aria-label="add"
                                                            disabled={mainCart}
                                                            onClick={() => handleAddCart(product, index, newPrice)}
                                                >
                                                    <AddShoppingCartIcon/>
                                                </IconButton>


                                            </div>

                                        </div>
                                    </div>
                                )
                            })}
                        </div> :

                        null
                    }


                    <br/>
                    {mainProducts &&
                    <Pagination
                        count={count}
                        size="large"
                        page={page}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                    />}
                </div>
            }

            <br/>
            <Snackbar
                anchorOrigin={{vertical, horizontal}} open={open} autoHideDuration={2000} key={vertical + horizontal}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="info" sx={{width: '100%'}}>
                    Agregado al carrito!
                </Alert>
            </Snackbar>
            {mainProducts?.length === 0  &&
            <Stack sx={{width: '100%'}} spacing={2}>
                <Alert severity="info">No te desanimes si no encontraste lo que buscabas, contáctanos
                    directamente para asesorarte.!</Alert>
                <div style={{height: 10}}/>
            </Stack>}
        </div>
    )
}

export default observer(MainProducts)

const styles = {
    spanRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',

    }

}
