import './footer.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import IconButton from '@mui/material/IconButton';
import {Link} from "react-router-dom";
import React, {useState} from "react";
import mc from '../../../assets/images/pngwing.com.png'
import visa from '../../../assets/images/visa.png'
import ae from '../../../assets/images/american-express.png'
import discover from '../../../assets/images/discover.png'
import dinners from '../../../assets/images/pngegg.png'
import alia from '../../../assets/images/alia-tarjetas.jpeg'
import {CSSTransition} from 'react-transition-group';
import Button from '@mui/material/Button';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';

function Footer() {

    const [payments, setPayments] = useState(false)
    const [showMessage, setShowMessage] = useState(false);
    const matches = useMediaQuery('(min-width:768px)');


    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <div>
            {matches ?
                <div className={'footer-container'}>
                    <div className={'details-footer-container'}
                    >
                        <div style={{
                            height: payments || showMessage ? 250 : 50, display: 'flex',
                            justifyContent: (payments || showMessage) && 'flex-start'
                        }}>

                            <Button style={{height: 30}} onClick={() => setShowMessage(!showMessage)}
                                    className={'button-contacts'}
                                    endIcon={showMessage ? <ExpandLessIcon style={{color: 'white'}}/>
                                        : <ExpandMoreIcon style={{color: 'white'}}/>}>
                                <span style={{color: 'white'}}>CONTACTANOS</span>
                            </Button>


                            <div style={{
                                marginLeft: 10, display: 'flex', flexDirection: 'column',
                            }}>
                                <CSSTransition
                                    in={showMessage}
                                    timeout={0}
                                    classNames="alert"
                                    unmountOnExit
                                >
                                    <div className={'contacts'} style={{
                                        display: 'flex', flexDirection: 'column', marginTop: 10
                                    }}>
                                        <span style={{color: 'white', fontWeight: 'bold'}}>LINEA DIRECTA NACIONAL: 4701800</span>
                                        <div style={{height: 15}}/>
                                        <span style={{color: 'yellow', fontWeight: 'bold'}}>MAZPARTES AV. EL REY</span>
                                        <span style={{color: '#E0E0E0',}}>AV. EL REY Y TELIGOTE</span>
                                        <span style={{color: '#E0E0E0',}}>0984096682 - 0958843294</span>
                                        <span style={{color: '#E0E0E0',}}>mazpartes@hotmail.com</span>
                                        <span style={{color: '#E0E0E0',}}>Ambato - Ecuador</span>
                                        <div style={{height: 15}}/>
                                        <span style={{color: 'yellow', fontWeight: 'bold', marginTop: 5}}>MAZPARTES SUR</span>
                                        <span style={{color: '#E0E0E0',}}>ATAHUALPA VILLACRES Y HUMBERTO MATA</span>
                                        <span style={{color: '#E0E0E0',}}>0983012006</span>
                                        <span style={{color: '#E0E0E0',}}>mazpartes@gmail.com</span>
                                        <span style={{color: '#E0E0E0',}}>Ambato - Ecuador</span>
                                    </div>

                                </CSSTransition>
                                <div style={{height: 15}}/>
                                {/*<span style={{color: '#E0E0E0',}}>Envios y despachos</span>*/}
                            </div>
                        </div>
                    </div>

                    <div className={'details-footer-container'} style={{
                        height: payments || showMessage ? 250 : 50, display: 'flex',
                        justifyContent: (payments || showMessage) && 'flex-start'
                    }}>
                        <div>
                            <Button style={{height: 30}} onClick={() => setPayments(!payments)}
                                    className={'button-contacts'}
                                    endIcon={payments ? <ExpandLessIcon style={{color: 'white'}}/>
                                        : <ExpandMoreIcon style={{color: 'white'}}/>}>
                                <span style={{color: 'white'}}>FORMAS DE PAGO</span>
                            </Button>

                            <CSSTransition
                                in={payments}
                                timeout={0}
                                classNames="alert"
                                unmountOnExit
                            >
                                <div className={'contacts'} style={{
                                    display: 'flex', flexDirection: 'column',
                                }}>
                                    <div style={styles.rowContainer_2}>
                                        <img alt="" src={mc} width="100" height="70"/>
                                        <img alt="" src={visa} width="70" height="60"/>
                                    </div>
                                    <div style={{height: 15}}/>
                                    <span style={{color: 'white', marginLeft: 10}}>Transferencia o depósito</span>

                                    <div style={{display: 'flex', flexDirection: 'column',}}>
                                    <span style={{
                                        color: '#fdfdfd',
                                        marginLeft: 10
                                    }}>Cuenta corriente Banco Pichincha</span>
                                        <span style={{color: 'yellow', marginLeft: 10}}># 2100024507</span>
                                        <span style={{color: '#fdfdfd', marginLeft: 10, fontWeight: '500'}}>Miranda Nuñez Hernan Vladimir</span>
                                    </div>
                                </div>
                            </CSSTransition>

                        </div>
                    </div>


                    <div className={'details-footer-container'} style={{
                        height: payments || showMessage ? 250 : 50, display: 'flex',
                        justifyContent: (payments || showMessage) && 'flex-start'
                    }}>

                        <div style={{display: 'flex', flexDirection: 'row',}}>

                            <Link target="_blank" to={"/terms-conditions"} style={{color: 'white', marginRight: 10}}>
                                TERMINOS Y CONDICIONES
                            </Link>


                            <div style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'center',
                                flexDirection: 'column',
                            }}>
                                <span style={{color: 'white', marginLeft: 10}}>Siguenos en</span>
                                <IconButton aria-label="delete"
                                            onClick={() => openInNewTab('https://www.facebook.com/Mazpartes')}>
                                    <FacebookIcon style={{color: '#0B83ED'}}/>
                                </IconButton>
                            </div>
                        </div>

                    </div>
                </div> :
                <div>
                    <div className={'details-footer-container'}>


                        <Button style={{height: 30}} onClick={() => setShowMessage(!showMessage)}
                                className={'button-contacts'}
                                endIcon={showMessage ? <ExpandLessIcon style={{color: 'white'}}/>
                                    : <ExpandMoreIcon style={{color: 'white'}}/>}>
                            <span style={{color: 'white'}}>CONTACTANOS</span>
                        </Button>


                        <div style={{
                            marginLeft: 10, display: 'flex', flexDirection: 'column',
                        }}>
                            <CSSTransition
                                in={showMessage}
                                timeout={0}
                                classNames="alert"
                                unmountOnExit
                            >
                                <div className={'contacts'} style={{
                                    display: 'flex', flexDirection: 'column', marginTop: 10
                                }}>
                                    <span style={{color: 'yellow', fontWeight: 'bold'}}>MAZPARTES SUR</span>
                                    <span style={{color: '#E0E0E0',}}>ATAHUALPA VILLACRES Y HUMBERTO MATA</span>
                                    <span style={{color: '#E0E0E0',}}>0961614642</span>
                                    <span style={{color: '#E0E0E0',}}>mazpartes@gmail.com</span>
                                    <span style={{color: '#E0E0E0',}}>Ambato - Ecuador</span>
                                    <div style={{height: 15}}/>
                                    <span style={{color: 'yellow', fontWeight: 'bold'}}>MAZPARTES AV. EL REY</span>
                                    <span style={{color: '#E0E0E0',}}>AV. EL REY Y TELIGOTE</span>
                                    <span style={{color: '#E0E0E0',}}>032521155 - 0984096682</span>
                                    <span style={{color: '#E0E0E0',}}>mazpartes@hotmail.com</span>
                                    <span style={{color: '#E0E0E0',}}>Ambato - Ecuador</span>
                                </div>

                            </CSSTransition>
                            <div style={{height: 15}}/>
                            {/*<span style={{color: '#E0E0E0',}}>Envios y despachos</span>*/}
                        </div>

                    </div>

                    <div className={'details-footer-container'}>
                        <div>
                            <Button style={{height: 30}} onClick={() => setPayments(!payments)}
                                    className={'button-contacts'}
                                    endIcon={payments ? <ExpandLessIcon style={{color: 'white'}}/>
                                        : <ExpandMoreIcon style={{color: 'white'}}/>}>
                                <span style={{color: 'white'}}>FORMAS DE PAGO</span>
                            </Button>

                            <CSSTransition
                                in={payments}
                                timeout={0}
                                classNames="alert"
                                unmountOnExit
                            >
                                <div className={'contacts'} style={{
                                    display: 'flex', flexDirection: 'column',
                                }}>
                                    <div style={styles.rowContainer_2}>
                                        <img alt="" src={mc} width="100" height="70"/>
                                        <img alt="" src={visa} width="70" height="60"/>
                                    </div>
                                    <div style={{height: 15}}/>
                                    <span style={{color: 'white', marginLeft: 10}}>Transferencia o depósito</span>

                                    <div style={{display: 'flex', flexDirection: 'column',}}>
                                    <span style={{
                                        color: '#fdfdfd',
                                        marginLeft: 10
                                    }}>Cuenta corriente Banco Pichincha</span>
                                        <span style={{color: 'yellow', marginLeft: 10}}># 2100024507</span>
                                        <span style={{color: '#fdfdfd', marginLeft: 10, fontWeight: '500'}}>Miranda Nuñez Hernan Vladimir</span>
                                    </div>
                                </div>
                            </CSSTransition>Nu

                        </div>
                    </div>

                    <div className={'details-footer-container'}>
                        <Link target="_blank" to={"/terms-conditions"} style={{color: 'white', marginLeft: 10}}>
                            TERMINOS Y CONDICIONES
                        </Link>

                        <span style={{color: 'white', marginLeft: 10}}>Siguenos en</span>
                        <IconButton aria-label="delete"
                                    onClick={() => openInNewTab('https://www.facebook.com/Mazpartes')}>
                            <FacebookIcon style={{color: '#0B83ED'}}/>
                        </IconButton>

                    </div>


                </div>
            }

            <div style={{
                display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center',
                justifyContent: 'center', background: '#282A36', height: 30, marginTop: 15
            }}>
                <span style={{color: 'white', fontSize: 11, fontWeight: '500'}}>© Mazpartes 2022 Todos los derechos reservados</span>
            </div>
        </div>
    )
}

export default Footer

const styles =
    {
        rowContainer: {
            display: 'flex',
            flexDirection:
                'row',
            width: '100%',
            // alignItems: 'center',
        }
        ,

        rowContainer_2: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            //marginTop: 5
        }
    }
