import Button from '@mui/material/Button';
import {useStyles} from "../utils/Styles";
import CancelIcon from '@mui/icons-material/Cancel';

function CancelButton(props) {

    const {loading, onClick} = props

    const classes = useStyles();

    return (
        <Button className={classes.cancelButton} disabled={loading} onClick={onClick}
        style={{ color: '#FFF',
            fontSize: 14,
            marginTop: 10,
            height: 35,
            fontWeight: '500',
            cursor: 'pointer',
            marginRight: 15,
            background: '#757575',}}>
            <CancelIcon size={15} style={{marginRight: 7, }}/>
            Cancelar
        </Button>
    )
}

export default CancelButton
