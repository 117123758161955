import {initializeApp} from "firebase/app"
import {getFirestore} from "firebase/firestore"
import {getAuth,} from "firebase/auth"

const firebaseApp = initializeApp({
    apiKey: "AIzaSyD6zob-wILwFBEVeLxNbHMEs4yCEASU6YI",
    authDomain: "mazpartes-46c02.firebaseapp.com",
    projectId: "mazpartes-46c02",
    storageBucket: "mazpartes-46c02.appspot.com",
    messagingSenderId: "961594224112",
    appId: "1:961594224112:web:43b475ddf12fefbe9f8135",
    measurementId: "G-EH18L9HM6Z"
});

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp)

export {db, auth};
