import {collection, query, where, getDocs} from "firebase/firestore";
import {db} from "../firebase/Firebase";

const SearchDocument = async (coll, path, value) => {
    const q = query(collection(db, coll), where(path, "==", value));

    const arr = []
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        arr.push({...doc.data(), docId: doc.id})
    });

    return arr

}

export default SearchDocument
