import {useContext, useState, useRef, useEffect} from "react";
import './header-styles.css'
import AppBarHeader from "./AppBar";
import {StoreContext} from "../../../store/Context";
import {observer} from "mobx-react-lite";
import CustomizedSnackbars from "../../../palette/SnacksBar";

function Header(props) {

    const {path, value, setValue} = props;

    const {dataStore} = useContext(StoreContext)
    const {user} = dataStore

    const [open, setOpen] = useState(false);
    const [openSnack, setOpenSnak] = useState(false);
    const [snacks, setSnacks] = useState({message: '', type: 'success'})

    const anchorRef = useRef(null);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    return (
        <header>
            {/*<div className="header-container">
                <div className="contacts-container">
                    <PhoneAndroidIcon style={{color: 'red', fontSize: 18}}/>
                    <span style={styles.phoneText}>0984196041</span>
                </div>
                <div/>
                <div className="session-container" style={{position: 'relative', zIndex: 2000}}>
                    {user ?
                        <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">

                            <Text text={`${user.displayName} ( ${user.typeClient} )`} size={14} weeigh={'700'}/>

                            <IconButton style={{color: 'red'}}
                                        id="basic-button"
                                        aria-controls="basic-menu"
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClickMenu}
                            >
                                <LogoutIcon/>
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleCloseMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => {
                                    signOut(auth).then(() => {
                                        dataStore.ShoppingCart([])
                                        setOpenSnak(true)
                                        window.localStorage.clear()
                                        setSnacks({...snacks, message: 'Saliendo..!', type: 'info'})
                                        navigate('/')
                                    }).catch(() => {
                                        setOpen(true)
                                        setSnacks({
                                            ...snacks,
                                            message: 'Error inesperado intentolo otra vez!',
                                            type: 'error'
                                        })
                                    })
                                }
                                }>Cerrar sesión</MenuItem>
                                <MenuItem onClick={() => {
                                    navigate('/order-history')
                                }}>Historial de pedidos</MenuItem>
                                <MenuItem onClick={() => {
                                    navigate('/update-password')
                                }
                                }>Actualizar contraseña</MenuItem>

                            </Menu>

                        </Stack> :
                        <Stack spacing={2} direction="row">
                            <Button style={{textTransform: 'none'}} variant="text"
                                    onClick={() => {
                                        navigate('/session')
                                        dataStore.Path(path)
                                    }}
                            >Iniciar</Button>
                            <Button
                                onClick={() => {
                                    navigate('/session')
                                    dataStore.Path(path)
                                }}
                                style={{textTransform: 'none', backgroundColor: 'red'}}
                                variant="contained" disableElevation>
                                Registarse
                            </Button>
                        </Stack>
                    }
                </div>

                <div className="responsive-container">
                    <Stack spacing={1} direction="row">
                        <IconButton color="primary" aria-label="add to shopping cart">
                            <Badge badgeContent={1} color="error">
                                <AddShoppingCartIcon/>
                            </Badge>
                        </IconButton>

                        <IconButton
                            ref={anchorRef}
                            id="composition-button"
                            aria-controls={open ? 'composition-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                        >
                            <AccountCircleOutlinedIcon style={{color: 'red', fontSize: 26}}/>
                        </IconButton>
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                        >
                            {({TransitionProps, placement}) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                autoFocusItem={open}
                                                id="composition-menu"
                                                aria-labelledby="composition-button"
                                                onKeyDown={handleListKeyDown}
                                            >
                                                <MenuItem style={{fontSize: 14}}
                                                          onClick={handleClose}>Iniciar</MenuItem>
                                                <MenuItem style={{fontSize: 14}}
                                                          onClick={handleClose}>Resgistrarse</MenuItem>
                                                <MenuItem style={{fontSize: 14}} onClick={handleClose}>Salir</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Stack>
                </div>
            </div>*/}
            {/*<UserOptions path={path}/>*/}
            <AppBarHeader setValue={setValue} value={value} path={path}/>

            {/*<div className="app-bar">
                <AppBarHeader setValue={setValue} value={value} path={path}/>
            </div>*/}
            <CustomizedSnackbars open={openSnack} setOpen={setOpenSnak} message={snacks.message} type={snacks.type}/>
        </header>
    )
}

export default observer(Header)

const styles = {
    phoneText: {
        fontSize: 14,
        fontWeight: 'bold'
    }
}
