import {useLocalStore} from 'mobx-react-lite';
import {StoreContext} from "./Context";
import {DataStore} from "./DataStore";
import {FiltersStore} from "./FiltersStore";

const StoresProvider = ({children}) => {

    const dataStore = useLocalStore(() => new DataStore());
    const filtersStore = useLocalStore(() => new FiltersStore());

    return (
        <StoreContext.Provider value={{filtersStore, dataStore}}>
            {children}
        </StoreContext.Provider>
    )
}

export {StoresProvider}
