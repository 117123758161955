import Typography from "@mui/material/Typography";
import {initUpdateValues, updateSchema} from "../../utils/YupSchemas";
import InputForm from "../../palette/InputForm";
import {useContext, useState} from "react";
import {Formik} from 'formik';
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SubmitButton from "../../palette/SubmitButton";
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import CustomizedSnackbars from "../../palette/SnacksBar";
import {StoreContext} from "../../store/Context";
import {observer} from "mobx-react-lite";
import axios from "axios";
import {headers, updatePwdUrl, errorMsg} from "../../utils/Const";
import '../recover-password/recover.css'

function Update() {

    const {dataStore} = useContext(StoreContext)
    const {user, path} = dataStore

    const [loading, setLoading] = useState(false)
    const [textButton, setTextButton] = useState('Actualizar')
    const [open, setOpen] = useState(false)
    const [snacks, setSnacks] = useState({message: '', type: 'success'})

    const navigate = useNavigate();
    window.location.hash = "no-back-button";


    const onSubmit = async (values) => {

        setLoading(true)
        setTextButton('Actualizando')

        const data = {password: values.password, uid: user.uid}

        try {
            const res = await axios.post(updatePwdUrl, data, headers)
            if (res.data === 'OK') {
                setOpen(true)
                setSnacks({...snacks, message: 'Contraseña actualizada exitosamente!', type: 'success'})
                setTimeout(() => {
                    setLoading(false)
                    setTextButton('Actualizar')
                    navigate(path ? path : '/')
                }, 1000)
            } else {
                setOpen(true)
                setLoading(false)
                setTextButton('Actualizar')
                setSnacks({...snacks, message: errorMsg, type: 'error'})
            }
        } catch (e) {
            setOpen(true)
            setLoading(false)
            setTextButton('Actualizar')
            setSnacks({...snacks, message: errorMsg, type: 'error'})
        }

    }

    return (
        <div className={'recover-main-container'}>
            <div className={'recover-container'}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography component="h2" variant="h5">
                        ACTUALIZAR CONTRASEÑA
                    </Typography>
                </div>
                <br/>

                <Formik
                    initialValues={initUpdateValues}
                    validationSchema={updateSchema}
                    onSubmit={onSubmit}
                >
                    {({
                          values,
                          errors,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                      }) => {
                        return (
                            <>
                                <InputForm label="Password" full field={'password'} errors={errors} top={10} small
                                           handleChange={handleChange} values={values} handleBlur={handleBlur}
                                />

                                <InputForm label="Confirmar contraseña" full field={'passwordConfirmation'}
                                           errors={errors} small
                                           handleChange={handleChange} values={values} handleBlur={handleBlur}
                                           top={10}
                                />

                                <div style={{
                                    display: 'flex', flexDirection: 'row', marginTop: 10, alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>

                                    <Button variant="text" startIcon={<ArrowBackIcon/>}
                                            onClick={async () => {
                                                navigate("/")
                                            }}>Volver</Button>

                                    <SubmitButton text={textButton} type="submit" onClick={handleSubmit}
                                                  loading={loading} icon={<DynamicFeedIcon/>}/>

                                </div>
                            </>
                        )
                    }
                    }
                </Formik>
                <CustomizedSnackbars open={open} setOpen={setOpen} message={snacks.message} type={snacks.type}/>
            </div>
        </div>
    )
}


export default observer(Update)
