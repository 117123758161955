import './shopping-cart.css'
import React, {useContext, useEffect, useState} from "react";
import {StoreContext} from "../../store/Context";
import {observer} from "mobx-react-lite";
import Text from "../../palette/Text";
import TextField from "@mui/material/TextField";
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {db} from "../../firebase/Firebase";
import {collection, doc, getDocs, query, setDoc, where, updateDoc} from "firebase/firestore";
import CustomizedSnackbars from "../../palette/SnacksBar";
import ConfirmationModal from "../../palette/ConfirmationModal";
import {Filters, generateUUID, UpdateArray} from "../../utils/HelpFunctions";
import {format} from "date-fns";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import RegisterClientModal from "../client-data/RegisterClientModal";
import SearchDocument from "../../utils/SearchDocument";
import {es} from 'date-fns/locale'
import '../home/header/header.css'
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {makeStyles, withStyles} from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';

const useStyles = makeStyles({
    table: {
        minWidth: 1300,
    },
    containerButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

function ShoppingCartDetails() {

    const {dataStore} = useContext(StoreContext)

    const {shoppingCart, user, uidCart, mainProducts, controlStores, ivaValue} = dataStore
    const navigate = useNavigate();
    const classes = useStyles();

    const [payment, setPayment] = useState('');
    const [checked, setChecked] = useState(true);
    const [deliveryCost, setDeliveryCost] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [subTotal_2, setSubTotal_2] = useState(0);
    const [iva, setIva] = useState(0);
    const [total, setTotal] = useState(0);
    const [stock, setStock] = useState(0);
    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [snacks, setSnacks] = useState({message: '', type: 'success'})
    const [index, setIndex] = useState(0)
    const [textButton, setTextButton] = useState('Confirmar')
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(false)
    const [alertCredit, setAlertCredit] = useState(false)
    const [paymentAlert, setPaymentAlert] = useState(false)
    const [openModalRegister, setOpenModalRegister] = useState(false)
    const [checkStock, setCheckedStock] = useState([])
    const [prevValue, setPrevValue] = useState(0)

    const matches = useMediaQuery('(min-width:768px)');
    window.location.hash = "no-back-button";

    useEffect(() => {
        if (!mainProducts) {
            navigate('/')
        }

    }, [mainProducts])

    useEffect(() => {

        let isMounted = true

        const GetDetail = async () => {

            const q = query(collection(db, "cities",), where("city", "==", user?.city))
            const querySnapshot = await getDocs(q);
            const array = []
            querySnapshot.forEach((doc) => {
                const newObject = {...doc.data(), docIdSubGroup: doc.id}
                array.push(newObject);
            });

            checked ? setDeliveryCost(parseFloat(array[0].deliveryCost)) : setDeliveryCost(0)

            let subTotal = 0

            shoppingCart.forEach(items => {

                const clientDis = parseFloat(items.clientDis)
                const totalDiscount = items.disProd + clientDis
                const resultDiscount = items.realPrice * (totalDiscount / 100)
                const netPrice = items.realPrice - resultDiscount
                const total = items.amount * netPrice
                subTotal = total + subTotal
            })

            const subTotal_2 = checked ? subTotal + parseFloat(array[0].deliveryCost) : subTotal
            const iva = ivaValue / 100
            const resultIva = subTotal_2 * iva
            const total = subTotal_2 + resultIva

            setSubTotal(subTotal)
            setSubTotal_2(parseFloat(subTotal_2))
            setIva(resultIva)
            setTotal(parseFloat(total))
        }

        if (user && isMounted) {
            GetDetail().catch(() => null)
        }

        return (() => {
            isMounted = false
        })


    }, [user, shoppingCart, checked])

    const handleChangeDelivery = (event) => {
        setChecked(event.target.checked);
        setDeliveryCost(0)
    };

    const handleChange = (event) => {
        if (event !== '') {
            setPaymentAlert(false)
        }
        setPayment(event.target.value);
    };

    const handleOpenModal = async (index) => {
        setIndex(index)
        setOpenModal(true)
    }

    const handleDelete = async () => {

        setTextButton('Eliminando')
        setLoading(true)

        try {

            const uid = user.uid

            const arr = [...shoppingCart]
            arr.splice(index, 1);

            const product = [shoppingCart[index]]
            const cod = product[0].cod

            dataStore.ShoppingCart(arr)

            const date = format(new Date(), 'MM-21 HH:mm:ss')
            const rp = date.replaceAll('-', '')
            const rp_1 = rp.replaceAll(' ', '')
            const rp_2 = rp_1.replaceAll(':', '')
            const uidCart_1 = uidCart ? uidCart : generateUUID()

            const data = {data: arr, uidCart: uidCart_1, orderNumber: rp_2, delivery: checked, payment, deliveryCost}

            await setDoc(doc(db, "clients", uid, 'cart', 'cart001'), data);
            const result = await SearchDocument('products', 'cod', cod)
            const uidProduct = result[0].docId
            const updateProduct = doc(db, "products", uidProduct);
            await updateDoc(updateProduct, {
                mainCart: false
            });
            setTextButton('Confirmar')
            setLoading(false)
            setOpenModal(false)
            setOpen(true)
            setSnacks({...snacks, message: 'Producto eliminado exitosamente!', type: 'success'})
            if (arr.length === 0) {
                setSubTotal(0)
                setSubTotal_2(0)
                setIva(0)
                setTotal(0)
                setDeliveryCost(0)
            }

            setTimeout(() => {
                if (arr.length === 0) {
                    navigate('/')
                }

            }, 2000)

        } catch (e) {
            setOpen(true)
            setSnacks({...snacks, message: 'Error inesperado intentelo otra vez!', type: 'error'})
            setTextButton('Confirmar')
            setLoading(false)
            setOpenModal(false)
        }
    }

    const onChangeAmount = async (items, value, index) => {

        if (value === '' || isNaN(value)) {
            dataStore.ShoppingCart([...shoppingCart])
            return
        }

        setPrevValue(value)
        if (value === prevValue) {
            return
        }

        const cod = items.cod
        const store = items.storage

        const q = query(collection(db, "products"), where("cod", "==", cod))
        const querySnapshot = await getDocs(q);
        const arr = []
        querySnapshot.forEach((doc) => {
            arr.push({...doc.data(), docId: doc.id,})
        })


        const stock = arr[0].stock

        const stores = controlStores ? controlStores : arr[0].stores

        const purchaseQuota = user.purchaseQuota

        const valueStock = Filters(arr[0].stores, 'name', store)


        //const storeStock = objStore.stock
        //let resultStock = value < storeStock ? storeStock - 1 : storeStock + 1
        const control = valueStock[0].stock - value
        const update = UpdateArray(stores, 'name', store, 'stock', value)
        dataStore.ControlStores(update)

        if (control < 0) {
            const update = UpdateArray(stores, 'name', store, 'stock', 0)
            dataStore.ControlStores(update)
            setAlert(true)
            setIndex(index)
            setStock(valueStock[0].stock)
            return
        }

        setCheckedStock([])

        setAlert(false)

        shoppingCart.forEach(items => {
            if (items.cod === cod && items.storage === store) {
                items.amount = value
            }
        })

        const updateCart = [...shoppingCart]
        dataStore.ShoppingCart(updateCart)


        const uidCart_1 = uidCart ? uidCart : generateUUID()
        dataStore.UIDCart(uidCart_1)
        const uid = user.uid
        const orderDate = format(new Date(), 'yyyy-MM-dd HH: mm')
        const data = {
            data: updateCart,
            uidCart: uidCart_1,
            orderNumber: '',
            delivery: checked,
            payment,
            deliveryCost,
            purchaseQuota,
            orderDate
        }


        try {

            if (stock === value) {
                /*const updateProduct = doc(db, "products", uidProduct)
                await updateDoc(updateProduct, {
                    mainCart: true
                });*/
                const update = UpdateArray(mainProducts, 'cod', cod, 'mainCart', true)
                dataStore.MainProducts(update)
            } else {
                const update = UpdateArray(mainProducts, 'cod', cod, 'mainCart', true)
                dataStore.MainProducts(update)
                /* const updateProduct = doc(db, "products", uidProduct)
                 await updateDoc(updateProduct, {
                     mainCart: false
                 });*/
            }

            await setDoc(doc(db, "clients", uid, 'cart', 'cart001'), data);
        } catch (e) {
            // console.log(e)
            setOpen(true)
            setSnacks({...snacks, message: 'Error inesperado intentolo otra vez!', type: 'error'})
        }
    }

    const buttonsChangeAmount = async (items, value, index) => {

        if (value < 1) {
            return
        }

        await onChangeAmount(items, value, index)

    }

    const handleOrder = async () => {

        const purchaseQuota = user.purchaseQuota

        if (payment === '') {
            setPaymentAlert(true)
            return
        }

        setPaymentAlert(false)

        if (total >= purchaseQuota) {
            setAlertCredit(true)
            return
        }

        setAlertCredit(false)

        const date = format(new Date(), 'MM-21 HH:mm:ss')
        const rp = date.replaceAll('-', '')
        const rp_1 = rp.replaceAll(' ', '')
        const rp_2 = rp_1.replaceAll(':', '')
        const uidCart_1 = uidCart ? uidCart : generateUUID()
        dataStore.UIDCart(uidCart_1)

        const orderDate = format(new Date(), 'yyyy-MM-dd HH:mm')
        const actualDate = format(new Date(), 'yyyy-MM-dd')
        const month = format(new Date(), 'LLLL', {locale: es})
        const year = format(new Date(), 'yyyy')

        const data = {
            data: shoppingCart, uidCart: uidCart_1, orderNumber: rp_2, delivery: checked, payment, deliveryCost,
            purchaseQuota, orderDate, status: 'PENDIENTE', buyDate: '', observation: '', total: 0, year, month,
            typeClient: user?.typeClient, name: user?.displayName, idClient: user?.idClient, actualDate
        }


        const checkStock = []
        for (const items of shoppingCart) {
            const cod = items.cod
            const amount = items.amount

            const q = query(collection(db, "products"), where("cod", "==", cod))
            const arr = []
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), docId: doc.id,})
            })

            if (arr[0].stock < amount) {
                const newObject = {product: `${items.cod} ${items.name} ${items.manufacturers}`}
                checkStock.push(newObject)
            }
        }

        if (checkStock.length > 0) {
            setCheckedStock(checkStock)
            return
        } else {
            setCheckedStock([])
        }

        dataStore.DataOrder(data)

        const uid = user.uid

        setOpenModalRegister(true)

        try {
            await setDoc(doc(db, "clients", uid, 'cart', 'cart001'), data);
            await setDoc(doc(db, "clients", uid, 'orders', uidCart_1), data);
            await setDoc(doc(db, 'orders', uidCart_1), data);

            let updateProducts;
            const productsArr = []

            for (const items of shoppingCart) {
                const cod = items.cod
                const storage = items.storage
                const q = query(collection(db, "products"), where("cod", "==", cod));
                const querySnapshot = await getDocs(q);

                querySnapshot.forEach((doc) => {
                    productsArr.push({...doc.data(), uidProduct: doc.id})
                });

                const filteredArr = productsArr.reduce((acc, current) => {
                    const x = acc.find(item => item.cod === current.cod);
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                }, []);

                const amount = items.amount

                filteredArr.forEach(items => {
                    if (items.cod === cod) {
                        const stores = items.stores
                        const filterStore = Filters(stores, 'name', storage)
                        const newStock = filterStore[0].stock - amount
                        let stock = newStock < 0 ? 0 : newStock
                        const updateArray = UpdateArray(stores, 'name', storage, 'stock', stock)
                        items.stores = updateArray
                    }
                })
                updateProducts = filteredArr
            }

            for (const items of updateProducts) {
                let stock = items.stores.reduce(function (prev, cur) {
                    return prev + cur.stock;
                }, 0);
                const uidProduct = items.uidProduct
                items.stock = stock
                await setDoc(doc(db, "products", uidProduct), items);
            }
        } catch (e) {
            console.log(e)
            setOpen(true)
            setSnacks({...snacks, message: 'Error inesperado intentelo otra vez!', type: 'error'})
        }
    }

    return (

        <div className={'shopping-container'}>
            <Text variant={'h5'} color={'gray'} text={'CARRITO DE COMPRAS'}/>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center"/>
                            <StyledTableCell align="center">Productos</StyledTableCell>
                            <StyledTableCell align="center">Precio. sin imp</StyledTableCell>
                            <StyledTableCell align="center">Cantidad</StyledTableCell>
                            <StyledTableCell align="center">% Dcto. Prod</StyledTableCell>
                            <StyledTableCell align="center">% Dcto. Cte</StyledTableCell>
                            <StyledTableCell align="center">Precio neto</StyledTableCell>
                            <StyledTableCell align="center">Total</StyledTableCell>
                            <StyledTableCell align="center">Bodega despacho</StyledTableCell>
                            <StyledTableCell align="center"/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shoppingCart.map((items, i) => {

                            const clientDis = parseFloat(items.clientDis)
                            const totalDiscount = items.disProd + clientDis
                            const resultDiscount = items.realPrice * (totalDiscount / 100)
                            const netPrice = items.realPrice - resultDiscount
                            const total = items.amount * netPrice

                            return (
                                <StyledTableRow key={i}>
                                    <StyledTableCell align="center">
                                        <img src={items.image} alt={''} style={styles.image}/>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {`${items.cod} ${items.name} ${items.manufacturers}`}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {`$ ${items.realPrice}`}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                            <IconButton aria-label="delete"
                                                        onClick={() => buttonsChangeAmount(items, items.amount - 1, i)}>
                                                <ArrowDropDownOutlinedIcon style={{fontSize: 32, color: '#282A36'}}/>
                                            </IconButton>
                                            <TextField
                                                label="Cantidad"
                                                type="number"
                                                value={items.amount}
                                                onChange={(e) => onChangeAmount(items, parseInt(e.target.value), i)}
                                                inputProps={{min: 1}}
                                                id="outlined-size-small"
                                                defaultValue="Small"
                                                size="small"
                                                disabled={!matches}
                                            />
                                            <IconButton aria-label="delete"
                                                        onClick={() => buttonsChangeAmount(items, items.amount + 1, i)}>
                                                <ArrowDropUpOutlinedIcon style={{fontSize: 32, color: '#282A36'}}/>
                                            </IconButton>
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {`% ${items.disProd.toFixed(2)}`}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {`% ${parseFloat(items.clientDis).toFixed(2)}`}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {`$ ${netPrice.toFixed(2)}`}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{width: 50}}>
                                        {`$ ${total.toFixed(2)}`}
                                    </StyledTableCell>

                                    <StyledTableCell align="center">
                                        <div style={{display: 'flex', flexDirection: 'column'}}
                                             className={'containers-items-styles'}>
                                      <span style={{color: '#333', fontSize: 16}}>
                                            {items.storage}
                                        </span>
                                            <span style={{color: '#333', fontSize: 9}}>
                                            {items.direction}
                                        </span>
                                            {(alert && index === i) &&
                                                <span style={{color: 'red', fontSize: 11, fontWeight: '500'}}>
                                            {`El stock actual es de ${stock}`}
                                        </span>}
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <IconButton aria-label="delete" onClick={() => handleOpenModal(i)}>
                                            <DeleteIcon style={{color: 'red'}}/>
                                        </IconButton>
                                    </StyledTableCell>

                                </StyledTableRow>
                            )

                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <br/>

            {checkStock?.length > 0 &&
                <Stack sx={{width: '100%'}} spacing={2}>
                    {checkStock?.map((items, i) => {
                        return (
                            <div style={{marginTop: 5}}>
                                <Alert
                                    severity="warning">{`Se ha modificado el stock en el ítem ${items.product} por favor actualize su cantidad`}</Alert>
                            </div>
                        )
                    })}
                </Stack>
            }

            <div className={'order-container'}>

                <div style={{width: matches ? '20%' : '100%'}} className={'containers-items-styles'}>
                    <FormControl sx={{m: 1, width: '100%'}}>
                        <InputLabel id="demo-simple-select-helper-label">Forma de pago</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={payment}
                            size={'small'}
                            label="Forma de pago"
                            onChange={handleChange}
                        >
                            <MenuItem value={'Deposito/transferencia'}>Deposito/transferencia</MenuItem>
                            <MenuItem value={'Tarjeta de Cred/Deb'}>Tarjeta de Cred/Deb</MenuItem>
                            {user?.paymentDeadline !== '' && <MenuItem value={'Credito'}>Credito</MenuItem>}
                        </Select>
                        {paymentAlert &&
                            <FormHelperText style={{fontWeight: '400', color: 'red'}}>
                                Escoja un metodo de pago
                            </FormHelperText>}
                    </FormControl>
                </div>

                <div style={{
                    width: matches ? '20%' : '100%', display: 'flex', flexDirection: 'row',
                    alignItems: 'center', justifyContent: !matches ? 'flex-start' : null
                }}
                     className={'containers-items-styles'}>
                    <Checkbox
                        checked={checked}
                        onChange={handleChangeDelivery}
                        inputProps={{'aria-label': 'controlled'}}
                    />
                    <span style={{marginRight: 15}}>Entrega a domicilio</span>
                </div>

                <div style={{
                    width: matches ? '60%' : '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}
                >
                    <div className={'resume-container'}>
                        <span style={{color: 'black', fontWeight: 'bold'}}>Subtotal : </span>
                        <span style={{color: '#333', fontWeight: '600', marginLeft: 25}}>
                             {`$ ${subTotal.toFixed(2)}`}
                         </span>
                    </div>

                    <div className={'resume-container'}>
                        <span style={{color: 'black', fontWeight: 'bold'}}>Costo de envio : </span>
                        <span style={{color: '#333', fontWeight: '600', marginLeft: 25}}>{`$ ${deliveryCost}`}</span>
                    </div>

                    <div className={'resume-container'}>
                        <span style={{color: 'black', fontWeight: 'bold'}}>Subtotal 2 : </span>
                        <span style={{color: '#333', fontWeight: '600', marginLeft: 25}}>
                             {`$ ${subTotal_2.toFixed(2)}`}
                         </span>
                    </div>

                    <div className={'resume-container'}>
                        <span style={{color: 'black', fontWeight: 'bold'}}>IVA 15%: </span>
                        <span style={{color: '#333', fontWeight: '600', marginLeft: 25}}>{`$ ${iva.toFixed(2)}`}</span>
                    </div>

                    <div className={'resume-container'}>
                        <span style={{color: 'black', fontWeight: 'bold'}}>TOTAL : </span>
                        <span style={{color: '#333', fontWeight: 'black', marginLeft: 25, fontSize: 18}}>
                             {`$ ${total.toFixed(2)}`}
                         </span>
                    </div>

                </div>

            </div>
            <br/>

            {alertCredit &&
                <Stack sx={{width: '100%'}} spacing={2}>
                    <Alert
                        severity="error">{`El monto máximo que puede adquirir con su línea de crédito es de ${user?.purchaseQuota}`}</Alert>
                </Stack>}

            <div className={'order-container-action'}>
                <Button variant="text" startIcon={<ArrowBackIcon/>}
                        onClick={async () => {
                            dataStore.BackDetailHome(true)
                            // dataStore.UpdateFilter({...filterData})
                            navigate("/")
                        }}>Volver</Button>

                <Button variant="contained" disableElevation style={{background: '#B7182B', borderRadius: 15}}
                        onClick={handleOrder} startIcon={<ReceiptOutlinedIcon/>}>
                    Generar Pedido
                </Button>

            </div>


            <RegisterClientModal openModalRegister={openModalRegister} setOpenModalRegister={setOpenModalRegister}
                                 checked={checked}/>

            <CustomizedSnackbars open={open} setOpen={setOpen} message={snacks.message} type={snacks.type}/>


            <ConfirmationModal openModal={openModal} setOpenModal={setOpenModal} text={'Eliminar producto'}
                               textBody={'Seguro que desea eliminar este producto?'} textButton={textButton}
                               icon={<DeleteIcon/>} loading={loading} onClick={handleDelete}
            />
        </div>
    )
}

export default observer(ShoppingCartDetails)

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#282A36',
        color: 'white',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#FAFAFA',
        },
    },
}))(TableRow);


const styles = {
    image: {
        width: 50,
        height: 70
    }
}
