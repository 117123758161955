import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import PersonalData from "./PersonalData";
import {observer} from "mobx-react-lite";
import {StoreContext} from "../../store/Context";
import {collection, deleteDoc, doc, getDocs, onSnapshot, query, setDoc, where} from "firebase/firestore";
import {db} from "../../firebase/Firebase";
import ClientDirection from "./ClientDirection";
import ClientDeliveryData from "./ClientDeliveryData";
import {FilterDelete, Filters, UpdateArray} from "../../utils/HelpFunctions";
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from "@mui/material/useMediaQuery";


function RegisterClientModal(props) {

    const {dataStore} = useContext(StoreContext)
    const {user, personalData, clientData, directionData, deliveryData, uidCart, shoppingCart} = dataStore


    const {openModalRegister, setOpenModalRegister, checked, setTrigger, trigger,} = props

    const [activeStep, setActiveStep] = React.useState(0);
    const [newSteps, setNewSteps] = useState([])

    const matches = useMediaQuery('(min-width:768px)');
    window.location.hash = "no-back-button";

    useEffect(() => {

        let isMounted = true

        const GetClientData = async () => {
            const uid = user.uid

            onSnapshot(doc(db, "clients", uid), (doc) => {

                const personal = {
                    ...personalData, name: doc.data().name, lastName: doc.data().lastName,
                    email: doc.data().email, phoneNumber: doc.data().phoneNumber, typeId: doc.data().typeId,
                    idClient: doc.data().idClient, businessNumber:
                        doc.data().businessNumber === undefined ? '' : doc.data().businessNumber,
                    tradeName:
                        doc.data().businessNumber === undefined ? '' : doc.data().tradeName
                }


                const direction = {
                    ...directionData, province: doc.data().province, city: doc.data().city,
                    mainStreet: doc.data().mainStreet, secondaryStreet: doc.data().secondaryStreet,
                    houseNumber: doc.data().houseNumber, reference: doc.data().reference
                }

                const delivery = {
                    ...deliveryData,
                    deliveryProvince: doc.data().deliveryProvince ? doc.data().deliveryProvince : doc.data().province,
                    deliveryCity: doc.data().deliveryCity ? doc.data().deliveryCity : doc.data().city,
                    deliveryMainStreet: doc.data().deliveryMainStreet ? doc.data().deliveryMainStreet : doc.data().mainStreet,
                    deliverySecondaryStreet: doc.data().deliverySecondaryStreet ? doc.data().deliverySecondaryStreet : doc.data().secondaryStreet,
                    deliveryHouseNumber: doc.data().deliveryHouseNumber ? doc.data().deliveryHouseNumber : doc.data().houseNumber,
                    deliveryReference: doc.data().deliveryReference ? doc.data().deliveryReference : doc.data().reference
                }


                dataStore.RegisterClientModal(personal, direction, delivery, doc.data())
            });
        }


        (isMounted && user) && GetClientData()

        //user && setTrigger(!trigger)

        return (() => {
            isMounted = false
        })

    }, [user])

    const steps = [
        {
            label: 'Datos personales',
            description: personalData &&
                <PersonalData activeStep={activeStep} setActiveStep={setActiveStep} personalData={personalData}
                              clientData={clientData} user={user} trigger={trigger} setTrigger={setTrigger}
                />
        },
        {
            label: 'Dirección del cliente',
            description: directionData &&
                <ClientDirection setOpenModalRegister={setOpenModalRegister} setActiveStep={setActiveStep} user={user}
                                 directionData={directionData} clientData={clientData} checked={checked}
                                 trigger={trigger} setTrigger={setTrigger}/>
        },
        {
            label: 'Dirección de entrega',
            description: deliveryData &&
                <ClientDeliveryData activeStep={activeStep} setActiveStep={setActiveStep} user={user}
                                    deliveryData={deliveryData} clientData={clientData}
                                    setOpenModalRegister={setOpenModalRegister}
                                    checked={checked}/>,
        },
    ];

    useEffect(() => {

        const filterSteps = checked ? steps : FilterDelete(steps, 'label', 'Dirección de entrega')

        setNewSteps(filterSteps)

    }, [user, checked, personalData])

    // const handleOpen = () => setOpen(true);
    const handleClose = async () => {
        setOpenModalRegister(false)

        const productsArr = []
        let updateProducts;
        const uid = user.uid

        try {
            for (const items of shoppingCart) {
                const cod = items.cod
                const storage = items.storage

                const q = query(collection(db, "products"), where("cod", "==", cod))

                const querySnapshot = await getDocs(q);

                querySnapshot.forEach((doc) => {
                    productsArr.push({...doc.data(), docId: doc.id,})
                })

                const filteredArr = productsArr.reduce((acc, current) => {
                    const x = acc.find(item => item.cod === current.cod);
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                }, []);


                const amount = items.amount

                filteredArr.forEach(items => {
                    if (items.cod === cod) {
                        const stores = items.stores
                        const filterStore = Filters(stores, 'name', storage)
                        const newStock = filterStore[0].stock + amount
                        const updateArray = UpdateArray(stores, 'name', storage, 'stock', newStock)
                        items.stores = updateArray
                    }
                })
                updateProducts = filteredArr
            }


            for (const items of updateProducts) {
                let stock = items?.stores.reduce(function (prev, cur) {
                    return prev + cur.stock;
                }, 0);
                const uidProduct = items.uidProduct
                items.stock = stock
                await setDoc(doc(db, "products", uidProduct), items);
            }

            dataStore.BackDetailHome(true)
            await deleteDoc(doc(db, "clients", uid, 'orders', uidCart));
            await deleteDoc(doc(db, 'orders', uidCart));
            dataStore.UIDCart(null)
            setActiveStep(0)

        } catch (e) {
            //  setOpen(true)
            //  setSnacks({...snacks, message: 'Error inesperado intentelo otra vez!', type: 'error'})
        }

    };

    return (
        <div>
            {user &&
            <Modal
                open={openModalRegister}
                //  onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                    width: matches ? 400 : 320, bgcolor: 'background.paper', boxShadow: 24, p: 4,
                }}>
                    <div
                        style={{
                            display: 'flex', flexDirection: 'row', alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                        <Typography>DATOS INFORMATIVOS DEL CLIENTE</Typography>
                        <IconButton aria-label="delete" size="small" onClick={handleClose}>
                            <CancelIcon style={{color: "red"}} fontSize="inherit"/>
                        </IconButton>
                    </div>
                    <Box sx={{maxWidth: 400}}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {newSteps.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel>
                                        {step.label}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>{step.description}</Typography>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </Box>
            </Modal>}
        </div>
    )

}

export default observer(RegisterClientModal)
