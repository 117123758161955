import Header from "../home/header/Header";
import './session.css'
import Footer from "../home/footer/Footer";
import DataSession from "./data-session/DataSession";
function Session() {

    return (
        <div className="wrapper-detail">
            <Header/>
            <div className="main-detail-container">
                <DataSession/>
            </div>
            <footer>
                <Footer/>
            </footer>
        </div>
    )
}

export default Session
