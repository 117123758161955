import './home.css'
import Header from "./header/Header";
import Sidebar from "./side/Side";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {useContext, useEffect, useState} from "react";
import {collection, getDocs, limit, query, where} from "firebase/firestore";
import {db} from "../../firebase/Firebase";
import {logo} from "../../utils/Const";
import {StoreContext} from "../../store/Context";
import MainProducts from "./main/MainProducts";
import Footer from "./footer/Footer";
import {Filters, Order, UpdateArray} from "../../utils/HelpFunctions";
import {observer} from "mobx-react-lite";
import mainProducts from "./main/MainProducts";

function Home(props) {

    const {dataStore, filtersStore} = useContext(StoreContext)
    const {shoppingCart, triggerProd_, mainProducts} = dataStore
    const {subGroupList, schemasList_} = filtersStore

    const [vehicles, setVehicles] = useState(null)
    const [models, setModels] = useState(null)
    const [manufacturers, setManufacturers] = useState(null)
    const [groups, setGroups] = useState(null)
    const [products, setProducts] = useState(null);
    const [schemas, setSchemas] = useState([]);
    const [trigger, setTrigger] = useState(false);
    const [triggerProd, setTriggerProd] = useState(false);
    const [triggerVehicle, setTriggerVehicle] = useState(false);
    const [triggerModel, setTriggerModel] = useState(false);
    const [triggerYear, setTriggerYear] = useState(false);
    const [user, setUser] = useState(null);
    const [value, setValue] = useState('');
    const [collapsed, setCollapsed] = useState(true)

    useEffect(() => {

        let isMounted = true

        const GetSubGroup = async () => {
            const querySnapshot = await getDocs(collection(db, "subGroups"));
            const subGroups = []
            querySnapshot.forEach((doc) => {
                subGroups.push({...doc.data(), docId: doc.id})
            });
            !subGroupList && filtersStore?.SubGroupList(Order(subGroups, 'subGroup'))
            filtersStore?.CopySubGroupList(Order(subGroups, 'subGroup'))
        }

        const GetSchemas = async () => {
            const querySnapshot = await getDocs(collection(db, "schemas"));
            const schemas = []
            querySnapshot.forEach((doc) => {
                schemas.push({...doc.data(), docId: doc.id})
            });
            setSchemas(Order(schemas, 'name'))
            !schemasList_ && filtersStore?.SchemaList(Order(schemas, 'name'))
            filtersStore?.CopySchemaList(Order(schemas, 'name'))
        }

        isMounted && GetSubGroup()

        isMounted && GetSchemas()


        return (() => {
            isMounted = false
        })

    }, [])

    useEffect(() => {
        let isMounted = true

        const GetProducts = async () => {
            const q = query(collection(db, "products"), where("status", "==", 'Activo'), where("stock", ">", 0),
                limit(10));
            const querySnapshot = await getDocs(q);
            const arr = []
            querySnapshot.forEach((doc) => {
                let stock = doc.data().stores.reduce(function (prev, cur) {
                    return prev + cur.stock;
                }, 0);

                //console.log(doc.data())
                const cod = doc.data().cod
                const filterAmount = Filters(shoppingCart, 'cod', cod)
                let control
                let arrStores = null

                if (filterAmount.length > 0) {
                    const product = doc.data()
                    let amount = filterAmount.reduce(function (prev, cur) {
                        return prev + cur.amount;
                    }, 0);
                    control = product.stock - amount

                    filterAmount.forEach((item) => {
                        const store = item.storage
                        const stores = doc.data().stores
                        const filterStore = Filters(stores, 'name', store)
                        const newStock = filterStore[0].stock - item.amount
                        arrStores = UpdateArray(stores, 'name', store, 'stock', newStock)
                    })

                    // dataStore.ControlStores(arr)
                } else {
                    arrStores = doc.data().stores
                }

                arr.push({
                    ...doc.data(), docId: doc.id, stock, show: stock > 0, stores: arrStores,
                    mainCart: control <= 0
                })
            })

            const order = Order(arr, 'name')
            dataStore.MainProducts(order)
            dataStore.CopyMainProducts(order)
            setProducts(order)
        }

        (isMounted && !mainProducts) ? GetProducts() : dataStore.MainProducts(mainProducts)

        return (() => {
            isMounted = false
        })

    }, [triggerProd, triggerProd_])

    useEffect(() => {
        let isMounted = true

        const GetModels = async () => {
            const querySnapshotModel = await getDocs(collection(db, "models"));
            const arrModels = []
            querySnapshotModel.forEach((doc) => {
                arrModels.push({...doc.data(), docId: doc.id})
            });
            setModels(Order(arrModels, 'models'))
            filtersStore?.ModelsList(Order(arrModels, 'models'))
            filtersStore?.CopyModelsList(Order(arrModels, 'models'))
        }

        isMounted && GetModels()

        return (() => {
            isMounted = false
        })
    }, [triggerModel])

    useEffect(() => {
        let isMounted = true

        const GetVehicles = async () => {
            const querySnapshot = await getDocs(collection(db, "vehiclesBrands"));
            const arr = []
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), docId: doc.id})
            });
            dataStore.Vehicles(arr)
        }

        const GetManufacturers = async () => {
            const querySnapshot = await getDocs(collection(db, "manufacturers"));
            const manufactures = []
            querySnapshot.forEach((doc) => {
                manufactures.push({...doc.data(), docId: doc.id})
            });
            setManufacturers(Order(manufactures, 'manufacturers'))
        }

        const GetGroups = async () => {
            const querySnapshot = await getDocs(collection(db, "groups"));
            const groups = []
            querySnapshot.forEach((doc) => {
                groups.push({...doc.data(), docId: doc.id})
            });
            setGroups(Order(groups, 'group'))
        }

        isMounted && GetVehicles()
        isMounted && GetManufacturers()
        isMounted && GetGroups()

        return (() => {
            isMounted = false
        })

    }, [])


    return (
        <>
            {(mainProducts) &&
                <div className={collapsed ? 'wrapper' : 'wrapper_2'}>
                    {/* <div className={'wrapper'}>*/}
                    <Header path={'/'} setValue={setValue} value={value}/>
                    <main>
                        {user ?
                            <MainProducts setCollapsed={setCollapsed} collapsed={collapsed}
                                          triggerProd={triggerProd}/> :
                            <MainProducts setCollapsed={setCollapsed} collapsed={collapsed} triggerProd={triggerProd}/>}
                    </main>

                    {collapsed ? <div id="sidebar" style={{height: 'auto'}}>
                        <Sidebar vehicles={vehicles} models={models} manufacturers={manufacturers} groups={groups}
                                 schemas={schemas}
                                 setSchemas={setSchemas} setTrigger={setTrigger} trigger={trigger}
                                 setTriggerProd={setTriggerProd} triggerProd={triggerProd} setModels={setModels}
                                 setValue={setValue} value={value}
                                 setTriggerVehicle={setTriggerVehicle} triggerVehicle={triggerVehicle}
                                 setTriggerModel={setTriggerModel} triggerModel={triggerModel}
                                 setCollapsed={setCollapsed} collapsed={collapsed}
                                 setTriggerYear={setTriggerYear} triggerYear={triggerYear}/>

                    </div> : <div id="sidebar"/>}
                    <footer>
                        <Footer/>
                    </footer>

                </div>}

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={!mainProducts}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}>
                    <img alt={''} src={logo} style={{width: 250, height: 125}}/>
                    <CircularProgress color="inherit"/>
                </div>
            </Backdrop>
        </>
    )
}


export default observer(Home)

