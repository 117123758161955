import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({

    formInput: {
        background: '#E5E9EA',
        borderRadius: 10,
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
            borderRadius: 10,
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
            borderRadius: 10,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
            borderRadius: 10,
        },
        '& label.Mui-focused': {
            borderColor: "#fff",
            borderRadius: 10,
        },
    },

    submitButton: {
        background: '#0087CB',
        boxShadow: 'none',
        height: 35,
        borderRadius: 15,
        color: '#FFFFFF',
        '&:hover': {
            boxShadow: 'none',
            background: '#0A94B1'
        }
    },


    cancelButton: {
        color: '#FFF',
        fontSize: 14,
        marginTop: 10,
        borderRadius: 15,
        height: 35,
        fontWeight: '500',
        cursor: 'pointer',
        marginRight: 15,
        background: '#757575',
        '&:hover': {
            boxShadow: 'none',
            background: '#616161',
            fontWeight: '700',
        }
    },

}))


export {useStyles}
