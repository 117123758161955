import {observer} from "mobx-react-lite";
import {errorMsg, headers, logo, ocUrl, tcUrl} from "../../utils/Const";
import React, {useContext, useEffect, useState} from "react";
import {StoreContext} from "../../store/Context";
import Stack from "@mui/material/Stack";
import Text from "../../palette/Text";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {signOut} from "firebase/auth";
import {auth, db} from "../../firebase/Firebase";
import Button from "@mui/material/Button";
import {Link, useNavigate} from "react-router-dom";
import Divider from '@mui/material/Divider';
import DeleteIcon from "@mui/icons-material/Delete";
import {collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where} from "firebase/firestore";
import Checkbox from "@mui/material/Checkbox";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Filters, UpdateArray} from "../../utils/HelpFunctions";
import CustomizedSnackbars from "../../palette/SnacksBar";
import ConfirmationModal from "../../palette/ConfirmationModal";
import Timer from "./Timer";
import {format} from "date-fns";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import {makeStyles, withStyles} from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import useMediaQuery from "@mui/material/useMediaQuery";
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import axios from "axios";
import Footer from "../home/footer/Footer";
import CircularProgress from "@mui/material/CircularProgress";

const logoMail = 'https://firebasestorage.googleapis.com/v0/b/mazpartes-46c02.appspot.com/o/mazpartesLogo.png?alt=media&token=7540cf7d-e92a-4a50-82aa-a63ff218b35b'
const companyName = 'Mazpartes'

function Order() {

    const {dataStore} = useContext(StoreContext)

    const {user, shoppingCart, observation, dataOrder, updateName, ivaValue} = dataStore

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [orderNumber, setOrderNumber] = useState('')
    const [payment, setPayment] = useState('');
    const [checked, setChecked] = useState(false);
    const [termsConditions, setTermsConditions] = useState(false);
    const [deliveryCost, setDeliveryCost] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [subTotal_2, setSubTotal_2] = useState(0);
    const [iva, setIva] = useState(0);
    const [total, setTotal] = useState(0);
    const [openModal, setOpenModal] = useState(false)
    const [snacks, setSnacks] = useState({message: '', type: 'success'})
    const [textButton, setTextButton] = useState('Confimar')
    const [buyButton, setBuyButton] = useState('Comprar')
    const [textCancelButton, setTextCancelButton] = useState('Cancelar compra')
    const [loading, setLoading] = useState(false)
    const [uidOrder, setUidOrder] = useState('')
    const [valueCard, setValueCard] = React.useState(1);

    const openMenu = Boolean(anchorEl);
    const classes = useStyles();
    const navigate = useNavigate();

    const time = new Date();
    time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
    window.location.hash = "no-back-button";
    const matches = useMediaQuery('(min-width:768px)');

    useEffect(() => {

        if (user) {
            if (shoppingCart.length === 0) {
                window.localStorage.clear();
                navigate('/')
            }
        }

    }, [user])

    useEffect(() => {

        let isMounted = true

        const GetDeliveryCost = async () => {
            const savedCity = localStorage.getItem('city');
            const deliveryCity = user?.deliveryCity
            let city
            if (deliveryCity) {
                city = deliveryCity
            } else {
                city = savedCity !== null ? JSON.parse(savedCity) : null;
            }
            const q = query(collection(db, "cities",), where("city", "==", city))
            const querySnapshot = await getDocs(q);
            const array = []
            querySnapshot.forEach((doc) => {
                const newObject = {...doc.data(), docIdSubGroup: doc.id}
                array.push(newObject);
            });
            setDeliveryCost(array[0].deliveryCost)

        }

        (isMounted && checked) && GetDeliveryCost()


        return (() => {
            isMounted = false
        })

    }, [checked])

    useEffect(() => {
        let isMounted = true
        const GetCart = async () => {
            const uid = user.uid
            const docRef = doc(db, "clients", uid, 'cart', 'cart001');
            try {
                const doc = await getDoc(docRef);
                setUidOrder(doc.data().uidCart)
                setOrderNumber(doc.data().orderNumber)
                setChecked(doc.data().delivery)
                setPayment(doc.data().payment)
                dataStore.DataOrder(doc.data())
            } catch (e) {
                // console.log("Error getting cached document:", e);
            }
        }

        (isMounted && user) && GetCart()

        return (() => {
            isMounted = false
        })


    }, [user])

    useEffect(() => {

        let isMounted = true

        const GetDetail = async () => {

            let subTotal = 0

            shoppingCart.forEach(items => {

                const clientDis = parseFloat(items.clientDis)
                const totalDiscount = items.disProd + clientDis
                const resultDiscount = items.realPrice * (totalDiscount / 100)
                const netPrice = items.realPrice - resultDiscount
                const total = items.amount * netPrice
                subTotal = total + subTotal
            })

            console.log(ivaValue)

            const deliveryCost_1 = typeof (deliveryCost) === "string" ? parseFloat(deliveryCost) : deliveryCost
            const subTotal_2 = subTotal + deliveryCost_1
            //shoppingCart[0].iva === 12 ? 15 : 12
            const iva = ivaValue / 100
            const resultIva = subTotal_2 * iva
            const total = subTotal_2 + resultIva

            setSubTotal(subTotal)
            setSubTotal_2(parseFloat(subTotal_2))
            setIva(resultIva)
            setTotal(parseFloat(total))
        }

        if (user && isMounted) {
            GetDetail().catch(() => null)
        }


        return (() => {
            isMounted = false
        })


    }, [user, shoppingCart, deliveryCost])

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCardOptions = (event) => {
        setValueCard(event.target.value);
    }

    const handleBack = async () => {
        const productsArr = []
        let updateProducts;
        const uid = user.uid

        try {
            for (const items of shoppingCart) {
                const cod = items.cod
                const storage = items.storage

                dataStore.Observation('')

                const q = query(collection(db, "products"), where("cod", "==", cod))

                const querySnapshot = await getDocs(q);

                querySnapshot.forEach((doc) => {
                    productsArr.push({...doc.data(), docId: doc.id,})
                })

                const filteredArr = productsArr.reduce((acc, current) => {
                    const x = acc.find(item => item.cod === current.cod);
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                }, []);


                const amount = items.amount

                filteredArr.forEach(items => {
                    if (items.cod === cod) {
                        const stores = items.stores
                        const filterStore = Filters(stores, 'name', storage)
                        const newStock = filterStore[0].stock + amount
                        const updateArray = UpdateArray(stores, 'name', storage, 'stock', newStock)
                        items.stores = updateArray
                    }
                })
                updateProducts = filteredArr
            }


            for (const items of updateProducts) {
                let stock = items?.stores.reduce(function (prev, cur) {
                    return prev + cur.stock;
                }, 0);
                const uidProduct = items.uidProduct
                items.stock = stock
                await setDoc(doc(db, "products", uidProduct), items);
            }

            dataStore.BackDetailHome(true)
            navigate("/shopping-cart")
            await deleteDoc(doc(db, "clients", uid, 'orders', uidOrder));
            await deleteDoc(doc(db, 'orders', uidOrder));
            dataStore.UIDCart(null)

        } catch (e) {
            setOpen(true)
            setSnacks({...snacks, message: 'Error inesperado intentelo otra vez!', type: 'error'})
        }

    }

    const handleDelete = async () => {
        const productsArr = []
        let updateProducts;
        const uid = user.uid

        setTextCancelButton('Cancelando')
        setTextButton('Cancelando')
        setLoading(true)

        try {
            for (const items of shoppingCart) {
                const cod = items.cod
                const storage = items.storage

                const q = query(collection(db, "products"), where("cod", "==", cod))

                const querySnapshot = await getDocs(q);

                querySnapshot.forEach((doc) => {
                    productsArr.push({...doc.data(), docId: doc.id,})
                })

                const filteredArr = productsArr.reduce((acc, current) => {
                    const x = acc.find(item => item.cod === current.cod);
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                }, []);


                const amount = items.amount

                filteredArr.forEach(items => {
                    if (items.cod === cod) {
                        const stores = items.stores
                        const filterStore = Filters(stores, 'name', storage)
                        const newStock = filterStore[0].stock + amount
                        const updateArray = UpdateArray(stores, 'name', storage, 'stock', newStock)
                        items.stores = updateArray
                    }
                })
                updateProducts = filteredArr
            }


            for (const items of updateProducts) {
                let stock = items?.stores.reduce(function (prev, cur) {
                    return prev + cur.stock;
                }, 0);
                const uidProduct = items.uidProduct
                items.stock = stock
                await setDoc(doc(db, "products", uidProduct), items);
            }

            dataStore.UIDCart(uidOrder)

            // await deleteDoc(doc(db, "clients", uid, 'cart', 'cart001'));
            await deleteDoc(doc(db, "clients", uid, 'orders', uidOrder));
            await deleteDoc(doc(db, 'orders', uidOrder));
            setOpen(true)
            setSnacks({...snacks, message: 'Compra cancelada!', type: 'success'})
            setTextCancelButton('Cancelar compra')
            setOpenModal(false)
            setTextButton('Confirmar')
            setLoading(true)

            setTimeout(() => {
                // dataStore.ShoppingCart([])
                navigate('/')
            }, 1500)

        } catch (e) {
            setOpen(true)
            setSnacks({...snacks, message: 'Error inesperado intentelo otra vez!', type: 'error'})
            setTextCancelButton('Cancelar compra')
            setTextButton('Confirmar')
            setLoading(false)
        }
    }

    const handleActionMenu = async () => {
        const productsArr = []
        let updateProducts;
        const uid = user.uid

        try {
            for (const items of shoppingCart) {
                const cod = items.cod
                const storage = items.storage

                dataStore.Observation('')

                const q = query(collection(db, "products"), where("cod", "==", cod))

                const querySnapshot = await getDocs(q);

                querySnapshot.forEach((doc) => {
                    productsArr.push({...doc.data(), docId: doc.id,})
                })

                const filteredArr = productsArr.reduce((acc, current) => {
                    const x = acc.find(item => item.cod === current.cod);
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                }, []);


                const amount = items.amount

                filteredArr.forEach(items => {
                    if (items.cod === cod) {
                        const stores = items.stores
                        const filterStore = Filters(stores, 'name', storage)
                        const newStock = filterStore[0].stock + amount
                        const updateArray = UpdateArray(stores, 'name', storage, 'stock', newStock)
                        items.stores = updateArray
                    }
                })
                updateProducts = filteredArr
            }


            for (const items of updateProducts) {
                let stock = items?.stores.reduce(function (prev, cur) {
                    return prev + cur.stock;
                }, 0);
                const uidProduct = items.uidProduct
                items.stock = stock
                await setDoc(doc(db, "products", uidProduct), items);
            }

            await deleteDoc(doc(db, "clients", uid, 'orders', uidOrder));
            await deleteDoc(doc(db, 'orders', uidOrder));
            dataStore.UIDCart(null)

        } catch (e) {
            setOpen(true)
            setSnacks({...snacks, message: 'Error inesperado intentelo otra vez!', type: 'error'})
        }
    }

    const handleCancelBuy = async () => {
        setOpenModal(true)
    }


    const handleBuy = async () => {

        const checkStock = []
        for (const items of shoppingCart) {
            const cod = items.cod
            const amount = items.amount

            const q = query(collection(db, "products"), where("cod", "==", cod))
            const arr = []
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), docId: doc.id,})
            })

            if (arr[0].stock < amount) {
                const newObject = {product: `${items.cod} ${items.name} ${items.manufacturers}`}
                checkStock.push(newObject)
            }
        }

        // setCheckedStock(checkStock)


        if (!termsConditions) {
            setOpen(true)
            setSnacks({...snacks, message: 'Debe aceptar los terminos y condiciones!', type: 'warning'})
            return
        }

        const uid = user.uid

        setLoading(true)
        setBuyButton('Comprando')

        if (payment === 'Deposito/transferencia' && observation === '') {
            setOpen(true)
            setSnacks({
                ...snacks,
                message: 'Colocar aquí numero de comprobante de su  transferencia o deposito!',
                type: 'warning'
            })
            setLoading(false)
            setBuyButton('Comprar')
        } else {

            const buyDate = format(new Date(), 'yyyy-MM-dd HH:mm')
            const newData = {
                ...dataOrder, observation, deliveryCost, uid, uidOrder, total: total.toFixed(2), buyDate,
                status: payment === 'Tarjeta de Cred/Deb' ? 'CONFIRMADO' : 'POR CONFIRMAR', name: updateName
            }

            const date = format(new Date(), 'yyyy-MM-dd HH:mm')

            if (payment === 'Tarjeta de Cred/Deb') {
                const {idClient, orderNumber, uid, name} = newData


                const amount = parseFloat(total.toFixed(2))
                const orderTaxableAmount = subTotal_2
                const orderVat = iva

                const orderInstallments = parseInt(valueCard)

                let paymentCheckout = new window.PaymentCheckout.modal({

                    //client_app_code: 'TPP3-EC-CLIENT', // Client Credentials //MAZPARTES-EC-CLIENT
                    //client_app_key: 'ZfapAKOk4QFXheRNvndVib9XU3szzg', // Client Credentials // Q2lYFXsXICkl1gaUqg5j6f5bXKQeZh
                    client_app_code: 'MAZPARTES-EC-CLIENT',
                    client_app_key: 'Q2lYFXsXICkl1gaUqg5j6f5bXKQeZh',
                    locale: 'es', // User's preferred language (es, en, pt). English will be used by default.
                    //env_mode: 'stg', // `prod`, `stg`, `local` to change environment. Default is `stg`
                    env_mode: 'prod', // `prod`, `stg`, `local` to change environment. Default is `stg`
                    onOpen: function () {
                        setBuyButton('Comprar')
                        setLoading(false)
                    },
                    onClose: function () {
                        setBuyButton('Comprar')
                        setLoading(false)
                    },
                    onResponse: async function (response) { // The callback to invoke when the Checkout process is completed

                        const {authorization_code, id, status, status_detail} = response.transaction
                        if (status === 'success' && status_detail === 3) {
                            const data = {
                                email: user?.email, logoMail, companyName, name, date, orderNumber,
                                authorization_code, id, total: total.toFixed(2)
                            }
                            try {
                                await setDoc(doc(db, "clients", uid, 'orders', uidOrder), newData);
                                await setDoc(doc(db, 'orders', uidOrder), newData);
                                await deleteDoc(doc(db, "clients", uid, 'cart', 'cart001'))
                                const docRef = doc(db, 'clients', user.uid, 'uidOrder', 'uidOrder001');
                                await setDoc(docRef, {uidOrder: uidOrder});

                                dataStore.Observation('')
                                dataStore.ShoppingCart([])
                                //setUidOrderStorage(uidOrder)
                                navigate('/orders-pdf')
                                setLoading(false)
                                setBuyButton('Comprar')
                                await axios.post(tcUrl, data, headers)
                                await axios.post(ocUrl, data, headers)

                            } catch (e) {
                                setOpen(true)
                                setLoading(false)
                                setBuyButton('Comprar')
                                setSnacks({...snacks, message: errorMsg, type: 'error'})
                            }
                        }


                        if (status === 'success' && status_detail !== 3) {
                            setLoading(false)
                            setBuyButton('Comprar')
                            setOpen(true)
                            setSnacks({...snacks, message: 'Sucesso indefinido intentelo nuevamente!', type: 'warning'})
                        }

                        if (status === 'failure') {
                            setLoading(false)
                            setBuyButton('Comprar')
                            setOpen(true)
                            setSnacks({
                                ...snacks,
                                message: 'Ha ocurrido un error en el pago con su tarjeta, revise sus datos e intentelo nuevamente!',
                                type: 'error'
                            })
                        }
                    }
                })


                paymentCheckout.open({
                    user_id: idClient,
                    user_email: user?.email ? user?.email : '', //optional
                    user_phone: user?.phoneNumber ? user?.phoneNumber : '', //optional
                    order_description: uid,
                    order_amount: parseFloat(amount.toFixed(2)),
                    order_vat: parseFloat(orderVat.toFixed(2)),
                    order_reference: orderNumber,
                    order_installments_type: orderInstallments, // optional: The installments type are only available for Ecuador and Mexico. The valid values are: https://paymentez.github.io/api-doc/#payment-methods-cards-debit-with-token-base-case-installments-type
                    order_taxable_amount: parseFloat(orderTaxableAmount.toFixed(2)), // optional: Only available for Ecuador. The taxable amount, if it is zero, it is calculated on the total. Format: Decimal with two fraction digits.
                    order_tax_percentage: 12 // optional: Only available for Ecuador. The tax percentage to be applied to this order.
                });


            } else {
                const {name} = newData
                const data = {
                    email: user?.email, logoMail, companyName, name, date, orderNumber, total: total.toFixed(2)
                }
                try {
                    await setDoc(doc(db, "clients", uid, 'orders', uidOrder), newData);
                    await setDoc(doc(db, 'orders', uidOrder), newData);
                    await deleteDoc(doc(db, "clients", uid, 'cart', 'cart001'))
                    const docRef = doc(db, 'clients', user.uid, 'uidOrder', 'uidOrder001');
                    await setDoc(docRef, {uidOrder: uidOrder});
                    dataStore.Observation('')
                    dataStore.ShoppingCart([])
                    await axios.post(ocUrl, data, headers)
                    // setUidOrderStorage(uidOrder)
                    navigate('/orders-pdf')
                    setLoading(false)
                    setBuyButton('Comprar')
                } catch (e) {
                    setOpen(true)
                    setLoading(false)
                    setBuyButton('Comprar')
                    setSnacks({...snacks, message: errorMsg, type: 'error'})
                }
            }

        }
    }


    return (
        <div className="wrapper-detail">
            <header>
                <div className="header-container">
                    <div className="contacts-container">
                        <div style={{width: '100%', cursor: 'pointer'}} onClick={() => {
                            navigate('/')
                            handleBack().catch(() => null)
                        }}>
                            <img alt="" src={logo} width={matches ? '100%' : '80%'} height={matches ? 120 : 100}/>
                        </div>
                    </div>
                    <div className="session-container" style={{position: 'relative', zIndex: 2000}}>
                        {user ?
                            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">

                                <Text text={`${user.displayName} ( ${user.typeClient} )`} size={14} weeigh={'700'}/>

                                <IconButton style={{color: 'red'}}
                                            id="basic-button"
                                            aria-controls="basic-menu"
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClickMenu}
                                >
                                    <FilterListOutlinedIcon style={{color: '#282A36'}}/>
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={handleCloseMenu}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => {
                                        signOut(auth).then(async () => {
                                            dataStore.ShoppingCart([])
                                            //setOpenSnak(true)
                                            setSnacks({...snacks, message: 'Saliendo..!', type: 'info'})
                                            navigate('/')
                                            await handleActionMenu()
                                        }).catch(() => {
                                            setOpen(true)
                                            setSnacks({
                                                ...snacks,
                                                message: 'Error inesperado intentelo otra vez!',
                                                type: 'error'
                                            })
                                        })
                                    }
                                    }>Cerrar sesión</MenuItem>
                                    <MenuItem onClick={async () => {
                                        navigate('/order-history')
                                        await handleActionMenu()
                                    }}>Historial de pedidos</MenuItem>

                                    <MenuItem onClick={async () => {
                                        navigate('/update-password')
                                        await handleActionMenu()
                                    }
                                    }>Actualizar contraseña</MenuItem>

                                </Menu>

                            </Stack> :
                            <Stack spacing={2} direction="row">
                                <Button style={{textTransform: 'none'}} variant="text"
                                        onClick={() => {
                                            navigate('/session')
                                            /// dataStore.Path(path)
                                        }}
                                >Iniciar</Button>
                                <Button
                                    onClick={() => {
                                        navigate('/session')
                                        //dataStore.Path(path)
                                    }}
                                    style={{textTransform: 'none', backgroundColor: 'red'}}
                                    variant="contained" disableElevation>
                                    Registarse
                                </Button>
                            </Stack>
                        }
                    </div>
                </div>

                <div style={{width: '80%',}}>
                    <Button variant="text" startIcon={<ArrowBackIcon/>}
                            onClick={handleBack}>Volver</Button>

                </div>
            </header>

            <div className="main-detail-container">
                <div className={'shopping-container'}>
                    <Divider/>
                    <br/>

                    <div style={{
                        width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                        {matches && <Text variant={'h5'} color={'gray'} text={'Detalle de productos'}/>}
                        {!matches && <span style={{color: 'gray', fontSize: 16}}>{`Detalle de productos`}</span>}

                        {matches && <Text variant={'h6'} color={'gray'} text={`No de orden. # ${orderNumber}`}/>}
                        {!matches &&
                            <span style={{color: 'gray', fontWeight: '500'}}>{`No de orden. # ${orderNumber}`}</span>}

                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center"/>
                                    <StyledTableCell align="center" style={{color: '#FFF'}}>Productos</StyledTableCell>
                                    <StyledTableCell align="center" style={{color: '#FFF'}}>Precio. sin
                                        imp</StyledTableCell>
                                    <StyledTableCell align="center" style={{color: '#FFF'}}>Cantidad</StyledTableCell>
                                    <StyledTableCell align="center" style={{color: '#FFF'}}>% Dcto.
                                        Prod</StyledTableCell>
                                    <StyledTableCell align="center" style={{color: '#FFF'}}>% Dcto.
                                        Cte</StyledTableCell>
                                    <StyledTableCell align="center" style={{color: '#FFF'}}>Precio
                                        neto</StyledTableCell>
                                    <StyledTableCell align="center" style={{color: '#FFF'}}>Total</StyledTableCell>
                                    <StyledTableCell align="center" style={{color: '#FFF'}}>Bodega
                                        despacho</StyledTableCell>
                                </TableRow>
                            </TableHead>


                            <TableBody>
                                {shoppingCart.map((items, i) => {

                                    const clientDis = parseFloat(items.clientDis)
                                    const totalDiscount = items.disProd + clientDis
                                    const resultDiscount = items.realPrice * (totalDiscount / 100)
                                    const netPrice = items.realPrice - resultDiscount
                                    const total = items.amount * netPrice


                                    return (
                                        <StyledTableRow key={i}>

                                            <StyledTableCell align="center">
                                                <img src={items.image} alt={''} style={styles.image}/>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {`${items.cod} ${items.name} ${items.manufacturers}`}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {`$ ${items.realPrice}`}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {`$ ${items.amount}`}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {`% ${items.disProd.toFixed(2)}`}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {`% ${parseFloat(items.clientDis).toFixed(2)}`}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {`$ ${netPrice.toFixed(2)}`}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {`$ ${total.toFixed(2)}`}
                                            </StyledTableCell>

                                            <StyledTableCell align="center">
                                                <div style={{display: 'flex', flexDirection: 'column'}}
                                                     className={'containers-items-styles'}>
                                                        <span style={{color: '#333', fontSize: 16}}>
                                                            {items.storage}
                                                        </span>
                                                    <span style={{color: '#333', fontSize: 9}}>
                                                            {items.direction}
                                                        </span>
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}


                            </TableBody>

                        </Table>
                    </TableContainer>
                    <Timer expiryTimestamp={time}/>

                    <div className={'order-container'}>

                        <div style={{width: matches ? '40%' : '100%', display: 'flex', flexDirection: 'column'}}
                             className={'containers-items-styles'}>

                            <div style={{width: '100%'}} className={'containers-items-styles'}>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <span style={{color: 'tomato', fontWeight: '500'}}>
                                            {`Forma de pago: ${payment}`}
                                     </span>
                                </div>
                                <span style={{marginRight: 5, marginLeft: 25}}>Entrega a domicilio</span>
                                <Checkbox
                                    checked={checked}
                                    inputProps={{'aria-label': 'controlled'}}
                                />

                            </div>

                            <TextareaAutosize
                                aria-label="minimum height"
                                minRows={3}
                                value={observation}
                                onChange={(e) => dataStore.Observation(e.target.value)}
                                placeholder={payment === 'Deposito/transferencia' ? 'Colocar aquí numero de comprobante de su  transferencia o deposito' : "Observación adicional"}
                                style={{width: matches ? '65%' : '100%'}}
                            />
                            {payment === 'Deposito/transferencia' &&

                                <span style={{marginTop: 10, color: 'gray', fontWeight: 'bold'}}>
                                            Cta. Cte. Bco Pichincha: 2100024507
                                </span>}
                            <br/>

                            {payment === 'Deposito/transferencia' &&

                                <span style={{color: 'gray', fontWeight: 'bold'}}>
                                            Hernán Miranda, cédula 1803473527
                                </span>}
                            <br/>
                            {payment === 'Tarjeta de Cred/Deb' &&
                                <FormControl>
                                    <FormLabel id="demo-controlled-radio-buttons-group">Opciones en tarjetas de
                                        credito</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={valueCard}
                                        onChange={handleCardOptions}
                                    >
                                        <FormControlLabel value={0} control={<Radio/>} label="Corriente"/>
                                        <FormControlLabel value={3} control={<Radio/>}
                                                          label="Diferido sin interes"/>
                                        <FormControlLabel value={2} control={<Radio/>}
                                                          label="Diferido con interes"/>
                                    </RadioGroup>
                                </FormControl>}

                        </div>
                        <div style={{
                            width: matches ? '60%' : '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                        }}
                        >
                            <div className={'resume-container'}>
                                <span style={{color: 'black', fontWeight: 'bold'}}>Subtotal : </span>
                                <span style={{color: '#333', fontWeight: '600', marginLeft: 25}}>
                            {`$ ${subTotal.toFixed(2)}`}
                        </span>
                            </div>

                            <div className={'resume-container'}>
                                <span style={{color: 'black', fontWeight: 'bold'}}>Costo de envio : </span>
                                <span style={{
                                    color: '#333',
                                    fontWeight: '600',
                                    marginLeft: 25
                                }}>{`$ ${deliveryCost}`}</span>
                            </div>

                            <div className={'resume-container'}>
                                <span style={{color: 'black', fontWeight: 'bold'}}>Subtotal 2 : </span>
                                <span style={{color: '#333', fontWeight: '600', marginLeft: 25}}>
                            {`$ ${subTotal_2.toFixed(2)}`}
                        </span>
                            </div>

                            <div className={'resume-container'}>
                                <span style={{color: 'black', fontWeight: 'bold'}}>{`IVA ${ivaValue} %`} </span>
                                <span style={{
                                    color: '#333',
                                    fontWeight: '600',
                                    marginLeft: 25
                                }}>{`$ ${iva.toFixed(2)}`}</span>
                            </div>

                            <div className={'resume-container'}>
                                <span style={{color: 'black', fontWeight: 'bold'}}>TOTAL : </span>
                                <span style={{color: 'black', fontWeight: '900', marginLeft: 25, fontSize: 18}}>
                            {`$ ${total.toFixed(2)}`}
                        </span>
                            </div>

                        </div>
                    </div>

                    <br/>
                    {matches ?
                        <div className={'order-container-action'}>
                            <Button variant="text" startIcon={<ArrowBackIcon/>}
                                    onClick={handleBack}>Volver</Button>

                            <div>

                                <Checkbox
                                    checked={termsConditions}
                                    onChange={(event) => setTermsConditions(event.target.checked)}
                                    inputProps={{'aria-label': 'controlled'}}
                                />


                                <Link target="_blank" to={"/terms-conditions"} style={{
                                    color: '#2976D2', marginRight: 25,
                                    marginLeft: 5,
                                }}>
                                    Acepto términos y condiciones
                                </Link>


                                <Button variant="contained" disableElevation startIcon={<CancelOutlinedIcon/>}
                                        style={{background: 'gray', marginRight: 10, borderRadius: 15}}
                                        onClick={handleCancelBuy} disabled={textCancelButton === 'Cancelando'}>
                                    {textCancelButton}
                                </Button>

                                <Button variant="contained" disableElevation
                                        style={{background: '#B7182B', borderRadius: 15}}
                                        startIcon={!loading && <MonetizationOnOutlinedIcon/>}
                                        onClick={handleBuy} disabled={textCancelButton === 'Cancelando' || loading}>
                                    {loading && <CircularProgress size={15} style={{marginRight: 7, color: 'white'}}/>}
                                    {buyButton}
                                </Button>
                            </div>
                        </div> :
                        <div>
                            <div style={styles.resContainer}>
                                <Checkbox
                                    checked={termsConditions}
                                    onChange={(event) => setTermsConditions(event.target.checked)}
                                    inputProps={{'aria-label': 'controlled'}}
                                />
                                <Link target="_blank" to={"/terms-conditions"} style={{
                                    color: '#2976D2', marginLeft: 5, marginRight: 7
                                }}>
                                    Terminos y condiciones
                                </Link>
                            </div>

                            <div style={styles.resContainer}>

                                <Button variant="contained" disableElevation startIcon={<CancelOutlinedIcon/>}
                                        style={{background: 'gray', marginRight: 10, borderRadius: 15}}
                                        onClick={handleCancelBuy}
                                        disabled={textCancelButton === 'Cancelando' || loading}>
                                    {textCancelButton}
                                </Button>

                                <Button variant="contained" disableElevation style={{
                                    background: '#B7182B',
                                    borderRadius: 15
                                }} startIcon={!loading && <MonetizationOnOutlinedIcon/>}
                                        onClick={handleBuy} disabled={textCancelButton === 'Cancelando' || loading}>
                                    {loading && <CircularProgress size={15} style={{marginRight: 7, color: 'white'}}/>}
                                    {buyButton}
                                </Button>

                            </div>

                        </div>}
                </div>
                <br/>
            </div>

            <footer>
                <Footer/>
            </footer>

            <ConfirmationModal openModal={openModal} setOpenModal={setOpenModal} text={'Cancelar compra'}
                               textBody={'Seguro que desea cancelar la compra?'} textButton={textButton}
                               icon={<DeleteIcon/>} loading={loading} onClick={handleDelete}

            />

            <CustomizedSnackbars open={open} setOpen={setOpen} message={snacks.message} type={snacks.type}/>
        </div>
    )

}

export default observer(Order)

const styles = {
    image: {
        width: 50,
        height: 70
    },

    resContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
}

const useStyles = makeStyles({
    table: {
        minWidth: 1000,
    },
    containerButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    }
});


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#282A36',
        color: 'white',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#FAFAFA',
        },
    },
}))(TableRow);

