import {configure, makeAutoObservable,} from 'mobx';


configure({
    enforceActions: 'never',
})


class FiltersStore {

    valueVehicles = null
    inputValueVehicles = ''
    valueModels = null
    inputValueModels = ''
    valueYear = null
    valueManufacturers = null
    inputValueManufacturers = ''

    initYear = 1960
    endYear = 2022

    //inputValueModels= ''

    /* LATERAL FILTERS*/

    products = null
    brandsModels = null

    filterSearch = {inLateralFilters: false, inDetail: false}

    selectedVehicles = null

    selectedModel = null

    selectedYear = null
    yearsList = null
    copyYearsList = null

    selectGroup = null

    subGroupList = null
    copSubGroupList = null

    selectSubGroup = null

    selectManufacturers = null

    modelsList = null
    copyModelsList = null

    selectSchema = null

    schemasList_ = null
    copySchemaList = null

    schema = null
    imageSchema = null

    //GLOBAL FILTER
    deleteGlobalFilter = false

    allProducts = null
    keyWords = ''
    backHome = false
    result = null


    constructor() {
        makeAutoObservable(this);
    }

    /****************** FILTERS FUNCTIONS ******************/

    FilterSearch = (values) => this.filterSearch = values
    setProducts= (products) => this.products = products
    setBrandsModels = (value) => this.brandsModels = value
    setSelectedVehicles = (value) => this.selectedVehicles = value
    setSelectedModel = (value) => this.selectedModel = value
    setSelectedYear = (value) => this.selectedYear = value
    setYearsList = (list) => this.yearsList = list
    setCopYearsList = (list) => this.copyYearsList = list
    setSelectGroup = (value) => this.selectGroup = value
    setSelectSubGroup = (value) => this.selectSubGroup = value
    setSelectManufacturers = (value) => this.selectManufacturers = value

    SchemaList = (schemasList) => this.schemasList_ = schemasList
    CopySchemaList = (schemasList) => this.copySchemaList = schemasList
    setSelectSchema = (schemas) => this.selectSchema = schemas
    setKeyWords = (keyWords) => this.keyWords = keyWords


    ModelsList = (models) => this.modelsList = models
    CopyModelsList = (models) => this.copyModelsList = models

    SubGroupList = (values) => this.subGroupList = values
    CopySubGroupList = (values) => this.copSubGroupList = values
    ImageSchema = (imageSchema) => this.imageSchema = imageSchema
    DeleteGlobalFilter = (value) => this.deleteGlobalFilter = value
    AllProducts = (products) => this.allProducts = products
    BackHome = (value) => this.backHome = value
    Result = (values) => this.result = values

    /*******************************************************/

    ValuesVehicles = (valueVehicles) => {
        this.valueVehicles = valueVehicles;
    }

    InputValuesVehicles = (inputValueVehicles) => {
        this.inputValueVehicles = inputValueVehicles;
    }

    /*Models*/

    ValuesModels = (valueModels) => {
        this.valueModels = valueModels;
    }

    InputValuesModels = (inputValueModels) => {
        this.inputValueModels = inputValueModels;
    }

    /*Years*/

    GetYears = (years) => {
        this.valueYear = years;
    }

    GetRangeYears = (initYear, endYear) => {
        this.initYear = parseInt(initYear)
        this.endYear = parseInt(endYear)
    }

    /*Manufacturers*/

    ValuesManufacturers = (valueManufacturers) => {
        this.valueManufacturers = valueManufacturers;
    }

    InputValuesManufacturers = (inputValueManufacturer) => {
        this.inputValueManufacturers = inputValueManufacturer;
    }


}

export {FiltersStore}
