import Typography from "@mui/material/Typography";
import {initLoginValues, loginSchema} from "../../../utils/YupSchemas";
import {auth, db} from "../../../firebase/Firebase";
import {Formik} from 'formik';
import {StoreContext} from "../../../store/Context";
import {useContext, useState} from "react";
import {observer} from "mobx-react-lite";
import InputForm from "../../../palette/InputForm";
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import SubmitButton from "../../../palette/SubmitButton";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import {signInWithEmailAndPassword} from "firebase/auth";
import CustomizedSnackbars from "../../../palette/SnacksBar";
import {useNavigate} from "react-router-dom";
import {collection, query, where, getDocs, doc, getDoc} from "firebase/firestore";

function Login() {

    const {dataStore} = useContext(StoreContext)
    const {path} = dataStore

    const [loading, setLoading] = useState(false)
    const [textButton, setTextButton] = useState('Iniciar sesión')
    const [open, setOpen] = useState(false)
    const [snacks, setSnacks] = useState({message: '', type: 'success'})

    const navigate = useNavigate();

    const onSubmit = async (values) => {

        const email = values.email
        const password = values.password

        setLoading(true)

        const q = query(collection(db, "clients"), where("email", "==", email));

        const querySnapshot = await getDocs(q);

        let clientData =null
        querySnapshot.forEach((doc) => {
            clientData= doc.data()
        });

        if(clientData){
            const status = clientData.status

            if(status === 'Activo'){
                signInWithEmailAndPassword(auth, email, password)
                    .then(async (userCredential) => {
                        setOpen(true)
                        setSnacks({...snacks, message: 'Bienvenido!', type: 'success'})
                        const user = userCredential.user;
                        const uid = user.uid;
                        const docRef = doc(db, "clients", uid);
                        const docSnap = await getDoc(docRef);
                        const type = docSnap.data().type
                        const city = docSnap.data()?.city ? docSnap.data()?.city : null
                        const paymentDeadline = docSnap.data()?.paymentDeadline
                        const idClient = docSnap.data()?.idClient
                        const purchaseQuota = docSnap.data()?.purchaseQuota
                        const deliveryCity = docSnap.data()?.deliveryCity
                        let displayName = type === 'Judirico' ? docSnap.data().tradeName : `${docSnap.data().name}  ${docSnap.data().lastName}`;
                        const discount = docSnap.data().discount
                        const typeClient = docSnap.data().typeClient
                        setTextButton('Iniciar sesión')
                        dataStore.User({
                            uid, displayName, typeClient, discount, city, paymentDeadline, purchaseQuota,
                            deliveryCity, type, idClient
                        })
                        setLoading(false)
                        navigate(path ? path : '/')
                    })
                    .catch(() => {
                        setOpen(true)
                        setSnacks({...snacks, message: 'Usuario o contraseña incorrecta!', type: 'error'})
                        setTextButton('Iniciar sesión')
                        setLoading(false)
                    });
            }else{
                setOpen(true)
                setSnacks({...snacks, message: 'No se puede iniciar sesión, su usuario debe estar en modo activo!', type: 'error'})
                setTextButton('Iniciar sesión')
                setLoading(false)
            }

        }else{
            setOpen(true)
            setSnacks({...snacks, message: 'Este correo electronico no existe!', type: 'error'})
            setTextButton('Iniciar sesión')
            setLoading(false)
        }
    }

    return (
        <div className={'register-container'}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography component="h2" variant="h5">
                    INICIAR SESIÓN
                </Typography>
            </div>

            <br/>

            <Formik
                initialValues={initLoginValues}
                validationSchema={loginSchema}
                onSubmit={onSubmit}
            >
                {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                  }) => {
                    return (
                        <>

                            <InputForm label="Correo electrónico" full field={'email'} errors={errors}
                                       handleChange={handleChange} values={values}
                                       handleBlur={handleBlur} top={12}
                                       maxLength={30} small
                            />

                            <InputForm label="Password" full field={'password'} errors={errors} top={10} small
                                       handleChange={handleChange} values={values} handleBlur={handleBlur}
                            />

                            <div style={{
                                display: 'flex', flexDirection: 'row', marginTop: 10, alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <Link href="#" underline="always">
                                    <Button href="#text-buttons"
                                            onClick={() => navigate('/recover-password')}>
                                        Olvido su contraseña?
                                    </Button>
                                </Link>

                                <SubmitButton text={textButton} type="submit" onClick={handleSubmit}
                                              loading={loading} icon={<VpnKeyIcon/>}/>
                            </div>

                        </>
                    )
                }}
            </Formik>
            <CustomizedSnackbars open={open} setOpen={setOpen} message={snacks.message} type={snacks.type}/>
        </div>
    )
}


export default observer(Login)
