import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CancelButton from "./CancelButton";
import SubmitButton from "./SubmitButton";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

function ConfirmationModal(props) {

    const {openModal, setOpenModal, text, textBody, textButton, loading, onClick, icon} = props


    return (
        <React.Fragment>
            <Modal
               // hideBackdrop
                open={openModal}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 'auto'}}>
                    <h3 id="simple-modal-title">{text}</h3>
                    <p id="simple-modal-description">
                        {textBody}
                    </p>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                    }}>
                        <CancelButton onClick={() => setOpenModal(false)}/>
                        <SubmitButton text={textButton} type="submit" onClick={onClick}
                                      loading={loading} icon={icon}/>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    )

}

export default ConfirmationModal
