import Header from "../home/header/Header";
import MainDetail from "./main-detail/MainDetail";
import './details.css'
import Footer from "../home/footer/Footer";
import {useState} from "react";

function Details() {

    const [value, setValue] = useState('');

    return (
        <div className="wrapper-detail">
            <div className={'header-detail'}>
                <Header path={'/product-details'}  setValue={setValue} value={value}/>
            </div>
            <div className="main-detail-container">
                <br/>
                <MainDetail/>
                <br/>
            </div>
            <footer>
                <Footer/>
            </footer>
        </div>
    )

}

export default Details
