import * as Yup from "yup";

/*Clients*/

export const clientsSchema = Yup.object().shape({

    name: Yup.string()
        .required('Ingrese el nombre'),
    lastName: Yup.string()
        .required('Ingrese el apellido'),
    email: Yup.string()
        .required('Ingrese el correo electrónico')
        .email('Correo electrónico invalido'),
    phoneNumber: Yup.string()
        .required('Ingrese su número de celular'),
    province: Yup.string()
        .required('Escoja la provincia'),
    city: Yup.string()
        .required('Escoja la ciudad'),
    password: Yup.string()
        .required('La contraseña es requerida')
        .min(6, 'La contraseña debe tener al menos 6 caracteres.')
        .max(10, 'Maximo 10 caracteres.'),
    passwordConfirmation: Yup.string()
        .required('La confirmación es requerida')
        .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')

});


export const iniClientValues = {
    name: '',
    lastName: '',
    idClient: '',
    email: '',
    phoneNumber: '',
    province: '',
    city: '',
    password: '',
    passwordConfirmation: '',
    type: 'Natural',
    typeId: '',
    purchaseQuota: 2000,
    numberInstallments: 0,
    status: 'Activo',
    typeClient: 'Final',
    paymentDeadline: '',
}

// LOGIN


export const loginSchema = Yup.object().shape({

    email: Yup.string()
        .required('Ingrese el correo electrónico')
        .email('Correo electrónico invalido'),

    password: Yup.string()
        .required('La contraseña es requerida')
        .min(6, 'La contraseña debe tener al menos 6 caracteres.')
});


export const initLoginValues = {
    email: '',
    password: '',
}

// RECOVER

export const recoverSchema = Yup.object().shape({

    email: Yup.string()
        .required('Ingrese el correo electrónico')
        .email('Correo electrónico invalido'),
});


export const initRecoverValues = {
    email: '',
}

// UPDATE PWD


export const updateSchema = Yup.object().shape({

    password: Yup.string()
        .required('La contraseña es requerida')
        .min(6, 'La contraseña debe tener al menos 6 caracteres.')
        .max(10, 'Maximo 10 caracteres.'),
    passwordConfirmation: Yup.string()
        .required('La confirmación es requerida')
        .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
});

export const initUpdateValues = {
    password: '',
    passwordConfirmation: ''
}


/*PERSONAL DATA*/

export const personalDataSchema = Yup.object().shape({

    name: Yup.string()
        .required('Ingrese el nombre'),
    lastName: Yup.string()
        .required('Ingrese el apellido'),
    email: Yup.string()
        .required('Ingrese el correo electrónico')
        .email('Correo electrónico invalido'),
    phoneNumber: Yup.string()
        .required('Ingrese su número de celular'),
    typeId: Yup.string()
        .required('Escoja el tipo de identificación'),
    idClient: Yup.string()
        .required('Ingrese su identificación'),

})


export const personalDataSchema_2 = Yup.object().shape({

    tradeName: Yup.string()
        .required('Ingrese el nombre comercial'),
    email: Yup.string()
        .required('Ingrese el correo electrónico')
        .email('Correo electrónico invalido'),
    phoneNumber: Yup.string()
        .required('Ingrese su número de celular'),
    typeId: Yup.string()
        .required('Escoja el tipo de identificación'),
    idClient: Yup.string()
        .required('Ingrese su identificación'),

})



/*DIRECTION DATA*/

export const clientDirectionSchema_1 = Yup.object().shape({
    province: Yup.string()
        .required('Escoja la provincia'),
    city: Yup.string()
        .required('Escoja la ciudad'),
    mainStreet: Yup.string()
        .required('Ingrese la calle principal'),
    secondaryStreet: Yup.string()
        .required('Ingrese la calle secundaria'),
    houseNumber: Yup.string()
        .required('Ingrese el número de Dpto/Casa'),
    reference: Yup.string()
        .required('Ingrese una referencia'),
});


/*DELIVERY DATA*/

export const clientDeliverySchema = Yup.object().shape({
    deliveryProvince: Yup.string()
        .required('Escoja la provincia'),
    deliveryCity: Yup.string()
        .required('Escoja la ciudad'),
    deliveryMainStreet: Yup.string()
        .required('Ingrese la calle principal'),
    deliverySecondaryStreet: Yup.string()
        .required('Ingrese la calle secundaria'),
    deliveryHouseNumber: Yup.string()
        .required('Ingrese el número de Dpto/Casa'),
    deliveryReference: Yup.string()
        .required('Ingrese una referencia'),
});







