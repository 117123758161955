//import useMediaQuery from '@mui/material/useMediaQuery';
import './side.css'
import FilterMMA from "./FilterMMA";
import FilterManufacturers from "./FilterManufacturers";
import FilterGSGNP from "./FilterGSGNP";
import {useContext, useEffect, useState} from "react";
import {StoreContext} from "../../../store/Context";
import {observer} from "mobx-react-lite";
import SchemaResult from "./SchemaResult";
import MenuIcon from '@mui/icons-material/Menu';
import {IconButton} from '@mui/material';

function Sidebar(props) {

    const {dataStore, filtersStore} = useContext(StoreContext)
    const {mainProducts, schemaFilter} = dataStore
    const {selectSchema, imageSchema} = filtersStore

    const {
        vehicles, manufacturers, groups, subGroups, schemas,
        setTriggerProd, triggerProd, setModels, models, setTriggerVehicle, triggerVehicle, setTriggerModel,
        triggerModel, setTriggerYear, triggerYear, setCollapsed, collapsed, value
    } = props


    const [valueVehicles, setValueVehicles] = useState(null);
    const [inputValueModels, setInputValueModels] = useState('');
    const [valueYear, setValueYear] = useState(null);

    const [valueGroups, setValueGroups] = useState(null);
    const [inputValueGroups, setInputValueGroups] = useState('');
    const [valueSubGroups, setValueSubGroups] = useState(null);
    const [inputValueSubGroups, setInputValueSubGroups] = useState('');
    const [valueSchema, setValueSchema] = useState(null);
    const [inputValueSchema, setInputValueSchemas] = useState('');
    const [schemasList, setSchemasList] = useState([])


    // const matches = useMediaQuery('(max-width:768px)');


    useEffect(() => {

        if (value === '') {
            resetFilter().catch(() => null)
        }

    }, [value])


    const resetFilter = async () => {
        const data = {
            vehicles: '', models: '', qYear: '', manufacturers: '', schema: '', groups: false,
            subGroup: false, schemaArr: [],
        }
        const dataGlobal = {
            vehicles: '', models: '', qYear: '', manufacturers: '', schema: ''
        }
        dataStore.UpdateFilter(data)
        dataStore.GlobalFilterCombination(dataGlobal)
        dataStore.GlobalFilter(false)
        dataStore.ShowNoStock(false)
        dataStore.ValueSearch('')
        await dataStore.FilterData(data)
        filtersStore.InputValuesModels('')
        filtersStore.InputValuesVehicles('')
        filtersStore.ValuesModels(null)
        filtersStore.ValuesVehicles(null)
        filtersStore.GetYears(null)
        filtersStore.InputValuesManufacturers('')
        filtersStore.ValuesManufacturers(null)
        setInputValueGroups('')
        setValueGroups(null)
        setInputValueSubGroups('')
        setValueSubGroups(null)
        setInputValueSchemas('')
        setValueSchema(null)
        setTriggerProd(!triggerProd)
    }


    return (
        <div className="sidebar-container">

            <div style={{height: 20}} className={'menu-side-container-show'}>
                <IconButton aria-label="delete" onClick={() => setCollapsed(!collapsed)}>
                    <MenuIcon/>
                </IconButton>
                <span>Filtros</span>
            </div>

            <div className={'menu-side-container'}>
                {collapsed &&
                    <IconButton aria-label="delete" onClick={() => setCollapsed(!collapsed)}>
                        <MenuIcon/>
                    </IconButton>}

                <div style={{height: 20}}>
                    <span>Filtros</span>
                </div>

            </div>

            <FilterMMA vehicles={vehicles} models={models} valueVehicles={valueVehicles}
                       setValueVehicles={setValueVehicles} inputValueModels={inputValueModels}
                       setInputValueModels={setInputValueModels} setModels={setModels}
                       setValueYear={setValueYear} valueYear={valueYear} resetFilter={resetFilter}
                       setTriggerProd={setTriggerProd} triggerProd={triggerProd}
                       setTriggerVehicle={setTriggerVehicle} triggerVehicle={triggerVehicle}
                       setTriggerModel={setTriggerModel} triggerModel={triggerModel}
                       setTriggerYear={setTriggerYear} triggerYear={triggerYear}
                       manufacturers={manufacturers} groups={groups} subGroups_={subGroups}
                       schemasList_={schemasList} schemas={schemas}
            />


            {selectSchema &&
                <SchemaResult imageUrl={imageSchema} mainProducts={mainProducts} schema={selectSchema}/>
            }
        </div>
    )
}

export default observer(Sidebar)


