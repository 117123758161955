export const companyId = '8HLl05uBDNQESGj9BTRA3IGnYFl2'
export const logo = 'https://firebasestorage.googleapis.com/v0/b/mazpartes-46c02.appspot.com/o/mazpartesLogo.png?alt=media&token=7540cf7d-e92a-4a50-82aa-a63ff218b35b'

export const filtersUrl = 'https://filtersproducts-uk7v3cm6ga-uc.a.run.app/filters-products'
export const searchProductsUrl = 'https://seekerdata-uk7v3cm6ga-uc.a.run.app/seeker-data'
//export const filtersUrl = ' https://filters-products-uk7v3cm6ga-uc.a.run.app/filters-products'
//export const filtersUrl = 'http://192.168.100.30:5000/filters-products'
//export const filtersUrl = 'http://127.0.0.1:5000/filters-products'
export const activeServiceUrl = 'https://activeservices-uk7v3cm6ga-uc.a.run.app/active-services'
//export const activeServiceUrl = 'http://127.0.0.1:5000/active-services'
//export const gfcUrl = 'http://127.0.0.1:5000/global-filter-combination'
//export const tcUrl = 'http://localhost:8080/transaction-card'
//export const ocUrl = 'http://localhost:8080/order-confirmation'
export const ocUrl = 'https://order-confirmation-uk7v3cm6ga-uc.a.run.app/order-confirmation'
export const tcUrl = 'https://transaction-card-uk7v3cm6ga-uc.a.run.app/transaction-card'
export const gfcUrl = 'https://global-filter-combination-uk7v3cm6ga-uc.a.run.app/global-filter-combination'
//export const recoverPasswordUrl = 'http://localhost:8080/recover-password'
export const recoverPasswordUrl = 'https://recover-password-uk7v3cm6ga-uc.a.run.app/recover-password'
export const updatePwdUrl = 'https://users-uk7v3cm6ga-uc.a.run.app/update-password'


/*Headers axios*/
export const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
}

export const errorMsg = 'Error inesperado intentelo nuevamente!'






