import Button from '@mui/material/Button';
import {useStyles} from "../utils/Styles";
import CircularProgress from '@mui/material/CircularProgress';

function SubmitButton(props) {

    const {loading, text, type, onClick, right, icon} = props

    const classes = useStyles();

    return (

        <Button type={type} variant="contained" onClick={onClick}
                style={{marginTop: 10, marginRight: right}}
                disabled={loading}
                startIcon={!loading ? icon : null}
                className={classes.submitButton}>
            {loading && <CircularProgress size={15} style={{marginRight: 7, color: 'white'}}/>}
            {text}
        </Button>
    )
}

export default SubmitButton
